import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import ReactPlayer from "react-player";
import {Icon} from '@iconify/react';

import {getCurrentUser} from "../store/auth/actions";
import {getAllBenchmarks, setBenchmarkFilterValue,getPhotosAndVideosUpdates, getUserData} from "../store/common/actions";
import {capitalize, clearApiBaseUrl, formatUpdateCreationDate} from "../assets/helpers/helpers";
import ReactExport from "react-export-excel";
import DotLoader from "react-spinners/DotLoader";
import DatePicker from 'react-datepicker'
import {
    getClientsForBasfByDistributor,
    getBasfDistributors,
    updateProfileById,
    getDistributorsCompetenceAreas,
    setBasfSelectValue,
    getClientDetailsForBasfPreview,
    clearClientDetails,
    clearClients,
    clearDistributors,
    getDistributorName,
    uploadMedia,
    uploadNewUpdate,

    clearBussinesList,
    getBusinessList,
} from "../store/basf/actions";


import '../assets/css/custom.css';

import {bussines_categories} from '../utils/constants'


class BasfSalesProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile_open: true,
            bussines_list: false,
            cv_list: false,
            reports_list: false,
            uploads_list: false,
            isActive: true,
            default_bussines_category_value: 'Zhgjidh një nga kategoritë ...',
            selected_bussines_category: 'Zgjidh operatorin ...',
            loading: false,
            
            //user:this.props.currentUser,

            name:this.props.currentUser?.name,
            fiscal_code:this.props.currentUser?.fiscal_code,
            bussines_name:this.props.currentUser?.bussines_name,
            legal_status:this.props.currentUser?.legal_status,
            year_of_establishment:this.props.currentUser?.year_of_establishment,
            area:this.props.currentUser?.area,
            address_street: this.props.currentUser?.address_street, 
            address_qyteti:this.props.currentUser?.address_qyteti , 
            address_postal_code:this.props.currentUser?.address_postal_code,
            country:this.props.currentUser?.country,
            telefono:this.props.currentUser?.telefono,
            email:this.props.currentUser?.email,
            property:this.props.currentUser?.property,

            //name, 
            lastName:this.props.currentUser?.lastName, 
            gender:this.props.currentUser?.gender,
            birthday:this.props.currentUser?.birthday, 
            birthplace:this.props.currentUser?.birthplace, 
            fatherName:this.props.currentUser?.fatherName,
            nationality:this.props.currentUser?.nationality,
            resident_address:this.props.currentUser?.resident_address,
            secondNationality:this.props.currentUser?.secondNationality, 
            origin:this.props.currentUser?.origin,
            education:this.props.currentUser?.education,
            educationField:this.props.currentUser?.educationField,
            //email,
            //telefono, 
            facebookProfile:this.props.currentUser?.facebookProfile,  
            twitterProfile:this.props.currentUser?.twitterProfile, 
            linkedinProfile:this.props.currentUser?.linkedinProfile,

            params: props.basf.params,
            errors: {
                area: false,
                distributor: false
            },
            filter: props.media.filter
        };
    }
    
    handleChangeInput = (e) => {
      this.setState({
        [e.target.getAttribute('name')]: e.target.value
      })
    }

    uploadMultipleFiles = (e) => {
        const self = this;
        const {uploadMedia, uploadNewUpdate} = self.props;
        console.log(Array.from(e.target.files).length, self.state.max_file_upload_limit)


        if (Array.from(e.target.files).length > self.state.max_file_upload_limit) {
            e.preventDefault();
            toast.error(`KUJDES! Nuk mund të ngarkoni më shumë se ${self.state.max_file_upload_limit} Foto`);
            return;
        }


        self.setState((prevState) => {
            const currentPhotos = prevState.registration_data?.uploaded_documents ?? []
    
            // console.log("UPLOADED_DOCUMENTS", [...currentPhotos,  ...Array.from(e.target.files)])

            const bussines_img = [...currentPhotos,  ...Array.from(e.target.files)];

            console.log("BUSSINES_LOGO", bussines_img)

            // if (img_file) {
            //     const reader = new FileReader();
                          
            //     reader.onload = (e) => {
            //       const img = new Image();
            //       img.src = img_file;
          
            //       img.onload = () => {
            //         const width = img.width;
            //         const height = img.height;
          
            //         console.log('Width:', width);
            //         console.log('Height:', height);
          
            //         self.setState({
            //             bussines_img_width: width,
            //             bussines_img_height: height,
            //         });
            //       };
            //     };          
            //     reader.readAsDataURL(selectedFile);
            //     setFile(selectedFile);
            // }

            
            let newData = Array.from(bussines_img);
            newData.file_reference = 'logo_img';    
            newData.profile_id = this.props.currentUser.id;

            newData.bussines_category = this.props.currentUser.category;
            
            console.log("newData.profile_id = this.props.currentUser.id;   ",this.props.currentUser.id)
            console.log("newData.profile_id = this.props.currentUser.id1;   ",newData)
            uploadNewUpdate(newData)            
        })    
    }

    uploadProductImage = (e) => {
        const self = this;
        const {uploadNewUpdate} = self.props;

        // if (Array.from(e.target.files).length > self.state.max_file_upload_limit) {
        //     e.preventDefault();
        //     toast.error(`KUJDES! Nuk mund të ngarkoni më shumë se ${self.state.max_file_upload_limit} Foto`);
        //     return;
        // }

        self.setState((prevState) => {
            const currentPhotos = prevState.registration_data?.uploaded_documents ?? [];
            const bussines_img = [...currentPhotos,  ...Array.from(e.target.files)];
            console.log("PRODUKT_IMG", bussines_img);          
            let newData = Array.from(bussines_img);
            newData.file_reference = 'product_or_service_img'; 
            newData.profile_id = this.props.currentUser.id;   
            newData.bussines_category = this.props.currentUser.category;
            console.log("newData.profile_id = this.props.currentUser.id;   ",this.props.currentUser.id)
            console.log("newData.profile_id = this.props.currentUser.id1;   ",newData)
            uploadNewUpdate(newData);
        })    
    }

    uploadProfileImage = (e) => {
        const self = this;
        const {uploadNewUpdate} = self.props;
        
        // if (Array.from(e.target.files).length > self.state.max_file_upload_limit) {
        //     e.preventDefault();
        //     toast.error(`KUJDES! Nuk mund të ngarkoni më shumë se ${self.state.max_file_upload_limit} Foto`);
        //     return;
        // }

        self.setState((prevState) => {
            const currentPhotos = prevState.registration_data?.uploaded_documents ?? [];
            const bussines_img = [...currentPhotos,  ...Array.from(e.target.files)];
            console.log("PROFILE_IMAGE", bussines_img);          
            let newData = Array.from(bussines_img);
            newData.file_reference = 'profile_img';   
            newData.profile_id = this.props.currentUser.id;  
            newData.bussines_category = this.props.currentUser.category;
            uploadNewUpdate(newData);
        })    
    }

    componentDidMount() {
        const {getAllBenchmarks, media, getUserData} = this.props;
        console.log("again here")
        //this.props.getCurrentUser()
        // getUserData()

        // if (media.benchmarks.list.data.length === 0 && media.benchmarks.list.loading === false) {
        //     getAllBenchmarks();
        // }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.updates_open !== this.state.updates_open) {
            const {setBenchmarkFilterValue, getPhotosAndVideosUpdates, media, getUserData} = this.props;
            if (media.filter.benchmark === '') {
                setBenchmarkFilterValue('benchmark', media.benchmarks.list.data[0]?.campo_benchmark);
                getPhotosAndVideosUpdates();
                getUserData()
            }
        }
    }

    hasDocument = (type) => {
        const {currentUser} = this.props;
        console.log("CURRENT_USER", currentUser);

        let docLink = false;
        if (currentUser && currentUser.documents && currentUser.documents.length > 0) {
            let index = this.props.currentUser.documents.findIndex(function (o) {
                return o.file_reference === type;
            });
            if (index !== -1) {
                docLink = this.props.currentUser.documents[index].path;
            }
        }
        console.log("docLink", docLink);
        if(docLink){
            return clearApiBaseUrl(docLink)
        }else{
            return docLink;
        }

    }

    handleToggle = (type) => {
        const {clearClientDetails} = this.props;
        const client_detail = this.props.basf.client_detail;

        if (client_detail !== null) {
            clearClientDetails();
        }

        if (type === 'profile') {
            this.setState({
                profile_open: true,
                bussines_list: false,
                cv_list: false,
                reports_list: false,
                uploads_list: false,
                selected_bussines_category: 'Zgjidh operatorin ...',
            })
        }

        if(type === 'bussines_list'){
            this.setState({
                profile_open: false,
                bussines_list: true,
                cv_list: false,
                reports_list: false,
                uploads_list: false,
            })
        }

        if(type === 'cv_list'){
            this.setState({
                profile_open: false,
                bussines_list: false,
                cv_list: true,
                reports_list: false,
                uploads_list: false,
                selected_bussines_category: 'Zgjidh operatorin ...',
            })
        }

        if(type === 'reports_list'){
            this.setState({
                profile_open: false,
                bussines_list: false,
                cv_list: false,
                reports_list: true,
                uploads_list: false,
            })
        }

        if(type === 'uploads_list'){
            this.setState({
                profile_open: false,
                bussines_list: false,
                cv_list: false,
                reports_list: false,
                uploads_list: true,
                selected_bussines_category: 'Zgjidh operatorin ...',
            })
        }
       
    }

    setIsActive = () => {
        const self = this;
        self.setState({
        isActive: !self.state.isActive,
        });
    }

    handleUpdateProfile = () => {
      const {updateProfileById,getClientsForBasfByDistributor,getCurrentUser} = this.props;
      let json;
      //getCurrentUser();
      if(this.props.currentUser.category === "PB" || 
      this.props.currentUser.category == "PSV" || 
      this.props.currentUser.category == "POJF"){
        
        json= {
          id:this.props.currentUser?.id,
          category:this.props.currentUser?.category,
          name:this.state.name,
          fiscal_code:this.state.fiscal_code,
          bussines_name:this.state.bussines_name,
          legal_status:this.state.legal_status,
          year_of_establishment:this.state.year_of_establishment,
          area:this.state.area,
          //address: this.props.currentUser?.address_street +', ' +this.props.currentUser?.address_qyteti +', ' +this.props.currentUser?.address_postal_code,
          address_street:this.state.address_street ,
          address_qyteti:this.state.address_qyteti,
          address_postal_code:this.state.address_postal_code,
          country:this.state.country,
          telefono:this.state.telefono,
          email:this.state.email,
          property:this.state.property
        }
      }
      
      if(this.props.currentUser.category === "PE" || 
      this.props.currentUser.category == "PL" || 
      this.props.currentUser.category == "PI"){
        json= {
          id:this.props.currentUser?.id,
          category:this.props.currentUser?.category,
          name:this.state.name, 
          lastName:this.state.lastName, 
          gender:this.state.gender,
          birthday:this.state.birthday, 
          birthplace:this.state.birthplace, 
          fatherName:this.state.fatherName,
          nationality:this.state.nationality,
          //resident_address:this.state.resident_address,
          address_street:this.state.address_street ,
          address_qyteti:this.state.address_qyteti,
          address_postal_code:this.state.address_postal_code,
          secondNationality:this.state.secondNationality, 
          origin:this.state.origin,
          education:this.state.education,
          educationField:this.state.educationField,
          email:this.state.email,
          telefono:this.state.telefono, 
          facebookProfile:this.state.facebookProfile,  
          twitterProfile:this.state.twitterProfile, 
          linkedinProfile:this.state.linkedinProfile
        }
      }
      

      updateProfileById(json,()=>{
        console.log("updated user 11111111111111 go 1",this.props)
        console.log("updated user 11111111111111 go 2",this.props.getCurrentUser)
        //this.props.getCurrentUser;
        //this.props.getCurrentUser();
        // getCurrentUser();
        console.log("updated user 11111111111111 go 3")
      })
    }
 
    showDistributorDetail = () => {
        this.setState({
            single_client_detail: true,
            distributors_clients_table: false,
            profile_open: false,
            distributors_open: true,
            updates_open: false,
        })
    }

    initGetAreas = () => {
        const {getDistributorsCompetenceAreas, clearClients, clearClientDetails} = this.props;
        this.handleToggle('distributors');
        clearClientDetails();
        clearClients();
        if(this.props.basf.areas.list.data.length === 0 && this.props.basf.areas.list.loading === false ) {
            getDistributorsCompetenceAreas();
        }
    }

    handleResearch = (e) => {
        e.preventDefault();
        if (this.handleResearchValidation()) {
            const {getClientsForBasfByDistributor, getDistributorName} = this.props;
            this.setState({
                distributors_clients_table: true
            });
            getDistributorName(this.props.basf.distributors.list.data);
            getClientsForBasfByDistributor();
        }
    }

    getClientDetailForBasf = (client) => {
        const {getClientDetailsForBasfPreview} = this.props;
        this.showDistributorDetail();
        getClientDetailsForBasfPreview(client);
    }

    handleResearchValidation = () => {
        const {params, errors} = this.state;

        let form_is_valid = true;
        if (!params.area_id.trim()) {
            form_is_valid = false;
            errors.area = true;
        }

        if (!params.distributor_id.trim()) {
            form_is_valid = false;
            errors.distributor = true;
        }

        this.setState((prevState) => ({
            ...prevState,
            errors: errors
        }));

        return form_is_valid;
    }

    showActObservationDetails = () => {
        const {clearBussinesList, getBusinessList} = this.props;
        clearBussinesList();
        getBusinessList();
    }

    setBussinesCategory = (e) => {
        const self = this;
        const {getBusinessList} = self.props;
        self.setState({
            selected_bussines_category: e.target.value
        },()=>{
            console.log("self.state.selected_operator", self.state.selected_bussines_category)
            getBusinessList(self.state.selected_bussines_category)
        })

    }

    render() {
        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
        const {
            profile_open, 
            bussines_list,
            cv_list,
            reports_list,
            uploads_list,
            isActive,
            selected_operator,
            default_user_value,
            loading,
            
            distributors_open, 
            updates_open, 
            distributors_clients_table, 
            single_client_detail,

            default_bussines_category_value,
            selected_bussines_category,


            errors
        } = this.state;
        
        const {setBasfSelectValue, currentUser, auth, getBasfDistributors, clearBussinesList, getBusinessList, clearDistributors, setBenchmarkFilterValue, getDistributorName,getPhotosAndVideosUpdates} = this.props;
        const areas = this.props.basf.areas.list.data;
        const distributors = this.props.basf.distributors.list.data;
        const clients = this.props.basf.clients.list.data;
        const client_detail = this.props.basf.client_detail;
        const benchmarks = this.props.media.benchmarks.list.data;
        const distributor_name = this.props.basf.distributor_name;
        const medias = this.props.media.content.data;
        const imgBaseUrl = process.env.REACT_APP_API_HOST;
        const bussines_list_data = this.props.basf.bussines_list.list.data;

        // console.log("PROFIL_BIZNESI", currentUser);



        if (auth.token === null) {
            return <Redirect to='/login'/>
        }

        return (
          <React.Fragment>
            <section>
              <div className="container">
                <nav aria-label="breadcrumb" className="d-inline-block">
                  <ul className="breadcrumb mb-0">
                    <li
                      className="breadcrumb-item"
                      style={{ marginRight: '5px' }}
                    >
                      <a onClick={() => this.props.history.push('/')}>Kreu</a>
                    </li>

                    {profile_open && (
                      <li
                        className={
                          profile_open
                            ? 'breadcrumb-item active'
                            : 'breadcrumb-item'
                        }
                      >
                        <a onClick={() => this.handleToggle('profile')}>
                          Profili
                        </a>
                      </li>
                    )}

                    {bussines_list && (
                      <li
                        className={
                          bussines_list
                            ? 'breadcrumb-item active'
                            : 'breadcrumb-item'
                        }
                      >
                        <a onClick={() => this.handleToggle('bussines_list')}>
                          Lista e Bizneseve
                        </a>
                      </li>
                    )}

                    {cv_list && (
                      <li
                        className={
                          cv_list ? 'breadcrumb-item active' : 'breadcrumb-item'
                        }
                      >
                        <a onClick={() => this.handleToggle('cv_list')}>
                          Lista e CV-ve
                        </a>
                      </li>
                    )}

                    {reports_list && (
                      <li
                        className={
                          reports_list
                            ? 'breadcrumb-item active'
                            : 'breadcrumb-item'
                        }
                      >
                        <a onClick={() => this.handleToggle('reports_list')}>
                          Raporte
                        </a>
                      </li>
                    )}

                    {uploads_list && (
                      <li
                        className={
                          uploads_list
                            ? 'breadcrumb-item active'
                            : 'breadcrumb-item'
                        }
                      >
                        <a onClick={() => this.handleToggle('uploads_list')}>
                          Ngarko Dokumenta
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </section>

            <section className="d-table w-100 mt-3">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 mt-3 mb-3">
                    <div className="card public-profile border-0 rounded shadow">
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col-lg-2 text-center profile-icon">
                            <i className="uil uil-user-circle" />
                          </div>

                          <div className="col-lg-10 col-md-9">
                            <div className="row align-items-end">
                              <div className="col-md-7 text-md-start text-center mt-4 mt-sm-0">
                                <h3 className="title mb-0">{currentUser?.name} {currentUser?.lastName}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>




            <section className="mb-3">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-12 d-lg-block mt-4">
                    <div className="sidebar sticky-bar p-4 rounded shadow">
                      <div className="widget">
                        <ul
                          className="list-unstyled sidebar-nav mb-0"
                          id="navmenu-nav"
                        >
                          <li
                            className={
                              profile_open
                                ? 'navbar-item account-menu px-0 active'
                                : 'navbar-item account-menu px-0'
                            }
                          >
                            <a
                              onClick={() => this.handleToggle('profile')}
                              className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer"
                            >
                              <span className="h4 mb-0">
                                <i className="uil uil-user" />
                              </span>
                              <h6 className="mb-0 ms-2 custom_font">Profili</h6>
                            </a>
                          </li>

                          <li
                            className={
                              bussines_list
                                ? 'navbar-item account-menu px-0 mt-2 active'
                                : 'navbar-item account-menu px-0 mt-2'
                            }
                          >
                            <a onClick={() => this.handleToggle('bussines_list')} className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer">
                              <span className="h4 mb-0">
                                <i className="uil uil-building" />
                              </span>
                              <h6 className="mb-0 ms-2 custom_font">
                                Lista e Bizneseve
                              </h6>
                            </a>
                          </li>


                          <li
                            className={
                              cv_list
                                ? 'navbar-item account-menu px-0 mt-2 active c'
                                : 'navbar-item account-menu px-0 mt-2'
                            }
                          >
                            <a
                              onClick={() => this.handleToggle('cv_list')}

                              className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer"
                            >
                              <span className="h4 mb-0">
                                <i className="uil uil-users-alt" />
                              </span>
                              <h6 className="mb-0 ms-2 custom_font">CV</h6>
                            </a>
                          </li>


                          <li
                            className={
                                reports_list
                                ? 'navbar-item account-menu px-0 mt-2 active c'
                                : 'navbar-item account-menu px-0 mt-2'
                            }
                          >
                            <a
                              onClick={() => this.handleToggle('reports_list')}
                              className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer"
                            >
                              <span className="h4 mb-0">
                                <i className="uil uil-analytics" />
                              </span>
                              <h6 className="mb-0 ms-2 custom_font">Raporte</h6>
                            </a>
                          </li>

                          <li
                            className={
                              uploads_list
                                ? 'navbar-item account-menu px-0 mt-2 active c'
                                : 'navbar-item account-menu px-0 mt-2'
                            }
                          >
                            <a 
                                 onClick={() => this.handleToggle('uploads_list')}
                                className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer">
                              <span className="h4 mb-0">
                                <i className="uil uil-upload" />
                              </span>
                              <h6 className="mb-0 ms-2 custom_font">Ngarko Dokumenta</h6>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  
                  {profile_open && (currentUser.category === "PE" || currentUser.category == "PL" || currentUser.category == "PI") ? (
                    <>
                      <div className="col-lg-8 col-12">
                        <div className="pb-4">
                          <div className="row">
                            <div className="col-md-12 mt-4">
                                <div className="rounded shadow mt-4" onClick={() => this.setIsActive(!isActive)} >
                                    <label className="custom_font align-items-center py-2 px-4">Të dhënat e profilit{' '}</label>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-md-6 mt-4">
                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Emër:
                                        </h6>
                                        <p className="text-muted mb-0">
                                          {/* {currentUser?.name} */}
                                          <input type="text" className="form-control" name="name" value={this.state.name} onChange={this.handleChangeInput}/>
                                        </p>
                                      </div>
                                    </div>

                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Mbiemër:
                                        </h6>
                                        <p className="text-muted mb-0">
                                          {/* {currentUser?.lastName} */}
                                          <input type="text" className="form-control" name="lastName" value={this.state.lastName} onChange={this.handleChangeInput}/>
                                        </p>
                                      </div>
                                    </div>

                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Gjinia:
                                        </h6>
                                        <p className="text-muted mb-0">
                                          {/* {currentUser?.gender} */}
                                          <input type="text" className="form-control" name="gender" value={this.state.gender} onChange={this.handleChangeInput}/>
                                        </p>
                                      </div>
                                    </div>
                                    
                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Datelindje:
                                        </h6>
                                        <div className="text-muted mb-0 elm-datepicker-wrapper">
                                          {/* {currentUser?.birthday} */}
                                          {/* <input type="text" className="form-control" name="birthday" value={this.state.birthday} onChange={this.handleChangeInput}/> */}
                                          <DatePicker
                                                className="form-control"
                                                placeholderText={'Datelindje'}
                                                dateFormat="dd/MM/yyyy"
                                                name="birthday"
                                                //selected={this.state.birthday}
                                                value={this.state.birthday}
                                                // onChange={this.handleChangeInput} 
                                                onChange={(date) => {
                                                  console.log("the date 1",date);
                                                  const d = new Date(date).toLocaleDateString('fr-FR');
                                                  console.log("the date",d);
                                                  this.setState({
                                                    ...this.state,
                                                    birthday:d
                                                  })
                                                  //setDate(d);
                                                }}
                                            />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Vëndlindje:
                                        </h6>
                                        <p className="text-muted mb-0">
                                          {/* {currentUser?.birthplace} */}
                                          <input type="text" className="form-control" name="birthplace" value={this.state.birthplace} onChange={this.handleChangeInput}/>
                                        </p>
                                      </div>
                                    </div>

                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Atësi:
                                        </h6>
                                        <p className="text-muted mb-0">
                                          {/* {currentUser?.fatherName} */}
                                          <input type="text" className="form-control" name="fatherName" value={this.state.fatherName} onChange={this.handleChangeInput}/>
                                        </p>
                                      </div>
                                    </div>
                                    
                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Shtetesi:
                                        </h6>
                                        <p className="text-muted mb-0">
                                          {/* {currentUser?.nationality} */}
                                          <input type="text" className="form-control" name="nationality" value={this.state.nationality} onChange={this.handleChangeInput}/>
                                        </p>
                                      </div>
                                    </div>

                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Adresa: Emri Rruges
                                        </h6>
                                        <p className="text-muted mb-0">
                                          {/* {currentUser?.resident_address} */}
                                          <input type="text" className="form-control" name="address_street" value={this.state.address_street} onChange={this.handleChangeInput}/>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Adresa: Kodi Postar
                                        </h6>
                                        <p className="text-muted mb-0">
                                          {/* {currentUser?.resident_address} */}
                                          <input type="text" className="form-control" name="address_postal_code" value={this.state.address_postal_code} onChange={this.handleChangeInput}/>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Adresa: Qyteti
                                        </h6>
                                        <p className="text-muted mb-0">
                                          {/* {currentUser?.resident_address} */}
                                          <input type="text" className="form-control" name="address_qyteti" value={this.state.address_qyteti} onChange={this.handleChangeInput}/>
                                        </p>
                                      </div>
                                    </div>
                                  

                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Shtetesi e dytë:
                                        </h6>
                                        <p className="text-muted mb-0">
                                          {/* {currentUser?.secondNationality} */}
                                          <input type="text" className="form-control" name="secondNationality" value={this.state.secondNationality} onChange={this.handleChangeInput}/>
                                        </p>
                                      </div>
                                    </div>
                                  </div> 
                                  <div className="col-md-6 mt-4">
                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Origjina:
                                        </h6>
                                        <p className="text-muted mb-0">
                                          {/* {currentUser?.origin} */}
                                          <input type="text" className="form-control" name="origin" value={this.state.origin} onChange={this.handleChangeInput}/>
                                        </p>
                                      </div>
                                    </div>

                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Arsimi:
                                        </h6> 
                                        <p className="text-muted mb-0">
                                          {/* {currentUser?.education} */}
                                          <input type="text" className="form-control" name="education" value={this.state.education} onChange={this.handleChangeInput}/>
                                        </p>
                                      </div>
                                    </div>

                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Fusha e Arsimit:
                                        </h6> 
                                        <p className="text-muted mb-0">
                                          {/* {currentUser?.educationField} */}
                                          <input type="text" className="form-control" name="educationField" value={this.state.educationField} onChange={this.handleChangeInput}/>
                                        </p>
                                      </div>
                                    </div>

                                    <div className="d-flex align-items-center mb-2 pb-2">
                                      <i
                                        data-feather="mail"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Email:
                                        </h6>
                                        <p className="text-muted mb-0">
                                          {/* {currentUser?.email} */}
                                          <input type="text" className="form-control" name="email" value={this.state.email} onChange={this.handleChangeInput}/>
                                        </p>
                                      </div>
                                    </div>

                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Telefoni:
                                        </h6> 
                                        <p className="text-muted mb-0">
                                          {/* {currentUser?.telefono} */}
                                          <input type="text" className="form-control" name="telefono" value={this.state.telefono} onChange={this.handleChangeInput}/>
                                        </p>
                                      </div>
                                    </div>

                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Profili Facebook:
                                        </h6>
                                        {/* <p className="text-muted mb-0">{currentUser?.facebookProfile}</p> */}

                                        <a
                                          href={
                                            'https://www.facebook.com/' +
                                            currentUser?.facebookProfile
                                          }
                                          target="_blank"
                                        >
                                          {
                                            <span
                                              className="mt-10"
                                              style={{ fontSize: '1rem' }}
                                            >
                                              {currentUser?.facebookProfile &&
                                                'https://www.facebook.com/' +
                                                  currentUser?.facebookProfile}
                                            </span>
                                          }
                                        </a>
                                        <input type="text" className="form-control" name="facebookProfile" value={this.state.facebookProfile} onChange={this.handleChangeInput}/>
                                      </div>
                                    </div>
                                    
                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Profili Twitter:
                                        </h6>
                                        {/* <p className="text-muted mb-0">{currentUser?.twitterProfile}</p> */}
                                        <a
                                          href={
                                            'https://twitter.com/' +
                                            currentUser?.twitterProfile
                                          }
                                          target="_blank"
                                        >
                                          {
                                            <span
                                              className="mt-10"
                                              style={{ fontSize: '1rem' }}
                                            >
                                              {currentUser?.twitterProfile &&
                                                'https://twitter.com/' +
                                                  currentUser?.twitterProfile}
                                            </span>
                                          }
                                        </a>
                                        <input type="text" className="form-control" name="twitterProfile" value={this.state.twitterProfile} onChange={this.handleChangeInput}/>
                                      </div>
                                    </div>

                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                      <i
                                        data-feather="user"
                                        className="fea icon-ex-md me-3"
                                      />
                                      <div className="flex-1">
                                        <h6 className="text_green custom_font mb-0">
                                          Profili Linkedin:
                                        </h6>
                                        {/* <p className="text-muted mb-0">{currentUser?.linkedinProfile}</p> */}
                                        <a
                                          href={
                                            'https://www.linkedin.com/in/' +
                                            currentUser?.linkedinProfile
                                          }
                                          target="_blank"
                                        >
                                          {
                                            <span
                                              className="mt-10"
                                              style={{ fontSize: '1rem' }}
                                            >
                                              {currentUser?.linkedinProfile &&
                                                'https://www.linkedin.com/in/' +
                                                  currentUser?.linkedinProfile}
                                            </span>
                                          }
                                        </a>
                                        <input type="text" className="form-control" name="linkedinProfile" value={this.state.linkedinProfile} onChange={this.handleChangeInput}/>
                                      </div>
                                    </div>

                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                        <i
                                          data-feather="user"
                                          className="fea icon-ex-md me-3"
                                        />    
                                        <div className="flex-1">

                                          <button type="button" style={{marginTop:"24px",width:"100%"}} onClick={() => this.handleUpdateProfile()} className="sub-menu-item btn btn-primary border-button">Modifiko</button>
                                        
                                        </div>
                                      </div>

                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {profile_open && (currentUser.category === "PB" || currentUser.category == "PSV" || currentUser.category == "POJF") ? (
                    <>
                      <div className="col-lg-8 col-12">
                        <div className="pb-4">
                          <div className="row">
                            <section>
                              <div className="rounded shadow mt-4" onClick={() => this.setIsActive(!isActive)} >
                                    <label className="custom_font align-items-center py-2 px-4">Të dhënat e profilit{' '}</label>
                              </div>

                              <div className="row mt-2">
                                {/* First Section */}
                                <div className="col-md-6 mt-4">
                                  <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                    <i
                                      data-feather="user"
                                      className="fea icon-ex-md me-3"
                                    />
                                    <div className="flex-1">
                                      <h6 className="text_green custom_font mb-0">
                                        Kodi Fiskal:
                                      </h6>
                                      <p className="text-muted mb-0">
                                        {/* {currentUser?.fiscal_code} */}
                                        <input type="text" className="form-control" name="fiscal_code" 

                                        // onChange={(event) => {
                                        //   const value = event.target.value
                                        //   this.setState((prevState) => ({
                                        //     ...prevState,
                                        //     user: {
                                        //       ...prevState.user,
                                        //       fiscal_code: value,
                                        //     },
                                        //     errors: {
                                        //       ...prevState.errors,
                                        //       fiscal_code: false,
                                        //     },
                                        //   }))
                                        //   setCurrentUserValue('fiscal_code', value,()=>{
                                        //     console.log("callback atempt !!!!!!!!!!!")
                                        //   })
                                        // }}
                                        onChange={this.handleChangeInput}
                                        value={this.state.fiscal_code}
                                        // value={currentUser?.fiscal_code}
                                        />
                                      </p>
                                    </div>
                                  </div>

                                  <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                    <i
                                      data-feather="user"
                                      className="fea icon-ex-md me-3"
                                    />
                                    <div className="flex-1">
                                      <h6 className="text_green custom_font mb-0">
                                        Emër:
                                      </h6>
                                      <p className="text-muted mb-0">
                                        {/* {currentUser?.name} */}
                                        <input type="text" className="form-control" name="name" value={this.state.name} onChange={this.handleChangeInput}/>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                    <i
                                      data-feather="user"
                                      className="fea icon-ex-md me-3"
                                    />
                                    <div className="flex-1">
                                      <h6 className="text_green custom_font mb-0">
                                        Emri Tregtar:
                                      </h6>
                                      <p className="text-muted mb-0">
                                        {/* {currentUser?.bussines_name} */}
                                        <input type="text" className="form-control" name="bussines_name" value={this.state.bussines_name} onChange={this.handleChangeInput}/>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                    <i
                                      data-feather="user"
                                      className="fea icon-ex-md me-3"
                                    />
                                    <div className="flex-1">
                                      <h6 className="text_green custom_font mb-0">
                                        Statusi Ligjor:
                                      </h6>
                                      <p className="text-muted mb-0">
                                        {/* {currentUser?.legal_status} */}
                                        <input type="text" className="form-control" name="legal_status" value={this.state.legal_status} onChange={this.handleChangeInput}/>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                    <i
                                      data-feather="user"
                                      className="fea icon-ex-md me-3"
                                    />
                                    <div className="flex-1">
                                      <h6 className="text_green custom_font mb-0">
                                        Objekti:
                                      </h6>
                                      <p className="text-muted mb-0">
                                        {/* {currentUser?.area} */}
                                        <input type="text" className="form-control" name="area" value={this.state.area} onChange={this.handleChangeInput}/>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                    <i
                                      data-feather="user"
                                      className="fea icon-ex-md me-3"
                                    />
                                    <div className="flex-1">
                                      <h6 className="text_green custom_font mb-0">
                                        Viti i themelimit:
                                      </h6>
                                      <p className="text-muted mb-0">
                                        {/* {currentUser?.year_of_establishment} */}
                                        <input type="text" className="form-control" name="year_of_establishment" value={this.state.year_of_establishment} onChange={this.handleChangeInput}/>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                    <i
                                      data-feather="user"
                                      className="fea icon-ex-md me-3"
                                    />
                                    <div className="flex-1">
                                      <h6 className="text_green custom_font mb-0">
                                        Pronësia:
                                      </h6>
                                      <p className="text-muted mb-0">
                                        {/* {currentUser?.property} */}
                                        <input type="text" className="form-control" name="property" value={this.state.property} onChange={this.handleChangeInput}/>
                                      </p>
                                    </div>
                                  </div>

                                  
                                </div>

                                {/* Second Section */}
                                <div className="col-md-6 mt-4">
                                  
                                  <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                    <i
                                      data-feather="user"
                                      className="fea icon-ex-md me-3"
                                    />
                                    <div className="flex-1">
                                      <h6 className="text_green custom_font mb-0">
                                        Email:
                                      </h6>
                                      <p className="text-muted mb-0">
                                        {/* {currentUser?.email} */}
                                        <input type="text" className="form-control" name="email" value={this.state.email} onChange={this.handleChangeInput}/>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                    <i
                                      data-feather="user"
                                      className="fea icon-ex-md me-3"
                                    />
                                    <div className="flex-1">
                                      <h6 className="text_green custom_font mb-0">
                                        Numri i Telefonit:
                                      </h6>
                                      <p className="text-muted mb-0">
                                        {/* {currentUser?.telefono} */}
                                        <input type="text" name="telefono" className="form-control" value={this.state.telefono} onChange={this.handleChangeInput}/>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                    <i
                                      data-feather="user"
                                      className="fea icon-ex-md me-3"
                                    />
                                    <div className="flex-1">
                                      <h6 className="text_green custom_font mb-0">
                                        Adresa: Emri Rruges
                                      </h6>
                                      <p className="text-muted mb-0">
                                        {/* {currentUser?.address_street +
                                          ', ' +
                                          currentUser?.address_qyteti +
                                          ', ' +
                                          currentUser?.address_postal_code} */}
                                          <input type="text" className="form-control" name="address_street" value={this.state.address_street} onChange={this.handleChangeInput}/>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                    <i
                                      data-feather="user"
                                      className="fea icon-ex-md me-3"
                                    />
                                    <div className="flex-1">
                                      <h6 className="text_green custom_font mb-0">
                                        Adresa: Kodi Postar
                                      </h6>
                                      <p className="text-muted mb-0">
                                        {/* {currentUser?.address_street +
                                          ', ' +
                                          currentUser?.address_qyteti +
                                          ', ' +
                                          currentUser?.address_postal_code} */}
                                          <input type="text" className="form-control" name="address_postal_code" value={this.state.address_postal_code} onChange={this.handleChangeInput}/>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                    <i
                                      data-feather="user"
                                      className="fea icon-ex-md me-3"
                                    />
                                    <div className="flex-1">
                                      <h6 className="text_green custom_font mb-0">
                                        Adresa: Qyteti
                                      </h6>
                                      <p className="text-muted mb-0">
                                        {/* {currentUser?.address_street +
                                          ', ' +
                                          currentUser?.address_qyteti +
                                          ', ' +
                                          currentUser?.address_postal_code} */}
                                          <input type="text" className="form-control" name="address_qyteti" value={this.state.address_qyteti} onChange={this.handleChangeInput}/>
                                      </p>
                                    </div>
                                  </div>
                                  
                                  <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                    <i
                                      data-feather="user"
                                      className="fea icon-ex-md me-3"
                                    />
                                    <div className="flex-1">
                                      <h6 className="text_green custom_font mb-0">
                                        Shteti:
                                      </h6>
                                      <p className="text-muted mb-0">
                                        {/* {currentUser?.country} */}
                                        <input type="text" name="country" className="form-control" value={this.state.country} onChange={this.handleChangeInput}/>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                    <i
                                      data-feather="user"
                                      className="fea icon-ex-md me-3"
                                    />    
                                    <div className="flex-1">

                                      <button type="button" style={{marginTop:"24px",width:"100%"}} onClick={() => this.handleUpdateProfile()} className="sub-menu-item btn btn-primary border-button">Modifiko</button>
                                     
                                    </div>
                                  </div>
                                  
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                      {currentUser?.people_connected_in_society === 1 &&
                        currentUser?.shareholders.length > 0 && (
                          <div className="border-bottom pb-4">
                            <div className="row">
                              <div className="col-md-12 mt-4">
                                <h5>{distributor_name}</h5>
                                <div className="table-responsive bg-white shadow rounded">
                                  <table className="table mb-0 table-center invoice-tb">
                                    <thead className="bg-light">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Lidhja në shoqëri
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Emër
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Mbiemër
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Gjinia
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Datelindje
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Vendlindje
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Atesi
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Adrese Banimi
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Shtetesia
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Origjina
                                        </th>
                                        {/* <th scope="col" className="border-bottom text-center">Azioni</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {currentUser?.shareholders.map(
                                        (shareholder, key) => {
                                          return (
                                            <tr key={key}>
                                              <td
                                                scope="row"
                                                className="text-start"
                                              >
                                                {capitalize(
                                                  shareholder.shareholder_type
                                                )}
                                              </td>
                                              <td
                                                scope="row"
                                                className="text-start"
                                              >
                                                {capitalize(
                                                  shareholder.firstName
                                                )}
                                              </td>
                                              <td className="text-start">
                                                {capitalize(
                                                  shareholder.lastName
                                                )}
                                              </td>
                                              <td className="text-start">
                                                {capitalize(shareholder.gender)}
                                              </td>
                                              <td className="text-start">
                                                {capitalize(
                                                  shareholder.birthday
                                                )}
                                              </td>
                                              <td className="text-start">
                                                {capitalize(
                                                  shareholder.birthplace
                                                )}
                                              </td>
                                              <td className="text-start">
                                                {capitalize(
                                                  shareholder.fatherName
                                                )}
                                              </td>
                                              <td className="text-start">
                                                {capitalize(
                                                  shareholder.nationality
                                                )}
                                              </td>
                                              <td className="text-start">
                                                {capitalize(
                                                  shareholder.resident_address
                                                )}
                                              </td>
                                              <td className="text-start">
                                                {capitalize(
                                                  shareholder.secondNationality
                                                )}
                                              </td>
                                              <td className="text-start">
                                                {capitalize(shareholder.origin)}
                                              </td>
                                              <td className="text-start">
                                                {shareholder.email}
                                              </td>
                                              <td className="text-start">
                                                {shareholder.telefono}
                                              </td>

                                              {/* <td className="text-center"><i
                                                                                            onClick={() => this.getClientDetailForBasf(client)}
                                                                                            className="uil uil-eye user_profile_text_green cursor_pointer"/>
                                                                                        </td> */}
                                            </tr>
                                          )
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </>
                  ) : null}

                  {bussines_list ? (
                    <>
                      <div className="col-lg-8 col-12">
                        <div className="pb-4">
                          {/* <div className="row">
                            <div className="col-md-12 mt-4">
                                <div className="accordion rounded shadow" onClick={() => this.setIsActive(!isActive)} style={{ cursor: 'pointer' }}>                                    
                                    <label className="custom_font align-items-center py-2 px-4">FILTRO TE DHENAT E PROFILIT{' '}</label>

                                    {!isActive && (
                                        <Icon
                                            icon="mdi:eye"
                                            width="22"
                                            height="22"
                                            className="fea icon-ex-md  card-category cursor_pointer"
                                        />
                                    )}

                                    {isActive && (
                                        <Icon
                                            icon="mdi:hide"
                                            width="22"
                                            height="22"
                                            className="fea icon-ex-md  card-category cursor_pointer"
                                        />
                                    )}
                                </div>
                            </div>
                          </div> */}

                          {
                            isActive && 
                            (
                                <>
                                    <div className="mt-4">
                                        <div className="row">
                                            <div className="col-md-12 mt-3">
                                                <div className="mb-4">
                                                    <label
                                                        className={
                                                            errors.operator
                                                            ? 'form-label label_error'
                                                            : 'form-label'
                                                        }
                                                    >
                                                        Kategoritë
                                                    </label>
                                                    <div className="form-icon position-relative">
                                                        <Icon
                                                            icon="bx:bx-building"
                                                            width="17"
                                                            height="17"
                                                            className="custom_icon"
                                                        />
                                                        <select
                                                            className={'form-select form-control ps-5'}
                                                            placeholder="Zhgjidh Kategorine"
                                                            selected={selected_bussines_category}
                                                            // onChange={this.setBussinesCategory}
                                                            onChange={(event) => {
                                                                const value = event.target.value
                                                                this.setState((prevState) => ({
                                                                    ...prevState,
                                                                    params: {
                                                                        bussines_category: value,
                                                                        user_id: auth.user.id
                                                                    }, errors: {
                                                                        ...prevState.errors,
                                                                        area: false
                                                                    },
                                                                    distributors_clients_table: false
                                                                }), () => {
                                                                    setBasfSelectValue('bussines_category', value);
                                                                    setBasfSelectValue('user_id', auth.user.id);
                                                                    this.setState({ selected_bussines_category: value})
                                                                    clearBussinesList();
                                                                    // getBusinessList();

                                                                    // clearDistributors();
                                                                    // clearClients();
                                                                    // getBasfDistributors();

                                                                })
                                                            }}
                                                            id="bussines"
                                                            value={selected_bussines_category}
                                                            aria-label="DEFAULT_BUSSINES_CATEGORY"
                                                            defaultValue={'DEFAULT_BUSSINES_CATEGORY'}
                                                        >
                                                            <option value="DEFAULT_BUSSINES_CATEGORY">{default_bussines_category_value}</option>

                                                            {bussines_categories.map(
                                                                (cat, index) => {
                                                                    return (
                                                                        <option key={cat.id} value={cat.value}>
                                                                            {cat.name}
                                                                        </option>
                                                                    )
                                                                }
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                        

                                            {/* {
                                                this.props.basf.user_act_observation_details.list.data.length === 0 
                                                ? (
                                                    <div className="text-center custom_font"> Nuk ka të dhëna për kategorinë 'Listë Biznesesh'</div>
                                                ) 
                                                : (
                                                    <Col xl={12} lg={12} sm={12} className="mb-2">
                                                        <SweetAlert
                                                            title={`Akt konstatime - ${this.props.basf.user_act_observation_details.list.data[0].Operatori}`}
                                                            showCancel
                                                            style={{ width: '40em !important' }}
                                                            cancelBtnText="Mbyll"
                                                            confirmBtnText="Shkarko ne Excel "
                                                            cancelBtnBsStyle="light"
                                                            confirmBtnBsStyle="primary"
                                                            onConfirm={() => {
                                                            this.exportToExcel(
                                                                this.props.basf
                                                                .user_act_observation_details.list
                                                                .data
                                                            )
                                                            }}
                                                            onCancel={() => this.hide_act_obs_alert()}
                                                            type={'controlled'}
                                                            dependencies={[]}
                                                        >
                                                            {(renderProps) => (
                                                            <>
                                                                <div className="col-lg-12 col-sm-12">
                                                                <div className="table-responsive bg-white shadow rounded">
                                                                    <table className="table mb-0 table-center invoice-tb">
                                                                    <thead className="bg-light"title="Akt Konstatime Totale">
                                                                        <tr>
                                                                        <th scope="col"className="border-bottom text-start">Num</th>
                                                                        <th scope="col" className="border-bottom text-start">Operatori</th>
                                                                        <th scope="col" className="border-bottom text-start">Emri</th>
                                                                        <th scope="col" className="border-bottom text-start">Mbiemri</th>
                                                                        <th scope="col" className="border-bottom text-start">Atësia</th>
                                                                        <th scope="col" className="border-bottom text-start">Karta ID</th>
                                                                        <th scope="col" className="border-bottom text-start">Qarku</th>
                                                                        <th scope="col" className="border-bottom text-start">Bashkia</th>
                                                                        <th scope="col" className="border-bottom text-start">Njësia Administrative</th>
                                                                        <th scope="col" className="border-bottom text-start">Fshati</th>
                                                                        <th scope="col" className="border-bottom text-start">FamiljaPrezenteNeBanese</th>
                                                                        <th scope="col" className="border-bottom text-start">KaTokëBujqësore</th>
                                                                        <th scope="col" className="border-bottom text-start">TëArdhuratNgaTokaApoBlektoria</th>
                                                                        <th scope="col" className="border-bottom text-start">NdihmëEkonomike</th>
                                                                        <th scope="col" className="border-bottom text-start">PensionInvaliditeti</th>
                                                                        <th scope="col" className="border-bottom text-start">PensionPleqërie</th>
                                                                        <th scope="col" className="border-bottom text-start">Akpa</th>
                                                                        <th scope="col" className="border-bottom text-start">LlojiPunësimit</th>
                                                                        <th scope="col" className="border-bottom text-start">MjetiMotorrik</th>
                                                                        <th scope="col" className="border-bottom text-start">TipiBanesës</th>
                                                                        <th scope="col" className="border-bottom text-start">GjëndjaBanesës</th>
                                                                        <th scope="col" className="border-bottom text-start">Kushtet_e_familjes</th>
                                                                        <th scope="col" className="border-bottom text-start">FirmosurNgaTëGjithëAnëtarët</th>
                                                                        <th scope="col" className="border-bottom text-start">PërfitonNdihmëEkonomike</th>
                                                                        <th scope="col" className="border-bottom text-start">FamiljeEkstremishteVarfër</th>
                                                                        <th scope="col" className="border-bottom text-start">NevojëPërShërbimeTëTjera</th>
                                                                        <th scope="col" className="border-bottom text-start">FormulariPlotësuarNëBanesë</th>
                                                                        <th scope="col" className="border-bottom text-start">Latitude</th>
                                                                        <th scope="col" className="border-bottom text-start">Longitude</th>
                                                                        <th scope="col" className="border-bottom text-start">Data Rregjistrimit</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {this.props.basf.user_act_observation_details.list?.data.map(
                                                                        (item, key) => {
                                                                            return (
                                                                            <tr key={key}>
                                                                                <td scope="row" className="text-start">{key + 1}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.Operatori)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.Emri)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.Mbiemri)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.Atesia)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.ID)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.Qarku)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.Bashkia)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.NjesiaAdministrative)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.Fshati)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.FamiljaPrezenteNeBanese)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.KaTokëBujqësore)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.TëArdhuratNgaTokaApoBlektoria)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.NdihmëEkonomike)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.PensionInvaliditeti)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.PensionPleqërie)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.Akpa)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.LlojiPunësimit)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.MjetiMotorrik)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.TipiBanesës)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.GjëndjaBanesës)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.Kushtet_e_familjes)}</td>   
                                                                                <td scope="row" className="text-start">{capitalize(item.FirmosurNgaTëGjithëAnëtarët)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.PërfitonNdihmëEkonomike)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.FamiljeEkstremishteVarfër)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.NevojëPërShërbimeTëTjera)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.FormulariPlotësuarNëBanesë)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.Latitude)}</td>
                                                                                <td scope="row" className="text-start">{capitalize(item.Longitude)}</td>
                                                                                <td scope="row" className="text-start">{item.DataRregjistrimit}</td>
                                                                            </tr>
                                                                            )
                                                                        }
                                                                        )}
                                                                    </tbody>
                                                                    </table>
                                                                </div>
                                                                </div>
                                                            </>
                                                            )}
                                                        </SweetAlert>
                                                    </Col>
                                                )
                                            } */}
                                        
                                        </div>

                                        <div className="row">
                                            <div className="loader-wrapper">
                                                <DotLoader color="#4a5c8a" loading={loading} />
                                            </div>

                                            <div className="d-grid">
                                                <button className="btn btn-primary border-button" disabled={loading} onClick={this.showActObservationDetails}>Shfaq</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                          }
                        </div>
                      </div>
                    </>
                  ) : null}

                  {uploads_list ? (
                    <>
                    {/*Logoja e Biznesit*/}
                      <div className="col-lg-8 col-12">
                        <div className="pb-4">
                          <div className="row">
                            <div className="rounded shadow mt-4">
                              <label className="custom_font align-items-center py-2 px-4">Dokumentat</label>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-4 mt-4">
                                <label
                                  className={
                                    errors.bussines_logo_error_show
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                  htmlFor="customFile"
                                >
                                  Ngarko ketu logon e biznesit
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <div className="input-group">
                                  <input
                                    type="file"
                                    accept="image/jpg, image/jpeg, image/png"
                                    className={
                                      errors.bussines_logo_error_show
                                        ? 'form-control input_error'
                                        : 'form-control'
                                    }
                                    onChange={this.uploadMultipleFiles}
                                    // multiple
                                    id="bussines_logo"
                                  />
                                  {
                                    this.hasDocument("logo_img") &&
                                      <>
                                          <span style={{color:"#28a745"}} className="input-group-addon">
                                              <i className="uil uil-check"></i>
                                          </span>
                                          <a style={{cursor:"pointer",color:"#bf1e2e"}} href={this.hasDocument("logo_img")} target="_blank" className="input-group-addon">
                                              <i className="uil-download-alt"></i>
                                          </a>
                                          {/*<img*/}
                                          {/*    src={imgBaseUrl.concat(this.hasDocument("logo_img"))}*/}
                                          {/*    className="card-img-top rounded-top"*/}
                                          {/*    alt="..."/>*/}
                                      </>
                                  }
                                </div>
                                <p
                                  className="text-danger"
                                  style={{ fontSize: '0.7rem' }}
                                >
                                  Pranohen vetem imazhe .jpg, .jpeg dhe .png
                                </p>
                                {/* {errors.bussines_logo_error_show && <p className="text-danger" style={{fontSize: "0.8rem"}}>Lejohen vetem imazhe me permasa 300x300 px.</p>} */}
                              </div>
                            </div>

                            {/* Fotoja e produkit/sherbimit */}
                            <div className="col-md-12">
                              <div className="mb-4 mt-4">
                                <label
                                  className={
                                    errors.bussines_logo_error_show
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                  htmlFor="customFile"
                                >
                                  Ngarko ketu foton e produktit ose
                                  sherbimit
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <div className="input-group">
                                  <input
                                    type="file"
                                    accept="image/jpg, image/jpeg, image/png"
                                    className={
                                      errors.bussines_logo_error_show
                                        ? 'form-control input_error'
                                        : 'form-control'
                                    }
                                    onChange={this.uploadProductImage}
                                    // multiple
                                    id="productImage"
                                  />
                                  {
                                    this.hasDocument("product_or_service_img") && <><span style={{color:"#28a745"}} className="input-group-addon"><i className="uil uil-check"></i></span><a style={{cursor:"pointer",color:"#bf1e2e"}} href={this.hasDocument("product_or_service_img")} target="_blank" className="input-group-addon"><i className="uil-download-alt"></i></a></>
                                  }
                                </div>
                                <p
                                  className="text-danger"
                                  style={{ fontSize: '0.7rem' }}
                                >
                                  Pranohen vetem imazhe .jpg, .jpeg dhe .png
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-4 mt-4">
                                <label
                                  className={
                                    errors.bussines_logo_error_show
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                  htmlFor="customFile"
                                >
                                  Ngarko ketu foton e profilit
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <div className="input-group">
                                  <input
                                    type="file"
                                    accept="image/jpg, image/jpeg, image/png"
                                    className={
                                      errors.bussines_logo_error_show
                                        ? 'form-control input_error'
                                        : 'form-control'
                                    }
                                    onChange={this.uploadProfileImage}
                                    // multiple
                                    id="profileImage"
                                  />
                                  {
                                    this.hasDocument("profile_img") && <><span style={{color:"#28a745"}} className="input-group-addon"><i className="uil uil-check"></i></span><a style={{cursor:"pointer",color:"#bf1e2e"}} href={this.hasDocument("profile_img")} target="_blank" className="input-group-addon"><i className="uil-download-alt"></i></a></>
                                  }
                                </div>
                                <p
                                  className="text-danger"
                                  style={{ fontSize: '0.7rem' }}
                                >
                                  Pranohen vetem imazhe .jpg, .jpeg dhe .png
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                 {(selected_bussines_category == "PB" || selected_bussines_category == "PSV" || selected_bussines_category == "POJF") && bussines_list_data && bussines_list_data.length > 0 && (
                     <div className="border-bottom pb-4">
                         <div className="row">
                             <div className="col-md-12 mt-4">
                                 <h5>{distributor_name}</h5>
                                 <button type="button" className="excel_download btn btn-primary border-button">
                                  <ExcelFile element={<><i className="uil uil-download-alt"></i> Shkarko ne Excel</>}>
                                        <ExcelSheet data={bussines_list_data} name="Lista e profileve">
                                            <ExcelColumn label="Kodi Fiskal" value="fiscal_code"/>
                                            <ExcelColumn label="Emër" value="name"/>
                                            <ExcelColumn label="Emri Tregtar" value="bussines_name"/>
                                            <ExcelColumn label="Statusi Ligjor" value="legal_status"/>
                                            <ExcelColumn label="Objekti" value="area"/>
                                            <ExcelColumn label="Viti i themelimit" value="year_of_establishment"/>
                                            <ExcelColumn label="Pronësia" value="property"/>
                                            <ExcelColumn label="E-mail" value="email"/>
                                            <ExcelColumn label="Telefoni" value="telefono"/>
                                            <ExcelColumn label="Adresa. Rruga" value="address_street"/>
                                            <ExcelColumn label="Adresa. Kodi Postar" value="address_qyteti"/>
                                            <ExcelColumn label="Adresa. Qyteti" value="address_postal_code"/>
                                            <ExcelColumn label="Shteti" value="district"/>
                                            <ExcelColumn label="Qarku" value="country"/>
                                        </ExcelSheet>
                                    </ExcelFile>
                                  </button>
                                 <div className="table-responsive bg-white shadow rounded">
                                     <table className="table mb-0 table-center invoice-tb">
                                             <thead className="bg-light">
                                             <tr>
                                                 <th scope="col" className="border-bottom text-start">Kodi Fiskal</th>
                                                 <th scope="col" className="border-bottom text-start">Emër</th>
                                                 <th scope="col" className="border-bottom text-start">Emri Tregtar</th>
                                                 <th scope="col" className="border-bottom text-start">Statusi Ligjor</th>
                                                 <th scope="col" className="border-bottom text-start">Objekti</th>
                                                 <th scope="col" className="border-bottom text-start">Viti i themelimit</th>
                                                 <th scope="col" className="border-bottom text-start">Pronësia</th>
                                                 <th scope="col" className="border-bottom text-start">E-mail</th>
                                                 <th scope="col" className="border-bottom text-start">Telefoni</th>
                                                 <th scope="col" className="border-bottom text-start">Adresa. Rruga</th>
                                                 <th scope="col" className="border-bottom text-start">Adresa. Kodi Postar</th>
                                                 <th scope="col" className="border-bottom text-start">Adresa. Qyteti</th>
                                                 <th scope="col" className="border-bottom text-start">Shteti</th>
                                                 <th scope="col" className="border-bottom text-start">Qarku</th>
                                                 {/*<th scope="col" className="border-bottom text-start">Logo e biznesit</th>*/}
                                                 {/*<th scope="col" className="border-bottom text-start">Foto e produktit ose sherbimit</th>*/}

                                                 {/* <th scope="col" className="border-bottom text-center">Azioni</th> */}
                                             </tr>
                                             </thead>
                                             <tbody>
                                             {bussines_list_data.map(
                                                 (item, key) => {
                                                     return (
                                                         <tr key={key}>
                                                             <td scope="row" className="text-start">{capitalize(item.fiscal_code)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.name)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.bussines_name)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.legal_status)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.area)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.year_of_establishment)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.property)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.email)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.telefono)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.address_street)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.address_qyteti)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.address_postal_code)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.district)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.country)}</td>
                                                             {/*<td scope="row" className="text-start">{capitalize(item.people_connected_in_society)}</td>*/}
                                                             {/*<td scope="row" className="text-start">{capitalize(item.bussines_img)}</td>*/}
                                                             {/*<td scope="row" className="text-start">{capitalize(item.product_img)}</td>*/}
                                                             {/*<td className="text-center"><i onClick={() => this.getClientDetailForBasf(client)} className="uil uil-eye user_profile_text_green cursor_pointer"/></td>*/}
                                                         </tr>
                                                     )
                                                 }
                                             )}
                                             </tbody>
                                         </table>
                                 </div>
                             </div>
                         </div>
                     </div>
                 )}

                  {(selected_bussines_category == "PE" || selected_bussines_category == "PL" || selected_bussines_category == "PI") && bussines_list_data && bussines_list_data.length > 0 && (
                     <div className="border-bottom pb-4">
                         <div className="row">
                             <div className="col-md-12 mt-4">
                                 <h5>{distributor_name}</h5>
                                 <button type="button" className="excel_download btn btn-primary border-button">
                                  <ExcelFile element={<><i className="uil uil-download-alt"></i> Shkarko ne Excel</>}>
                                        <ExcelSheet data={bussines_list_data} name="Lista e profileve">
                                            <ExcelColumn label="Emër" value="name"/>
                                            <ExcelColumn label="Mbiemër" value="lastName"/>
                                            <ExcelColumn label="Gjinia" value="gender"/>
                                            <ExcelColumn label="Datelindja" value="birthday"/>
                                            <ExcelColumn label="Vendlindja" value="birthplace"/>
                                            <ExcelColumn label="Emeri i babait" value="fatherName"/>
                                            <ExcelColumn label="Nacionaliteti" value="nationality"/>
                                            <ExcelColumn label="Adressa" value="resident_address"/>
                                            <ExcelColumn label="Nacionaliteti i dyte" value="secondNationality"/>
                                            <ExcelColumn label="Origjina" value="origin"/>
                                            <ExcelColumn label="Arsimimi" value="education"/>
                                            <ExcelColumn label="Fusha e studimit" value="educationField"/>
                                            <ExcelColumn label="Email" value="email"/>
                                            <ExcelColumn label="Telefoni" value="telefono"/>
                                            <ExcelColumn label="Facebook" value="facebookProfile"/>
                                            <ExcelColumn label="Twitter" value="twitterProfile"/>
                                            <ExcelColumn label="Linkedin" value="linkedinProfile"/>
                                        </ExcelSheet>
                                    </ExcelFile>
                                  </button>
                                 <div className="table-responsive bg-white shadow rounded">
                                     <table className="table mb-0 table-center invoice-tb">
                                             <thead className="bg-light">
                                             <tr>
                                                 
                                                 <th scope="col" className="border-bottom text-start">Emër</th>
                                                 <th scope="col" className="border-bottom text-start">Mbiemër</th>
                                                 <th scope="col" className="border-bottom text-start">Gjinia</th>
                                                 <th scope="col" className="border-bottom text-start">Datelindja</th>
                                                 <th scope="col" className="border-bottom text-start">Vendlindja</th>
                                                 <th scope="col" className="border-bottom text-start">Emeri i babait</th>
                                                 <th scope="col" className="border-bottom text-start">Nacionaliteti</th>
                                                 <th scope="col" className="border-bottom text-start">Adressa</th>
                                                 <th scope="col" className="border-bottom text-start">Nacionaliteti i dyte</th>
                                                 <th scope="col" className="border-bottom text-start">Origjina</th>
                                                 <th scope="col" className="border-bottom text-start">Arsimimi</th>
                                                 <th scope="col" className="border-bottom text-start">Fusha e studimit</th>
                                                 <th scope="col" className="border-bottom text-start">Email</th>
                                                 <th scope="col" className="border-bottom text-start">Telefoni</th>
                                                 <th scope="col" className="border-bottom text-start">Facebook</th>
                                                 <th scope="col" className="border-bottom text-start">Twitter</th>
                                                 <th scope="col" className="border-bottom text-start">Linkedin</th>
                                                 {/*<th scope="col" className="border-bottom text-start">Logo e biznesit</th>*/}
                                                 {/*<th scope="col" className="border-bottom text-start">Foto e produktit ose sherbimit</th>*/}

                                                 {/* <th scope="col" className="border-bottom text-center">Azioni</th> */}
                                             </tr>
                                             </thead>
                                             <tbody>
                                             {bussines_list_data.map(
                                                 (item, key) => {
                                                     return (
                                                         <tr key={key}>
                                                             <td scope="row" className="text-start">{capitalize(item.name)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.lastName)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.gender)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.birthday)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.birthplace)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.fatherName)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.nationality)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.resident_address)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.secondNationality)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.origin)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.education)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.educationField)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.email)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.telefono)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.facebookProfile)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.twitterProfile)}</td>
                                                             <td scope="row" className="text-start">{capitalize(item.linkedinProfile)}</td>
                                                             {/*<td scope="row" className="text-start">{capitalize(item.people_connected_in_society)}</td>*/}
                                                             {/*<td scope="row" className="text-start">{capitalize(item.bussines_img)}</td>*/}
                                                             {/*<td scope="row" className="text-start">{capitalize(item.product_img)}</td>*/}
                                                             {/*<td className="text-center"><i onClick={() => this.getClientDetailForBasf(client)} className="uil uil-eye user_profile_text_green cursor_pointer"/></td>*/}
                                                         </tr>
                                                     )
                                                 }
                                             )}
                                             </tbody>
                                         </table>
                                 </div>
                             </div>
                         </div>
                     </div>
                 )}
                </div>
              </div>
            </section>
          </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.user,
        basf: state.basf,
        auth: state.auth,
        media: state.media,
    }
}


const mapDispatchToProps = {
  getCurrentUser,
  getDistributorsCompetenceAreas,
  getClientsForBasfByDistributor,
  getBasfDistributors,
  updateProfileById,
  setBasfSelectValue,
  getClientDetailsForBasfPreview,
  clearClientDetails,
  clearClients,
  clearDistributors,
  getAllBenchmarks,
  setBenchmarkFilterValue,
  getDistributorName,
  getPhotosAndVideosUpdates,
  uploadNewUpdate,
  uploadMedia,
  clearBussinesList,
  getBusinessList,
}


export default connect(mapStateToProps, mapDispatchToProps)(BasfSalesProfile)