const excluded_codes = {
    "IT-BG": "#827F82",
    "IT-BI": "#827F82",
    "IT-BL":"#827F82",
    "IT-BS":"#827F82",
    "IT-BZ":"#827F82",
    "IT-RC":"#827F82",
    "IT-RI":"#827F82",
    "IT-RM":"#827F82",
    "IT-AO":"#827F82",
    "IT-IS":"#827F82",
    "IT-IM":"#827F82",
    "IT-AQ":"#827F82",
    "IT-LC":"#827F82",
    "IT-TS":"#827F82",
    "IT-TR":"#827F82",
    "IT-LO":"#827F82",
    "IT-LU":"#827F82",
    "IT-LT":"#827F82",
    "IT-TN":"#827F82",
    "IT-TE":"#827F82",
    "IT-CO":"#827F82",
    "IT-CH":"#827F82",
    "IT-CI":"#827F82",
    "IT-CE":"#827F82",
    "IT-CA":"#827F82",
    "IT-CZ":"#827F82",
    "IT-CR":"#827F82",
    "IT-CS":"#827F82",
    "IT-SV":"#827F82",
    "IT-SP":"#827F82",
    "IT-SS":"#827F82",
    "IT-SO":"#827F82",
    "IT-KR":"#827F82",
    "IT-FR":"#827F82",
    "IT-NO":"#827F82",
    "IT-NA":"#827F82",
    "IT-NU":"#827F82",
    "IT-MB":"#827F82",
    "IT-MS":"#827F82",
    "IT-UD":"#827F82",
    "IT-PT":"#827F82",
    "IT-PE":"#827F82",
    "IT-PO":"#827F82",
    "IT-PI":"#827F82",
    "IT-GR":"#827F82",
    "IT-VC":"#827F82",
    "IT-VB":"#827F82",
    "IT-VA":"#827F82",
    "IT-VV":"#827F82",
    "IT-VT":"#827F82",
    "IT-VS":"#827F82",
    "IT-GE":"#827F82",
    "IT-GO":"#827F82",
    "IT-OG":"#827F82",
    "IT-OT":"#827F82",
    "IT-OR":"#827F82"
}

export default excluded_codes;