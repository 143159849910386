import React, {Component, Fragment} from "react";


class Condizioni extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <div id='condizioni'>
                    <p className='text-center text-bold mt-3'>Condizioni d’uso [Regolamento]<a href={process.env.REACT_APP_URL} className='text_green'> www.revyplus.it</a></p>
                    <p className='text-bold mt-3'>Obiettivo del Programma Revy+</p>
                    <p>Con il Programma Revy+ BASF si impegna a dimostrare l’efficacia della propria linea di difesa cereali, coinvolgendo gli agricoltori in un percorso condiviso dalla semina alla raccolta.</p>
                    <p>Nello specifico attraverso l’utilizzo di prodotti BASF per la concia delle sementi e dell’applicazione del prodotto Revycare®
                        si prevede di un aumento ottenere di produzione media per ettaro trattato superiore del 3% rispetto ai risultati conseguibili
                        mediante l’impiego di una strategia di difesa standard.
                    </p>
                    <p className='text-bold mt-5'>Modalità di svolgimento del Programma Revy+</p>
                    <p>Il programma si basa sulla conduzione, da parte di centri di saggio esterni a BASF,
                        di n.6 campi benchmark dislocati nei principali areali produttivi italiani.
                        I campi benchmark avranno la funzione di dimostrare l’efficacia dei prodotti BASF,
                        e nello specifico di Revycare®, rispetto alle rese di una linea di difesa standard,
                        applicate sulle principali varietà di frumento (duro e tenero) ed orzo coltivate nella zona.
                    </p>
                    <p>I centri di saggio partecipanti all’iniziativa si occuperanno della conduzione dei campi,
                        dalla semina alla raccolta delle produzioni, e della conseguente verifica
                        dei quantitativi ottenuti attraverso le contrapposte linee di difesa.
                    </p>
                    <p>
                        Se al termine del Programma Revy+ le produzioni dei campi benchmark condotti con la strategia di difesa BASF
                        non supereranno del 3% quelli condotti con la strategia di difesa standard, verrà riconosciuto agli agricoltori
                        partecipanti all’iniziativa un bonus pari al valore di 1 lt di prodotto Revycare®
                        per ogni superficie pari a 5 ettari registrata a Programma.
                    </p>
                    <p className='text-bold mt-5'>Adesione e partecipazione aziende agricole</p>
                    <p>Per poter partecipare al Programma Revy+, l’azienda agricola dovrà registrare una superficie,
                        coltivata a frumento (duro o tenero) o orzo, minima di 5 ettari ed aver acquistato il prodotto Revycare®
                        sufficiente per trattare la superficie al dosaggio medio di 1 litro.
                    </p>
                    <p>
                        Al momento dell’acquisto del prodotto Revycare®,
                        presso il distributore di fiducia aderente al programma,
                        l’agricoltore riceverà un codice, avente la funzione di attestare
                        l’avvenuto acquisto del prodotto, e che servirà all’agricoltore al
                        momento della registrazione al portale dedicato al programma.
                    </p>
                    <p>
                        Attraverso il portale Revy+, le aziende agricole potranno avere visione dei
                        contenuti in esso condivisi da BASF, riguardanti l’andamento della coltivazione
                        dei campi benchmark, della successiva raccolta del prodotto e dei conseguenti risultati ottenuti.
                    </p>
                    <p>
                        Al momento della registrazione, compilando le voci dati
                        richieste ed inserendo il codice di acquisto, l’azienda
                        agricola potrà essere indirizzata ai contenuti del campo
                        benchmark al quale fare riferimento per tutta la durata del Programma Revy+.
                    </p>

                    <p className='text-bold mt-5'>Il codice di acquisto</p>
                    <p>Il codice di acquisto, fornito dal distributore all’azienda agricola,
                        costituisce una prova di acquisto necessaria alla procedura di registrazione al portale Programma Revy+.
                    </p>
                    <p>
                        Il codice di acquisto è composto da una sequenza alfanumerica,
                        che collega l’azienda agricola al distributore e permette a quest’ultimo
                        di avere visione delle informazioni e dei dati riguardanti l’azienda agricola.
                    </p>
                    <p>
                        Sarà possibile ed a discrezione del cliente distributore
                        effettuare un monitoraggio del flusso di adesioni ed eventualmente
                        effettuare segnalazioni in merito. Tale monitoraggio risulta importante
                        per garantire il rispetto dello scopo e del corretto svolgimento del Programma Revy+
                        e dell’eventuale erogazione del bonus previsto.
                    </p>
                    <p className='text-bold mt-5'>Tempistiche e durata del Programma Revy+</p>
                    <p>Sarà possibile aderire al Programma Revy+ da parte delle aziende agricole,
                        mediante la registrazione a portale, dal 01/01/2022 al 30/03/22.
                    </p>
                    <p>
                        La campagna di coltivazione dei campi benchmark, si concluderà entro il 30/06/2022.
                        Il conseguente controllo delle produzioni raggiunte e la comunicazione dei risultati avverrà entro il 30/09/2022.
                    </p>
                    <p>
                        L’eventuale erogazione del bonus conseguente al mancato raggiungimento dell’obiettivo del Programma da parte della linea di difesa BASF, avverrà entro il 30/11/22.
                    </p>

                </div>
            </Fragment>
        )
    }
}

export default Condizioni;