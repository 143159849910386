import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect, withRouter} from 'react-router-dom';
import {confirmNewPassword} from "../store/auth/actions";

import '../assets/css/custom.css';

class ConfirmNewPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirm_password: '',
            error: false
        }
    }

    confirmNewPassword = (e) => {
        e.preventDefault();
        const {confirmNewPassword} = this.props;
        const {password} = this.state;
        const data = {
            token: this.props.match.params.token,
            password: password
        }
        if (this.validateForm()) {
            confirmNewPassword(data);
        }
    }


    validateForm = () => {
        const {password, confirm_password} = this.state;
        let form_is_valid = true;
        let error = false;
        if (!password.trim() || !confirm_password.trim() || (password !== confirm_password)) {
            form_is_valid = false;
            error = true;
        }
        this.setState((prevState) => ({
            ...prevState,
            error: error
        }));

        return form_is_valid;
    }

    render() {
        const {error} = this.state;
        const redirect_to_login = this.props.auth.redirect_to_login;

        if (redirect_to_login) {
            return <Redirect to='/login'/>
        }

        return (
            <React.Fragment>
                <section className="bg-auth-home">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-md-7 m-auto">
                                <div className="card shadow rounded border-0">
                                    <div className="card-body">
                                        <h4 className="card-title text-left text_green">Fjalëkalim i ri</h4>
                                        <p className="border-bottom pb-2"/>
                                        <form className="login-form mt-4">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="mb-3">
                                                        <label className={error ? "form-label label_error" : "form-label"}>Fjalëkalim i ri<span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                            <i data-feather="key" className="uil uil-key-skeleton fea icon-sm custom_icon"/>
                                                            <input type="password"
                                                                   className={error ? "form-control ps-5 input_error" : "form-control ps-5"}
                                                                   onChange={(event) => {
                                                                       const value = event.target.value
                                                                       this.setState((prevState) => ({
                                                                           ...prevState,
                                                                           password: value,
                                                                           error: false
                                                                       }))
                                                                   }}
                                                                   placeholder="Fjalëkalimi" name="password" required=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="mb-3">
                                                        <label className={error ? "form-label label_error" : "form-label"}>Konfirmo fjalëkalimin e ri<span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                            <i data-feather="key" className="uil uil-key-skeleton fea icon-sm custom_icon"/>
                                                            <input type="password"
                                                                   className={error ? "form-control ps-5 input_error" : "form-control ps-5"}
                                                                   onChange={(event) => {
                                                                       const value = event.target.value
                                                                       this.setState((prevState) => ({
                                                                           ...prevState,
                                                                           confirm_password: value,
                                                                           error: false
                                                                       }))
                                                                   }}
                                                                   placeholder="Konfirmo fjalëkalimin e ri" name="password"
                                                                   required=""/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 mb-0">
                                                    <div className="d-grid">
                                                        <button className="btn btn-primary" onClick={(e) => this.confirmNewPassword(e)}>Dërgo</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}


const mapDispatchToProps = {
    confirmNewPassword
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmNewPassword));
