export const GET_DISTRIBUTORS_COMPETENCE_AREAS = 'GET_DISTRIBUTORS_COMPETENCE_AREAS';
export const GET_BASF_DISTRIBUTORS = 'GET_BASF_DISTRIBUTORS';
export const GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR = 'GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR';
export const SET_BASF_SELECT_VALUE = 'SET_BASF_SELECT_VALUE';
export const GET_CLIENT_DETAILS_FOR_BASF_PREVIEW = 'GET_CLIENT_DETAILS_FOR_BASF_PREVIEW';
export const CLEAR_CLIENT_DETAILS = 'CLEAR_CLIENT_DETAILS';
export const CLEAR_DISTRIBUTORS = 'CLEAR_DISTRIBUTORS';
export const CLEAR_CLIENTS = 'CLEAR_CLIENTS';
export const UPLOAD_NEW_UPDATE = 'UPLOAD_NEW_UPDATE';
export const SET_NEW_UPDATE_VALUE = 'SET_NEW_UPDATE_VALUE';
export const UPLOAD_MEDIA = 'UPLOAD_MEDIA';
export const GET_DISTRIBUTOR_NAME = 'GET_DISTRIBUTOR_NAME';
export const CLEAR_FORM = 'CLEAR_FORM';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_BASF_BUSSINESS_LIST = 'GET_BASF_BUSSINESS_LIST';
export const CLEAR_BUSSINES_LIST = 'CLEAR_BUSSINES_LIST';
