import {
    GET_PHOTOS_AND_VIDEOS_UPDATES,
    GET_ALL_BENCHMARKS,
    SET_BENCHMARK_FILTER_VALUE
} from "./actionTypes";


const initialState = {
    content: {
        loading: false,
        data: []
    },
    benchmarks: {
        list: {
            data: [],
            loading: false
        }
    },
    filter: {
        benchmark: ''
    }
};

export default (state = initialState, action) => {
    let content;
    let benchmarks;
    let filter;
    switch (action.type) {
        case GET_PHOTOS_AND_VIDEOS_UPDATES:
            content = Object.assign({}, state.content);
            content.loading = true;
            state = {
                ...state,
                content: content
            }
            break;
        case GET_PHOTOS_AND_VIDEOS_UPDATES.concat("_SUCCESS"):
            content = Object.assign({}, state.content);
            content.loading = false;
            content.data = action.payload;
            state = {
                ...state,
                content: content
            }
            break;
        case GET_PHOTOS_AND_VIDEOS_UPDATES.concat("_FAILURE"):
            content = Object.assign({}, state.content);
            content.loading = false;
            state = {
                ...state,
                content: content
            }
            break;
        case GET_ALL_BENCHMARKS:
            benchmarks = Object.assign({}, state.benchmarks);
            benchmarks.list.loading = true;
            state = {
                ...state,
                benchmarks: benchmarks
            }
            break;
        case GET_ALL_BENCHMARKS.concat("_SUCCESS"):
            benchmarks = Object.assign({}, state.benchmarks);
            benchmarks.list.loading = false;
            benchmarks.list.data = action.payload;
            state = {
                ...state,
                benchmarks: benchmarks
            }
            break;
        case SET_BENCHMARK_FILTER_VALUE:
            filter = Object.assign({}, state.filter);
            filter[action.payload.field] = action.payload.value;
            state = {
                ...state,
                filter: filter
            }
            break;
        default:
            state = {...state};
            break;
    }
    return state;
};