import React, {Component} from 'react'
import {Icon} from '@iconify/react'
import SweetAlert from "react-bootstrap-sweetalert";

import {connect} from 'react-redux'
import {
    setRegistrationValue,
    registerNewUser,
    setErrorMapTrue,
    setErrorMapFalse,
    setErrorCodeFalse,
    //uploadMedia,
    setShareholderValue,
    getProfileCategories,
    //uploadNewUpdate,
} from '../store/user/actions'

import Map from '../components/vector_map/vector_map'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import SimpleBar from 'simplebar-react'
import {sorting} from '../assets/helpers/helpers'
import DotLoader from "react-spinners/DotLoader";

import 'simplebar/dist/simplebar.min.css'
import '../assets/css/materialdesignicons.min.css'
import '../assets/css/custom.css'

import {
    district_list,
    origin_list,
    states_list,
    area_list,
    legal_status_list,
    education_list,
    property_list,
} from '../utils/constants.js'


import {Button, Col} from "reactstrap";

import {capitalize, clearApiBaseUrl, formatUpdateCreationDate} from "../assets/helpers/helpers";

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';

import { toast } from "react-toastify";


class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      registration_data: props.user.registration_data,
      errors: {
        bussines_logo_error_show: false,
        product_image_error_show: false,
      },
      bussines_logo_error_show: false,
      product_image_error_show: false,
      bussines_product_img_error_show: false,
      shareholders_errors: {},
      new_update: props.user.new_update,
      year: new Date().getFullYear(),
      showAlertProfilBiznesi: false,
      shareholders_alert_form_data: {},
      default_district_value: 'Zgjidh qarkun ...',
      default_country_value: 'Zgjidh shtetin ...',
      default_origin_value: 'Zgjidh origjinën ...',
      default_legal_status_value: 'Zhgjidh statusin ligjor ...',
      default_object_value: 'Zhgjidh objektin ...',
      default_education_value: 'Zhgjidh arsimin ...',

      max_file_upload_limit: 1,
      max_width_limt: 300,
      max_width_height: 300,
      bussines_img_width: null,
      bussines_img_height: null,

      otherLegalStatusValue: '',
      otherAreaValue: '',
      otherPropertyValue: '',

      psv_other_legal_status_value: '',
      psv_other_object_value: '',

      password_dont_match: false,
      password_dont_match_msg:'Ju lutem sigurohuni që paswordet të jenë të njëjtë!',
      selectedBirthday: '',
      selected_shareholder_birthday: '',
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const { setErrorMapFalse, user } = this.props
    if (user.error_map) {
      setErrorMapFalse()
      getProfileCategories()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.user.redirect_to_home !== this.props.user.redirect_to_home) {
      this.props.history.push('/')
    }
  }

  handleFormValidation = () => {
    const {
      errors,
      shareholders_errors,
      bussines_logo_error_show,
      product_image_error_show,
    } = this.state
    const form_data = this.props.user.registration_data
    const { setErrorMapTrue } = this.props
    let form_is_valid = true
    let mail_regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let phone_regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g

    if (!form_data?.bussines_category?.trim()) {
      errors.bussines_category = true
      form_is_valid = false
    }



    if (form_data.bussines_category === 'PB') {
      if (!form_data?.fiscal_code?.trim()) {
        errors.fiscal_code = true
        form_is_valid = false
      }

      if (!form_data?.name?.trim()) {
        errors.name = true
        form_is_valid = false
      }

      if (!form_data?.bussines_name?.trim()) {
        errors.bussines_name = true
        form_is_valid = false
      }

      // && !bussines_logo_error_show
      // if (!bussines_logo_error_show) {
      //     console.log("IMAGE OK")
      //     errors.file = true
      //     form_is_valid = false
      // }else{
      //     console.log("IMAGE KO")
      // }

      // if (!form_data?.productImage?.trim()) {
      //     errors.productImage = true
      //     form_is_valid = false
      // }

      if (!form_data?.legal_status?.trim()) {
        errors.legal_status = true
        form_is_valid = false
      }

      if (!form_data?.area?.trim()) {
        errors.area = true
        form_is_valid = false
      }

      if (!form_data?.year_of_establishment?.trim()) {
        errors.year_of_establishment = true
        form_is_valid = false
      }

      if (!form_data?.property?.trim()) {
        errors.property = true
        form_is_valid = false
      }

      if (!form_data?.email?.trim() || !mail_regex.test(form_data.email)) {
        errors.email = true
        form_is_valid = false
      }

      if (!form_data?.password?.trim()) {
        errors.password = true
        form_is_valid = false
      }

      if (!form_data?.confirm_password?.trim()) {
        errors.confirm_password = true
        form_is_valid = false
      }

      if (form_data.password !== form_data.confirm_password) {
        const self = this
        errors.password = true
        errors.confirm_password = true
        form_is_valid = false
        self.setState({
          password_dont_match: true,
        })
      }

      // if (!form_data?.telefono?.trim() || !phone_regex?.test(form_data?.telefono)) {
      //     errors.telefono = true
      //     form_is_valid = false
      // }

      if (!form_data?.address_street?.trim()) {
        errors.address_street = true
        form_is_valid = false
      }

      if (!form_data?.address_postal_code?.trim()) {
        errors.address_postal_code = true
        form_is_valid = false
      }

      if (!form_data?.address_qyteti?.trim()) {
        errors.address_qyteti = true
        form_is_valid = false
      }

      // if (!form_data?.district?.trim()) {
      //     errors.district = true
      //     form_is_valid = false
      // }

      if (!form_data?.country?.trim()) {
        errors.country = true
        form_is_valid = false
      }
    }



    if (form_data.bussines_category === 'PSV') {
      if (!form_data?.fiscal_code?.trim()) {
        errors.fiscal_code = true
        form_is_valid = false
      }

      if (!form_data?.name?.trim()) {
        errors.name = true
        form_is_valid = false
      }

      if (!form_data?.bussines_name?.trim()) {
        errors.bussines_name = true
        form_is_valid = false
      }

      if (!form_data?.legal_status?.trim()) {
        errors.legal_status = true
        form_is_valid = false
      }

      if (!form_data?.area?.trim()) {
        errors.area = true
        form_is_valid = false
      }

      if (!form_data?.year_of_establishment?.trim()) {
        errors.year_of_establishment = true
        form_is_valid = false
      }

      if (!form_data?.email?.trim() || !mail_regex.test(form_data.email)) {
        errors.email = true
        form_is_valid = false
      }

      if (!form_data?.password?.trim()) {
        errors.password = true
        form_is_valid = false
      }

      if (!form_data?.confirm_password?.trim()) {
        errors.confirm_password = true
        form_is_valid = false
      }

      if (form_data.password !== form_data.confirm_password) {
        const self = this
        errors.password = true
        errors.confirm_password = true
        form_is_valid = false
        self.setState({
          password_dont_match: true,
        })
      }

      // if (!form_data?.telefono?.trim() || !phone_regex?.test(form_data?.telefono)) {
      //     errors.telefono = true
      //     form_is_valid = false
      // }

      // if (!form_data?.address?.trim()) {
      //     errors.address = true
      //     form_is_valid = false
      // }

      if (!form_data?.address_street?.trim()) {
        errors.address_street = true
        form_is_valid = false
      }

      if (!form_data?.address_postal_code?.trim()) {
        errors.address_postal_code = true
        form_is_valid = false
      }

      if (!form_data?.address_qyteti?.trim()) {
        errors.address_qyteti = true
        form_is_valid = false
      }

      // if (!form_data?.district?.trim()) {
      //     errors.district = true
      //     form_is_valid = false
      // }

      if (!form_data?.country?.trim()) {
        errors.country = true
        form_is_valid = false
      }
    }



    if (form_data.bussines_category === 'PL') {

      if (!form_data?.name?.trim()) {
        errors.name = true
        form_is_valid = false
      }

      if (!form_data?.lastName?.trim()) {
        errors.lastName = true
        form_is_valid = false
      }

      if (!form_data?.gender?.trim()) {
        errors.gender = true
        form_is_valid = false
      }

      // if (!form_data?.birthday?.trim()) {
      //   errors.birthday = true
      //   form_is_valid = false
      // }

      if (!form_data?.birthplace?.trim()) {
        errors.birthplace = true
        form_is_valid = false
      }

      if (!form_data?.fatherName?.trim()) {
        errors.fatherName = true
        form_is_valid = false
      }

      if (!form_data?.nationality?.trim()) {
        errors.nationality = true
        form_is_valid = false
      }

      // if (!form_data?.resident_address?.trim()) {
      //   errors.resident_address = true
      //   form_is_valid = false
      // }


      if (!form_data?.address_street?.trim()) {
        errors.address_street = true
        form_is_valid = false
      }

      if (!form_data?.address_postal_code?.trim()) {
        errors.address_postal_code = true
        form_is_valid = false
      }

      if (!form_data?.address_qyteti?.trim()) {
        errors.address_qyteti = true
        form_is_valid = false
      }

      // if (!form_data?.secondNationality?.trim()) {
      //     errors.secondNationality = true
      //     form_is_valid = false
      // }

      if (!form_data?.origin?.trim()) {
        errors.origin = true
        form_is_valid = false
      }

      if (!form_data?.education?.trim()) {
        errors.education = true
        form_is_valid = false
      }

      if (!form_data?.educationField?.trim()) {
        errors.educationField = true
        form_is_valid = false
      }

      if (!form_data?.email?.trim()) {
        errors.email = true
        form_is_valid = false
      }

      if (!form_data?.password?.trim()) {
        errors.password = true
        form_is_valid = false
      }

      if (!form_data?.confirm_password?.trim()) {
        errors.confirm_password = true
        form_is_valid = false
      }

      if (form_data.password !== form_data.confirm_password) {
        const self = this
        errors.password = true
        errors.confirm_password = true
        form_is_valid = false
        self.setState({
          password_dont_match: true,
        })
      }

      // if (!form_data?.telefono?.trim()) {
      //   errors.telefono = true
      //   form_is_valid = false
      // }

      // if (!form_data?.facebookProfile?.trim()) {
      //     errors.facebookProfile = true
      //     form_is_valid = false
      // }

      // if (!form_data?.twiterProfile?.trim()) {
      //     errors.twiterProfile = true
      //     form_is_valid = false
      // }

      // if (!form_data?.linkedinProfile?.trim()) {
      //     errors.linkedinProfile = true
      //     form_is_valid = false
      // }

      // if (!form_data?.profilePicture?.trim()) {
      //     errors.profilePicture = true
      //     form_is_valid = false
      // }
      //
      // if (!form_data?.cv?.trim()) {
      //     errors.cv = true
      //     form_is_valid = false
      // }
    }



    if (form_data.bussines_category === 'PE' || form_data.bussines_category === 'PI') {

      if (!form_data?.name?.trim()) {
        errors.name = true
        form_is_valid = false
      }

      if (!form_data?.lastName?.trim()) {
        errors.lastName = true
        form_is_valid = false
      }

      if (!form_data?.gender?.trim()) {
        errors.gender = true
        form_is_valid = false
      }

      // if (!form_data?.birthday?.trim()) {
      //   errors.birthday = true
      //   form_is_valid = false
      // }

      if (!form_data?.birthplace?.trim()) {
        errors.birthplace = true
        form_is_valid = false
      }

      if (!form_data?.fatherName?.trim()) {
        errors.fatherName = true
        form_is_valid = false
      }

      if (!form_data?.nationality?.trim()) {
        errors.nationality = true
        form_is_valid = false
      }

      // if (!form_data?.resident_address?.trim()) {
      //   errors.resident_address = true
      //   form_is_valid = false
      // }

      if (!form_data?.address_street?.trim()) {
        errors.address_street = true
        form_is_valid = false
      }

      if (!form_data?.address_postal_code?.trim()) {
        errors.address_postal_code = true
        form_is_valid = false
      }

      if (!form_data?.address_qyteti?.trim()) {
        errors.address_qyteti = true
        form_is_valid = false
      }

      // if (!form_data?.secondNationality?.trim()) {
      //     errors.secondNationality = true
      //     form_is_valid = false
      // }

      if (!form_data?.origin?.trim()) {
        errors.origin = true
        form_is_valid = false
      }

      if (!form_data?.education?.trim()) {
        errors.education = true
        form_is_valid = false
      }

      if (!form_data?.educationField?.trim()) {
        errors.educationField = true
        form_is_valid = false
      }

      if (!form_data?.email?.trim()) {
        errors.email = true
        form_is_valid = false
      }

      if (!form_data?.password?.trim()) {
        errors.password = true
        form_is_valid = false
      }

      if (!form_data?.confirm_password?.trim()) {
        errors.confirm_password = true
        form_is_valid = false
      }

      if (form_data.password !== form_data.confirm_password) {
        const self = this
        errors.password = true
        errors.confirm_password = true
        form_is_valid = false
        self.setState({
          password_dont_match: true,
        })
      }

      // if (!form_data?.telefono?.trim()) {
      //   errors.telefono = true
      //   form_is_valid = false
      // }

      // if (!form_data?.facebookProfile?.trim()) {
      //     errors.facebookProfile = true
      //     form_is_valid = false
      // }

      // if (!form_data?.twiterProfile?.trim()) {
      //     errors.twiterProfile = true
      //     form_is_valid = false
      // }

      // if (!form_data?.linkedinProfile?.trim()) {
      //     errors.linkedinProfile = true
      //     form_is_valid = false
      // }

      // if (!form_data?.profilePicture?.trim()) {
      //     errors.profilePicture = true
      //     form_is_valid = false
      // }

      // if (!form_data?.caseStudy?.trim()) {
      //     errors.caseStudy = true
      //     form_is_valid = false
      // }

      // if (!form_data?.picture?.trim()) {
      //     errors.picture = true
      //     form_is_valid = false
      // }

      // if (!form_data?.cv?.trim()) {
      //     errors.cv = true
      //     form_is_valid = false
      // }
    }



    if (form_data.bussines_category === 'POJF') {

      if (!form_data?.fiscal_code?.trim()) {
        errors.fiscal_code = true
        form_is_valid = false
      }

      if (!form_data?.name?.trim()) {
        errors.name = true
        form_is_valid = false
      }

      if (!form_data?.bussines_name?.trim()) {
        errors.bussines_name = true
        form_is_valid = false
      }

      if (!form_data?.legal_status?.trim()) {
        errors.legal_status = true
        form_is_valid = false
      }

      if (!form_data?.area?.trim()) {
        errors.area = true
        form_is_valid = false
      }

      if (!form_data?.year_of_establishment?.trim()) {
        errors.year_of_establishment = true
        form_is_valid = false
      }

      if (!form_data?.property?.trim()) {
        errors.property = true
        form_is_valid = false
      }

      if (!form_data?.email?.trim() || !mail_regex.test(form_data.email)) {
        errors.email = true
        form_is_valid = false
      }

      if (!form_data?.password?.trim()) {
        errors.password = true
        form_is_valid = false
      }

      if (!form_data?.confirm_password?.trim()) {
        errors.confirm_password = true
        form_is_valid = false
      }

      if (form_data.password !== form_data.confirm_password) {
        const self = this
        errors.password = true
        errors.confirm_password = true
        form_is_valid = false
        self.setState({
          password_dont_match: true,
        })
      }

      // if (
      //   !form_data?.telefono?.trim() ||
      //   !phone_regex?.test(form_data?.telefono)
      // ) {
      //   errors.telefono = true
      //   form_is_valid = false
      // }

      // if (!form_data?.address?.trim()) {
      //   errors.address = true
      //   form_is_valid = false
      // }

      if (!form_data?.address_street?.trim()) {
        errors.address_street = true
        form_is_valid = false
      }

      if (!form_data?.address_postal_code?.trim()) {
        errors.address_postal_code = true
        form_is_valid = false
      }

      if (!form_data?.address_qyteti?.trim()) {
        errors.address_qyteti = true
        form_is_valid = false
      }

      // if (!form_data?.district?.trim()) {
      //     errors.district = true
      //     form_is_valid = false
      // }

      if (!form_data?.country?.trim()) {
        errors.country = true
        form_is_valid = false
      }
    }

    console.log('FORM_DATA_VALUES', form_data)
    console.log('FORM_DATA_VALUES_VALIDATION', errors)

    this.scrollToFirstError(errors)

    this.setState((prevState) => ({
      ...prevState,
      errors: errors,
    }))
    return form_is_valid
  }

  // convertImageToBase64 = (file, callback)  => {
  //     let reader = new FileReader();
  //     reader.readAsDataURL(file);

  //     reader.onload = function () {
  //         const image = new Image();
  //         image.crossOrigin='anonymous';
  //         image.onload = () => {
  //             callback(reader.result,image.naturalWidth,image.naturalHeight)
  //         }
  //         image.src = reader.result;
  //     };
  //     reader.onerror = function (error) {
  //         console.log('Error: ', error);
  //     };
  // }

  initUserRegistration = (event) => {
    const self = this
    event.preventDefault()
    const { registerNewUser, user } = self.props
    const registration_data = user.registration_data
    const data = this.props.user.new_update

    if (self.handleFormValidation()) {
      console.log('BUSSINES_ING', data)
      console.log('registration_data', registration_data)
      registerNewUser(registration_data)
    }
  }

  scrollToFirstError = (errors) => {
    const all_errors = Object.entries(errors).filter(
      (error) => error[1] === true
    )

    const sorted_errors = all_errors.sort(function (a, b) {
      return sorting.indexOf(a[0]) - sorting.indexOf(b[0])
    })

    if (sorted_errors.length) {
      const input = document.getElementById(sorted_errors[0][0])
      input.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      })
    }
  }

  scrollToModalFirstError = (errors) => {
    const all_errors = Object.entries(errors).filter(
      (error) => error[1] === true
    )

    const sorted_errors = all_errors.sort(function (a, b) {
      return sorting.indexOf(a[0]) - sorting.indexOf(b[0])
    })

    if (sorted_errors.length) {
      const input = document.getElementById(sorted_errors[0][0])
      input.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      })
    }
  }

  openModal() {
    const self = this
    self.setState({ showAlertProfilBiznesi: true })
    const { setShareholderValue } = this.props
  }

  closeModal() {
    const self = this
    self.setState({
      showAlertProfilBiznesi: false,
      shareholders_errors: {},
      shareholders_alert_form_data: {},
    })
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++++
  // SHTO SHAREHOLDER
  // ++++++++++++++++++++++++++++++++++++++++++++++++
  addShareholders() {
    const { shareholders_errors, shareholders_alert_form_data } = this.state
    const { setRegistrationValue } = this.props
    const shareholder_data = this.props.user.shareholder_data

    console.log('ERNEST', shareholders_alert_form_data)

    if (!shareholders_alert_form_data?.shareholder_type?.trim()) {
      this.setState((prevState) => ({
        ...prevState,
        shareholders_errors: {
          ...prevState.shareholders_errors,
          shareholder_type: true,
        },
      }))
    }

    if (!shareholders_alert_form_data?.firstName?.trim()) {
      this.setState((prevState) => ({
        ...prevState,
        shareholders_errors: {
          ...prevState.shareholders_errors,
          firstName: true,
        },
      }))
    }

    if (!shareholder_data?.lastName?.trim()) {
      this.setState((prevState) => ({
        ...prevState,
        shareholders_errors: {
          ...prevState.shareholders_errors,
          lastName: true,
        },
      }))
    }

    if (!shareholder_data?.gender?.trim()) {
      this.setState((prevState) => ({
        ...prevState,
        shareholders_errors: {
          ...prevState.shareholders_errors,
          gender: true,
        },
      }))
    }

    if (!shareholder_data?.birthday?.trim()) {
      this.setState((prevState) => ({
        ...prevState,
        shareholders_errors: {
          ...prevState.shareholders_errors,
          birthday: true,
        },
      }))
    }

    if (!shareholder_data?.birthplace?.trim()) {
      this.setState((prevState) => ({
        ...prevState,
        shareholders_errors: {
          ...prevState.shareholders_errors,
          birthplace: true,
        },
      }))
    }

    if (!shareholder_data?.fatherName?.trim()) {
      this.setState((prevState) => ({
        ...prevState,
        shareholders_errors: {
          ...prevState.shareholders_errors,
          fatherName: true,
        },
      }))
    }

    if (!shareholder_data?.nationality?.trim()) {
      this.setState((prevState) => ({
        ...prevState,
        shareholders_errors: {
          ...prevState.shareholders_errors,
          nationality: true,
        },
      }))
    }

    // if (!shareholder_data?.secondNationality?.trim()) {
    //     this.setState((prevState) => ({
    //         ...prevState,
    //         shareholders_errors: {
    //             ...prevState.shareholders_errors,
    //             secondNationality: true,
    //         },
    //     }))
    // }

    if (!shareholder_data?.resident_address?.trim()) {
      this.setState((prevState) => ({
        ...prevState,
        shareholders_errors: {
          ...prevState.shareholders_errors,
          resident_address: true,
        },
      }))
    }

    if (!shareholder_data?.origin?.trim()) {
      this.setState((prevState) => ({
        ...prevState,
        shareholders_errors: {
          ...prevState.shareholders_errors,
          origin: true,
        },
      }))
    }

    console.log(shareholders_errors, 'shareholders_errors')
    console.log(Object.entries(shareholders_errors).filter((item) => item === true).length > 0,'errors')
    console.log('EVL',Object.entries(shareholders_errors).filter((item) => item === true))
    console.log('TIRANE',Object.entries(shareholders_errors).filter((item) => item === true).length >= 0)

    if (
      !shareholders_alert_form_data.shareholder_type ||
      !shareholders_alert_form_data.firstName ||
      !shareholders_alert_form_data.lastName ||
      !shareholders_alert_form_data.gender ||
      !shareholders_alert_form_data.birthday ||
      !shareholders_alert_form_data.birthplace ||
      !shareholders_alert_form_data.fatherName ||
      !shareholders_alert_form_data.nationality ||
      !shareholders_alert_form_data.resident_address ||
      !shareholders_alert_form_data.origin
    ) {
      // if (Object.entries(shareholders_errors).filter((item) => item === true).length >= 0) {
      console.log('INVALID DATA')
      return false
    } else {
      this.setState((prevState) => {
        const currentShareholders = prevState.registration_data?.shareholders ?? []
        console.log('currentShareholders', currentShareholders)
        console.log('------------------------>', [...currentShareholders,shareholders_alert_form_data])

        if ([...currentShareholders, shareholder_data].length > 0) {
          console.log('eri', shareholders_alert_form_data)

          setRegistrationValue('shareholders', [...currentShareholders,shareholder_data,])
          console.log('final_data', this.props.user)

          return {
            ...prevState,
            registration_data: {
              ...prevState.registration_data,
              shareholders: [
                ...currentShareholders,
                shareholders_alert_form_data,
              ],
            },
            showAlertProfilBiznesi: false,
            shareholders_alert_form_data: {},
            shareholders_errors: {},
          }
        }
        return {
          ...prevState,
        }
      })
    }
  }

  editShareholder(shareholder) {
    console.log('editShareholder', shareholder)
  }

  deleteShareholder(shareholder) {
    console.log('deleteShareholder', shareholder)
  }

    // uploadMultipleFiles = (e) => {
    //     const self = this;
    //     const {uploadMedia, uploadNewUpdate} = self.props;
    //     console.log(Array.from(e.target.files).length, self.state.max_file_upload_limit)


    //     if (Array.from(e.target.files).length > self.state.max_file_upload_limit) {
    //         e.preventDefault();
    //         toast.error(`KUJDES! Nuk mund të ngarkoni më shumë se ${self.state.max_file_upload_limit} Foto`);
    //         return;
    //     }


    //     self.setState((prevState) => {
    //         const currentPhotos = prevState.registration_data?.uploaded_documents ?? []

    //         // console.log("UPLOADED_DOCUMENTS", [...currentPhotos,  ...Array.from(e.target.files)])

    //         const bussines_img = [...currentPhotos,  ...Array.from(e.target.files)];

    //         console.log("BUSSINES_LOGO", bussines_img)

    //         // if (img_file) {
    //         //     const reader = new FileReader();

    //         //     reader.onload = (e) => {
    //         //       const img = new Image();
    //         //       img.src = img_file;

    //         //       img.onload = () => {
    //         //         const width = img.width;
    //         //         const height = img.height;

    //         //         console.log('Width:', width);
    //         //         console.log('Height:', height);

    //         //         self.setState({
    //         //             bussines_img_width: width,
    //         //             bussines_img_height: height,
    //         //         });
    //         //       };
    //         //     };
    //         //     reader.readAsDataURL(selectedFile);
    //         //     setFile(selectedFile);
    //         // }


    //         let newData = Array.from(bussines_img);
    //         newData.file_reference = 'logo_img';
    //         uploadNewUpdate(newData)
    //     })
    // }

    // uploadProductImage = (e) => {
    //     const self = this;
    //     const {uploadNewUpdate} = self.props;

    //     // if (Array.from(e.target.files).length > self.state.max_file_upload_limit) {
    //     //     e.preventDefault();
    //     //     toast.error(`KUJDES! Nuk mund të ngarkoni më shumë se ${self.state.max_file_upload_limit} Foto`);
    //     //     return;
    //     // }

    //     self.setState((prevState) => {
    //         const currentPhotos = prevState.registration_data?.uploaded_documents ?? [];
    //         const bussines_img = [...currentPhotos,  ...Array.from(e.target.files)];
    //         console.log("PRODUKT_IMG", bussines_img);
    //         let newData = Array.from(bussines_img);
    //         newData.file_reference = 'product_or_service_img';
    //         uploadNewUpdate(newData);
    //     })
    // }

    // uploadProfileImage = (e) => {
    //     const self = this;
    //     const {uploadNewUpdate} = self.props;

    //     // if (Array.from(e.target.files).length > self.state.max_file_upload_limit) {
    //     //     e.preventDefault();
    //     //     toast.error(`KUJDES! Nuk mund të ngarkoni më shumë se ${self.state.max_file_upload_limit} Foto`);
    //     //     return;
    //     // }

    //     self.setState((prevState) => {
    //         const currentPhotos = prevState.registration_data?.uploaded_documents ?? [];
    //         const bussines_img = [...currentPhotos,  ...Array.from(e.target.files)];
    //         console.log("PROFILE_IMAGE", bussines_img);
    //         let newData = Array.from(bussines_img);
    //         newData.file_reference = 'profile_img';
    //         uploadNewUpdate(newData);
    //     })
    // }

  setAreaValue = () => {
    const self = this
    const { setRegistrationValue } = self.props
    console.log('otherAreaValue', self.state.otherAreaValue)
    setRegistrationValue('area', self.state.otherAreaValue)
  }

  setLegalStatusValue = () => {
    const self = this
    const { user, setRegistrationValue } = self.props

    setRegistrationValue('legal_status', self.state.otherLegalStatusValue)



    // const category = user.registration_data.bussines_category

    // console.log('ERNEST', category);

    // if (category === 'PB') {
    //   setRegistrationValue('legal_status', self.state.otherLegalStatusValue)
    // } else if (category === 'PSV') {
    //   console.log('OOOOOOOOOOOOOOOOOOOO')
    //   setRegistrationValue('legal_status',self.state.psv_other_legal_status_value)
    // }
  }


  setPropertyValue = () => {
    const self = this
    const { setRegistrationValue } = self.props
    setRegistrationValue('property', self.state.otherPropertyValue)
  }


  setBirthday = (date) => {
    const self = this;
    const { setRegistrationValue } = self.props

    if(date){
      console.log("Birthday", date);
      self.setState({selectedBirthday: date});
      const birthday_formated = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      setRegistrationValue('birthday', birthday_formated)
    }else{
      self.setState({selectedBirthday: ''});
    }

  }

    setShareholderBirthday = (date) => {
      const self = this;
      const { setShareholderValue } = self.props

    if(date){
      console.log("Sharehoder Birthday", date);
      self.setState({selected_shareholder_birthday: date});
      const birthday_formated = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      setShareholderValue('birthday', birthday_formated)
    }else{
      self.setState({selected_shareholder_birthday: ''});
    }
  }



    render() {
        //const {setShareholderValue, setRegistrationValue, setErrorCodeFalse, uploadMedia, years} = this.props
  //       const {setShareholderValue, setRegistrationValue, setErrorCodeFalse, years} = this.props
  //       const {
  //           errors,
  //           year,
  //           showAlertProfilBiznesi,
  //           shareholders_alert_form_data,
  //           shareholders_errors,
  //           default_district_value,
  //           default_origin_value,
  //           default_country_value,
  //           default_legal_status_value,
  //           default_object_value,
  //           default_education_value,
  //           max_file_upload_limit,
  //           max_width_limt,
  //           max_height_limt,
  //           bussines_img_width,
  //           bussines_img_height,
  //           otherAreaValue,
  //           otherLegalStatusValue,
  //           otherPropertyValue,
  //           password_dont_match,
  //           password_dont_match_msg

  //       } = this.state
  //       const error_map = this.props.user.error_map
  //       const code_error = this.props.code_error
  //       this.years = Array.from(new Array(60), (val, index) => year - index)
  //       const upload_loading = this.props.user.upload_loading;

  // render() {

    const {
      setShareholderValue,
      setRegistrationValue,
      setErrorCodeFalse,
      //uploadMedia,
      years
    } = this.props
    const {
      errors,
      year,
      showAlertProfilBiznesi,
      shareholders_alert_form_data,
      shareholders_errors,
      default_district_value,
      default_origin_value,
      default_country_value,
      default_legal_status_value,
      default_object_value,
      default_education_value,
      max_file_upload_limit,
      max_width_limt,
      max_height_limt,
      bussines_img_width,
      bussines_img_height,
      otherAreaValue,
      otherLegalStatusValue,
      otherPropertyValue,
      psv_other_legal_status_value,
      psv_other_object_value,
      password_dont_match,
      password_dont_match_msg,
      selectedBirthday,
      selected_shareholder_birthday,
    } = this.state
    const error_map = this.props.user.error_map;
    const code_error = this.props.code_error;
    this.years = Array.from(new Array(60), (val, index) => year - index);
    const upload_loading = this.props.user.upload_loading;

    const errorOnForm = Object.values(errors).every((value) => {
      if (value === true) {
        return true
      }

      return false
    })

    console.log('valid_form_data', errorOnForm)

    return (
      <React.Fragment>
        <section className="bg-auth-home">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 m-auto">
                <div className="card shadow rounded border-0">
                  <div className="card-body">
                    <h4
                      className="card-title text-center text-uppercase"
                      style={{ color: '#bf1e2e' }}
                    >
                      {' '}
                      Krijo Profil Biznesi{' '}
                    </h4>
                    <p className="border-bottom pb-2" />

                    <form className="login-form mt-4">
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <div className="mb-4">
                            <label
                              className={
                                errors.bussines_category
                                  ? 'form-label label_error'
                                  : 'form-label'
                              }
                            >
                              Kategoritë
                              <span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                              <Icon
                                icon="bx:bx-grid-alt"
                                width="17"
                                height="17"
                                className="custom_icon"
                              />
                              <select
                                className={
                                  errors.bussines_category
                                    ? 'form-select form-control ps-5 input_error'
                                    : 'form-select form-control ps-5'
                                }
                                placeholder="Zhgjidh profilin e biznesit"
                                onChange={(event) => {
                                  const value = event.target.value
                                  this.setState((prevState) => ({
                                    ...prevState,
                                    registration_data: {
                                      ...prevState.registration_data,
                                      bussines_category: value,
                                    },
                                    errors: {},
                                    // errors: {
                                    //     ...prevState.errors,
                                    //     bussines_category: false,
                                    // },
                                  }))
                                  setRegistrationValue(
                                    'bussines_category',
                                    value
                                  )
                                }}
                                id="bussines_category"
                                value={
                                  this.props.user.registration_data
                                    .bussines_category !== ''
                                    ? this.props.user.registration_data
                                        .bussines_category
                                    : undefined
                                }
                                aria-label="Default select example"
                                defaultValue={'DEFAULT'}
                              >
                                <option value="DEFAULT" disabled>
                                  Zhgjidh një nga kategoritë ...{' '}
                                </option>
                                <option value="PB">Profil Biznesi</option>
                                <option value="PSV">
                                  Profil i Sipërmarrësit të vetëpunësuar
                                </option>
                                <option value="PL">Profil Lidershipi</option>
                                <option value="PE">Profil Eksperti</option>
                                <option value="PI">Profil Individ</option>
                                <option value="POJF">
                                  Profil Organizate Jofitimprurese
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>

                        {/* PROFIL BIZNESI */}
                        {this.props.user.registration_data.bussines_category ==='PB' ? (
                          <section>
                            {/* Kodi Fiskal */}
                            <div className="mb-4">
                              <label
                                className={
                                  errors.fiscal_code
                                    ? 'form-label label_error'
                                    : 'form-label'
                                }
                              >
                                {' '}
                                Kodi fiskal{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="form-icon position-relative">
                                <Icon
                                  icon="bx:bx-building-house"
                                  width="17"
                                  height="17"
                                  className="custom_icon"
                                />
                                <input
                                  type="text"
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        fiscal_code: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        fiscal_code: false,
                                      },
                                    }))
                                    setRegistrationValue('fiscal_code', value)
                                  }}
                                  value={
                                    this.props.user.registration_data
                                      .fiscal_code
                                  }
                                  className={
                                    errors.fiscal_code
                                      ? 'form-control ps-5 input_error'
                                      : 'form-control ps-5'
                                  }
                                  placeholder="Kodi fiskal"
                                  id="fiscal_code"
                                  name="Kodi fiskal"
                                  required=""
                                />
                              </div>
                            </div>

                            {/* Emri */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.name
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Emër <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-building-house"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="text"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          name: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          name: false,
                                        },
                                      }))
                                      setRegistrationValue('name', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.name
                                    }
                                    className={
                                      errors.name
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Emër"
                                    id="name"
                                    name="Emër"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Emri Tregtare  PB */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.bussines_name
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  {' '}
                                  Emri Tregtar{' '}
                                  <span className="text-danger">*</span>{' '}
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-building-house"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="text"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          bussines_name: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          bussines_name: false,
                                        },
                                      }))
                                      setRegistrationValue(
                                        'bussines_name',
                                        value
                                      )
                                    }}
                                    value={
                                      this.props.user.registration_data
                                        .bussines_name
                                    }
                                    className={
                                      errors.bussines_name
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Emri Tregtar"
                                    id="bussines_name"
                                    name="Emri Tregtar"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Statusi Ligjor PB */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.legal_status
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Statusi Ligjor{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-grid-alt"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.legal_status
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh statusin ligjor"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          legal_status: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          legal_status: false,
                                        },
                                      }))
                                      setRegistrationValue('legal_status', value)
                                      this.setState({otherLegalStatusValue: ''})
                                    }}
                                    id="legal_status"
                                    // value={
                                    //   this.props.user.registration_data.legal_status !== ''
                                    //     ? this.props.user.registration_data.legal_status
                                    //     : undefined
                                    // }
                                    aria-label="Default select example"
                                    defaultValue={'DEFAULT_LEGAL_STATUS'}
                                  >
                                    <option
                                      value="DEFAULT_LEGAL_STATUS"
                                      disabled
                                    >
                                      {default_legal_status_value}
                                    </option>

                                    {legal_status_list.map(
                                      (district, index) => {
                                        return (
                                          <option
                                            key={`district${index}.id`}
                                            value={district.name}
                                          >
                                            {district.name}
                                          </option>
                                        )
                                      }
                                    )}
                                  </select>
                                </div>
                              </div>

                              {/* Tjeter Specifiko */}
                              {this.props.user.registration_data
                                .legal_status === 'Tjetër' && (
                                <SweetAlert
                                  title={''}
                                  onConfirm={this.setLegalStatusValue}
                                  type={'controlled'}
                                  dependencies={[otherLegalStatusValue]}
                                >
                                  {(renderProps: SweetAlertRenderProps) => (
                                    <form>
                                      <input
                                        type={'text'}
                                        ref={renderProps.setAutoFocusInputRef}
                                        className="form-control"
                                        value={otherLegalStatusValue}
                                        onKeyDown={
                                          renderProps.onEnterKeyDownConfirm
                                        }
                                        onChange={(e) =>
                                          this.setState({
                                            otherLegalStatusValue:
                                              e.target.value,
                                          })
                                        }
                                        placeholder={
                                          'Specifiko statusin ligjor'
                                        }
                                      />
                                    </form>
                                  )}
                                </SweetAlert>
                              )}

                              {otherLegalStatusValue.length > 0 && (
                                <div className="mt-4 mb-4">
                                  <label className={'form-label'}>
                                    Statusi ligjor tjetër
                                  </label>
                                  <input
                                    type="text"
                                    value={otherLegalStatusValue}
                                    placeholder=""
                                    id="other_legal_status"
                                    name="other_legal_status"
                                    className={'form-control'}
                                    required=""
                                  />
                                </div>
                              )}
                            </div>

                            {/* Objekti PB*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.area
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Objekti
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-grid-alt"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.area
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh objektin"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          area: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          area: false,
                                        },
                                      }))
                                      setRegistrationValue('area', value)
                                      this.setState({ otherAreaValue: '' })
                                    }}
                                    id="area"
                                    // value={
                                    //   this.props.user.registration_data.area !== ''
                                    //     ? this.props.user.registration_data.area
                                    //     : undefined
                                    // }
                                    aria-label="Default select example"
                                    defaultValue={'DEFAULT_OBJECT_VALUE'}
                                  >
                                    <option
                                      value="DEFAULT_OBJECT_VALUE"
                                      disabled
                                    >
                                      {' '}
                                      {default_legal_status_value}{' '}
                                    </option>

                                    {area_list.map((district, index) => {
                                      return (
                                        <option
                                          key={`district${index}.id`}
                                          value={district.name}
                                        >
                                          {district.name}
                                        </option>
                                      )
                                    })}
                                  </select>

                                  {/* Tjeter Specifiko */}
                                  {this.props.user.registration_data.area ===
                                    'Tjetër' && (
                                    <SweetAlert
                                      title={''}
                                      onConfirm={this.setAreaValue}
                                      type={'controlled'}
                                      dependencies={[this.state.otherAreaValue]}
                                    >
                                      {(renderProps: SweetAlertRenderProps) => (
                                        <form>
                                          <input
                                            type={'text'}
                                            ref={
                                              renderProps.setAutoFocusInputRef
                                            }
                                            className="form-control"
                                            value={this.state.otherAreaValue}
                                            onKeyDown={
                                              renderProps.onEnterKeyDownConfirm
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                otherAreaValue: e.target.value,
                                              })
                                            }
                                            placeholder={'Specifiko objektin'}
                                          />
                                        </form>
                                      )}
                                    </SweetAlert>
                                  )}

                                  {otherAreaValue.length > 0 && (
                                    <div className="mt-4 mb-4">
                                      <label className={'form-label'}>
                                        Objekti tjetër
                                      </label>
                                      <input
                                        type="text"
                                        value={otherAreaValue}
                                        className={'form-control'}
                                        placeholder="Specifiko objektin"
                                        id="other_district"
                                        name="other_district"
                                        required=""
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* Viti Themelimit  */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.year_of_establishment
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Viti i themelimit
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-grid-alt"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.year_of_establishment
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh vitin"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          year_of_establishment: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          year_of_establishment: false,
                                        },
                                      }))
                                      setRegistrationValue(
                                        'year_of_establishment',
                                        value
                                      )
                                    }}
                                    id="year_of_establishment"
                                    value={
                                      this.props.user.registration_data
                                        .year_of_establishment !== ''
                                        ? this.props.user.registration_data
                                            .year_of_establishment
                                        : undefined
                                    }
                                    aria-label="Default select example"
                                    defaultValue={
                                      'DEFAULT_YEAR_OF_ESTABLISHMENT'
                                    }
                                  >
                                    <option
                                      value="DEFAULT_YEAR_OF_ESTABLISHMENT"
                                      disabled
                                    >
                                      Zhgjidh vitin e themelimit ...
                                    </option>

                                    {this.years.map((year, index) => {
                                      return (
                                        <option
                                          key={`year${index}`}
                                          value={year}
                                        >
                                          {year}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>

                            {/* Pronësia  */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.property
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Pronësia
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-grid-alt"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.property
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh llojin e pronësisë"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          property: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          property: false,
                                        },
                                      }))
                                      setRegistrationValue('property', value)
                                    }}
                                    id="property"
                                    // value={
                                    //   this.props.user.registration_data
                                    //     .property !== ''
                                    //     ? this.props.user.registration_data
                                    //         .property
                                    //     : undefined
                                    // }
                                    aria-label="Default select example"
                                    defaultValue={'DEFAULT_PROPERTY_VALUE'}
                                  >
                                    <option
                                      value="DEFAULT_PROPERTY_VALUE"
                                      disabled
                                    >
                                      Zhgjidh llojin e pronësisë ...
                                    </option>

                                    {/* <option value="Shqiptare">
                                              Shqiptare
                                            </option>

                                            <option value="E perbashket">
                                              E perbashket
                                            </option>

                                            <option value="Kosova">Kosova</option>

                                            <option value="Maqedonia e Veriut">
                                              Maqedonia e Veriut
                                            </option>

                                            <option value="Lugina e Presheves">
                                              Lugina e Presheves
                                            </option>

                                            <option value="Mali i Zi">
                                              Mali i Zi
                                            </option>

                                            <option value="Arberesh">
                                              Arberesh
                                            </option>

                                            <option value="shto_dhe_cileso">
                                              Shto edhe Cilëso
                                            </option> */}

                                    {property_list.map((district, index) => {
                                      return (
                                        <option
                                          key={`district${index}.id`}
                                          value={district.name}
                                        >
                                          {district.name}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>

                              {/* Tjeter Specifiko */}
                              {this.props.user.registration_data.property ===
                                'Shto edhe Cilëso' && (
                                <SweetAlert
                                  title={''}
                                  onConfirm={this.setPropertyValue}
                                  type={'controlled'}
                                  dependencies={[this.state.otherPropertyValue]}
                                >
                                  {(renderProps: SweetAlertRenderProps) => (
                                    <form>
                                      <input
                                        type={'text'}
                                        ref={renderProps.setAutoFocusInputRef}
                                        className="form-control"
                                        value={this.state.otherPropertyValue}
                                        onKeyDown={
                                          renderProps.onEnterKeyDownConfirm
                                        }
                                        onChange={(e) =>
                                          this.setState({
                                            otherPropertyValue: e.target.value,
                                          })
                                        }
                                        placeholder={
                                          'Specifiko llojin e pronësise...'
                                        }
                                      />
                                    </form>
                                  )}
                                </SweetAlert>
                              )}

                              {otherPropertyValue.length > 0 && (
                                <div className="mt-4 mb-4">
                                  <label className={'form-label'}>
                                    Pronësi tjetër
                                  </label>
                                  <input
                                    type="text"
                                    value={otherPropertyValue}
                                    className={'form-control'}
                                    placeholder=""
                                    id="other_property"
                                    name="other_property"
                                    required=""
                                  />
                                </div>
                              )}
                            </div>

                            {/* Emaili  */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.email
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  E-mail
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <PermIdentityIcon className="fea icon-sm custom_icon" />
                                  <input
                                    type="email"
                                    autoComplete="off"
                                    className={
                                      errors.email
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          email: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          email: false,
                                        },
                                      }))
                                      setRegistrationValue('email', value)
                                    }}
                                    placeholder="Email"
                                    name="email"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/*Paswordi PB*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.password
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Password
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="feather:key"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="password"
                                    autoComplete="new-password"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          password: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          password: false,
                                        },
                                      }))
                                      setRegistrationValue('password', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.password
                                    }
                                    className={
                                      errors.password
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Password"
                                    id="password"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/*Konfirmo Paswordi PB*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.confirm_password
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Konfirmo Passwordin
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="feather:key"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="password"
                                    autoComplete="new-password"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          confirm_password: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          confirm_password: false,
                                        },
                                      }))
                                      setRegistrationValue(
                                        'confirm_password',
                                        value
                                      )
                                    }}
                                    value={
                                      this.props.user.registration_data
                                        .confirm_password
                                    }
                                    className={
                                      errors.confirm_password
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Konfirmo Passwordin"
                                    id="confirm_password"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {this.props.user.registration_data.password !== this.props.user.registration_data.confirm_password && (
                              <div className="mt-4 mb-4">
                                <label className={'form-label label_error'}>
                                  {password_dont_match_msg}
                                </label>
                              </div>
                            )}

                            {/* Telefoni PB */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.telefono
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Telefoni
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="ci:phone-outline"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="number"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          telefono: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          telefono: false,
                                        },
                                      }))
                                      setRegistrationValue('telefono', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.telefono
                                    }
                                    className={
                                      errors.telefono
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Telefoni"
                                    name="telefono"
                                    id="telefono"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Adresa e Biznesit PB */}
                            <div className="row">
                              <section>
                                <div className="mb-2">
                                  <label
                                    className={
                                      errors.address
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Adresa<span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="row">
                                  {/* Rruga */}
                                  <div className="col-md-4">
                                    <div className="mb-4">
                                      {/* <label className={errors.address ? 'form-label label_error' : 'form-label'}>
                                                  Rruga/Nr.Shpisë
                                                </label> */}

                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bxs-map"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <input
                                          type="text"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                address_street: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                address_street: false,
                                              },
                                            }))
                                            setRegistrationValue(
                                              'address_street',
                                              value
                                            )
                                          }}
                                          value={
                                            this.props.user.registration_data
                                              .address_street
                                          }
                                          className={
                                            errors.address_street
                                              ? 'form-control ps-5 input_error'
                                              : 'form-control ps-5'
                                          }
                                          placeholder="Rruga / numri banesës"
                                          id="address_street"
                                          name="address_street"
                                          required=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* Kodi Postar */}
                                  <div className="col-md-4">
                                    <div className="mb-4">
                                      {/* <label className={errors.address ? 'form-label label_error' : 'form-label'}>
                                                  Kodi Postar
                                                </label> */}

                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bxs-map"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <input
                                          type="number"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                address_postal_code: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                address_postal_code: false,
                                              },
                                            }))
                                            setRegistrationValue(
                                              'address_postal_code',
                                              value
                                            )
                                          }}
                                          value={
                                            this.props.user.registration_data
                                              .address_postal_code
                                          }
                                          className={
                                            errors.address_postal_code
                                              ? 'form-control ps-5 input_error'
                                              : 'form-control ps-5'
                                          }
                                          placeholder="Kodi postar"
                                          id="address_postal_code"
                                          name="address_postal_code"
                                          required=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* Qyteti */}
                                  <div className="col-md-4">
                                    <div className="mb-4">
                                      {/* <label className={errors.qyteti ? 'form-label label_error' : 'form-label'}>
                                                  Qyteti
                                                </label> */}

                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bxs-map"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <input
                                          type="text"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                address_qyteti: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                address_qyteti: false,
                                              },
                                            }))
                                            setRegistrationValue(
                                              'address_qyteti',
                                              value
                                            )
                                          }}
                                          value={
                                            this.props.user.registration_data
                                              .address_qyteti
                                          }
                                          className={
                                            errors.address_qyteti
                                              ? 'form-control ps-5 input_error'
                                              : 'form-control ps-5'
                                          }
                                          placeholder="Qyteti"
                                          id="address_qyteti"
                                          name="address_qyteti"
                                          required=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>

                            {/* Shteti PB */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.country
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Shteti
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bxs-map"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.country
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh shtetin"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          country: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          country: false,
                                        },
                                      }))
                                      setRegistrationValue('country', value)
                                    }}
                                    id="country"
                                    value={
                                      this.props.user.registration_data
                                        .country !== ''
                                        ? this.props.user.registration_data
                                            .country
                                        : undefined
                                    }
                                    aria-label="Default select example"
                                    defaultValue={'DEFAULT_COUNTRY'}
                                  >
                                    <option value="DEFAULT_COUNTRY" disabled>
                                      {default_country_value}
                                    </option>

                                    {states_list.map((district, index) => {
                                      return (
                                        <option
                                          key={`district${index}.id`}
                                          value={district.name}
                                        >
                                          {district.name}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>

                            {/* Qarku */}
                            {/* <div className="col-md-12">
                                      <div className="mb-4">
                                        <label
                                          className={
                                            errors.district
                                              ? 'form-label label_error'
                                              : 'form-label'
                                          }
                                        >
                                          Qarku
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-icon position-relative">
                                          <Icon
                                            icon="bx:bx-grid-alt"
                                            width="17"
                                            height="17"
                                            className="custom_icon"
                                          />
                                          <select
                                            className={
                                              errors.district
                                                ? 'form-select form-control ps-5 input_error'
                                                : 'form-select form-control ps-5'
                                            }
                                            placeholder="Zhgjidh Qarkun"
                                            onChange={(event) => {
                                              const value = event.target.value
                                              this.setState((prevState) => ({
                                                ...prevState,
                                                registration_data: {
                                                  ...prevState.registration_data,
                                                  district: value,
                                                },
                                                errors: {
                                                  ...prevState.errors,
                                                  district: false,
                                                },
                                              }))
                                              setRegistrationValue(
                                                'district',
                                                value
                                              )
                                            }}
                                            id="district"
                                            value={
                                              this.props.user.registration_data
                                                .district !== ''
                                                ? this.props.user.registration_data
                                                    .district
                                                : undefined
                                            }
                                            aria-label="Default select example"
                                            defaultValue={'DEFAULT_DISTRICT'}
                                          >
                                            <option
                                              value="DEFAULT_DISTRICT"
                                              disabled
                                            >
                                              {default_district_value}
                                            </option>

                                            {district_list.map(
                                              (district, index) => {
                                                return (
                                                  <option
                                                    key={`district${index}.id`}
                                                    value={district.name}
                                                  >
                                                    {district.name}
                                                  </option>
                                                )
                                              }
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                    </div> */}

                            {/*<div className="col-md-12">*/}
                            {/*    <div className="mb-4">*/}
                            {/*        <label*/}
                            {/*            className={*/}
                            {/*                errors.country*/}
                            {/*                    ? 'form-label label_error'*/}
                            {/*                    : 'form-label'*/}
                            {/*            }*/}
                            {/*        >*/}
                            {/*            Shteti<span className="text-danger">*</span>*/}
                            {/*        </label>*/}
                            {/*        <div className="form-icon position-relative">*/}
                            {/*            <Icon*/}
                            {/*                icon="bx:bxs-map"*/}
                            {/*                width="17"*/}
                            {/*                height="17"*/}
                            {/*                className="custom_icon"*/}
                            {/*            />*/}
                            {/*            <input*/}
                            {/*                type="text"*/}
                            {/*                onChange={(event) => {*/}
                            {/*                    const value = event.target.value*/}
                            {/*                    this.setState((prevState) => ({*/}
                            {/*                        ...prevState,*/}
                            {/*                        registration_data: {*/}
                            {/*                            ...prevState.registration_data,*/}
                            {/*                            country: value,*/}
                            {/*                        },*/}
                            {/*                        errors: {*/}
                            {/*                            ...prevState.errors,*/}
                            {/*                            country: false,*/}
                            {/*                        },*/}
                            {/*                    }))*/}
                            {/*                    setRegistrationValue('country', value)*/}
                            {/*                }}*/}
                            {/*                value={*/}
                            {/*                    this.props.user.registration_data.country*/}
                            {/*                }*/}
                            {/*                className={*/}
                            {/*                    errors.country*/}
                            {/*                        ? 'form-control ps-5 input_error'*/}
                            {/*                        : 'form-control ps-5'*/}
                            {/*                }*/}
                            {/*                placeholder="Shteti"*/}
                            {/*                id="country"*/}
                            {/*                name="Shteti"*/}
                            {/*                required=""*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                                {/*Logoja e Biznesit*/}
                                {/* <div className="col-md-12">
                                  <div className="mb-4 mt-4">
                                    <label
                                      className={
                                        errors.bussines_logo_error_show
                                          ? 'form-label label_error'
                                          : 'form-label'
                                      }
                                      htmlFor="customFile"
                                    >
                                      Ngarko ketu logon e biznesit
                                      //* <span className="text-danger">*</span>

                                    </label>
                                    <input
                                      type="file"
                                      accept="image/jpg, image/jpeg, image/png"
                                      className={
                                        errors.bussines_logo_error_show
                                          ? 'form-control input_error'
                                          : 'form-control'
                                      }
                                      onChange={this.uploadMultipleFiles}
                                      // multiple
                                      id="bussines_logo"
                                    />
                                    <p
                                      className="text-danger"
                                      style={{ fontSize: '0.7rem' }}
                                    >
                                      Pranohen vetem imazhe .jpg, .jpeg dhe .png
                                    </p>
                                    {///* {errors.bussines_logo_error_show && <p className="text-danger" style={{fontSize: "0.8rem"}}>Lejohen vetem imazhe me permasa 300x300 px.</p>} *
                                  </div>
                                </div> */}

                                {/* Fotoja e produkit/sherbimit */}
                                {/* <div className="col-md-12">
                                  <div className="mb-4 mt-4">
                                    <label
                                      className={
                                        errors.bussines_logo_error_show
                                          ? 'form-label label_error'
                                          : 'form-label'
                                      }
                                      htmlFor="customFile"
                                    >
                                      Ngarko ketu foton e produktit ose
                                      sherbimit
                                      {//* <span className="text-danger">*</span> *
                                    </label>
                                    <input
                                      type="file"
                                      accept="image/jpg, image/jpeg, image/png"
                                      className={
                                        errors.bussines_logo_error_show
                                          ? 'form-control input_error'
                                          : 'form-control'
                                      }
                                      onChange={this.uploadProductImage}
                                      // multiple
                                      id="productImage"
                                    />
                                    <p
                                      className="text-danger"
                                      style={{ fontSize: '0.7rem' }}
                                    >
                                      Pranohen vetem imazhe .jpg, .jpeg dhe .png
                                    </p>
                                  </div>
                                </div> */}

                            {/* <div className="col-md-12">
                                      <div className="mb-4">
                                          <label className={ errors.product_image_error_show ? 'form-label label_error' : 'form-label'} htmlFor="customFile">Ngarko ketu foton e produktit ose sherbimit:<span className="text-danger">*</span></label>
                                          <input
                                              type="file"
                                              accept="image/jpg, image/jpeg, image/png"
                                              className={ errors.product_image_error_show ? 'form-control input_error' : 'form-control' }
                                              onChange={(event) => {
                                                  const value = event.target.files[0];

                                                  this.convertImageToBase64(value, (result,width,height) => {

                                                      console.log("UPLOAD IMAGE base64 result", result);
                                                      console.log("UPLOAD IMAGE base64 width", width);
                                                      console.log("UPLOAD IMAGE base64 height", height);

                                                      if((width !== 301 || height !== 301) && value.name != ""){
                                                          this.setState(
                                                              (prevState) => ({
                                                                  ...prevState,
                                                                  registration_data: {
                                                                      ...prevState.registration_data,
                                                                      productImage: value,
                                                                  },
                                                                  errors: {
                                                                      ...prevState.errors,
                                                                      product_image_error_show: true,
                                                                  },
                                                                  product_image_error_show: true,
                                                              }),
                                                              () => {}
                                                          )
                                                      }else{
                                                          this.setState(
                                                              (prevState) => ({
                                                                  ...prevState,
                                                                  registration_data: {
                                                                      ...prevState.registration_data,
                                                                      productImage: result,
                                                                  },
                                                                  errors: {
                                                                      ...prevState.errors,
                                                                      product_image_error_show: false,
                                                                  },
                                                                  product_image_error_show: false
                                                              }),
                                                              () => {
                                                                  setRegistrationValue('product_img', result)
                                                              }
                                                          )
                                                      }
                                                  });
                                              }}
                                              id="customProductFile"
                                          />
                                          <p className="text-danger" style={{fontSize: "0.8rem"}}> Pranohen vetem imazhe .jpg, .jpeg dhe .png me permasa 300x300 px</p>
                                          {! errors.product_image_error_show &&  <p className="text-danger" style={{fontSize: "0.8rem"}}>Pranohen vetem imazhe .jpg, .jpeg dhe .png me permasa 300x300 px.</p>}
                                          {errors.product_image_error_show && <p className="text-danger" style={{fontSize: "0.8rem"}}> Pranohen vetem imazhe .jpg, .jpeg dhe .png me permasa 300x300 px.</p>}
                                      </div>
                                  </div> */}

                                {/* Fotoja e profilit */}
                                {/* <div className="col-md-12">
                                  <div className="mb-4 mt-4">
                                    <label
                                      className={
                                        errors.bussines_logo_error_show
                                          ? 'form-label label_error'
                                          : 'form-label'
                                      }
                                      htmlFor="customFile"
                                    >
                                      Ngarko ketu foton e profilit
                                      {//* <span className="text-danger">*</span> *
                                    </label>
                                    <input
                                      type="file"
                                      accept="image/jpg, image/jpeg, image/png"
                                      className={
                                        errors.bussines_logo_error_show
                                          ? 'form-control input_error'
                                          : 'form-control'
                                      }
                                      onChange={this.uploadProfileImage}
                                      // multiple
                                      id="profileImage"
                                    />
                                    <p
                                      className="text-danger"
                                      style={{ fontSize: '0.7rem' }}
                                    >
                                      Pranohen vetem imazhe .jpg, .jpeg dhe .png
                                    </p>
                                  </div>
                                </div> */}

                            {/* Persona qe lidhen ne shoqeri? */}
                            <div
                              className="col-md-12"
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div className="mb-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={(event) => {
                                      const value = event.target.checked
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          people_connected_in_society: value
                                            ? value
                                            : false,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          people_connected_in_society: false,
                                        },
                                      }))
                                      setRegistrationValue(
                                        'people_connected_in_society',
                                        value
                                      )
                                    }}
                                    value={
                                      this.props.user.registration_data
                                        .people_connected_in_society
                                    }
                                    id="people_connected_in_society"
                                  />
                                  <label
                                    className={
                                      errors.people_connected_in_society
                                        ? 'form-check-label label_error'
                                        : 'form-check-label'
                                    }
                                    htmlFor="people_connected_in_society"
                                  >
                                    Persona të tjerë që lidhen në shoqëri?
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                              </div>

                              {this.props.user.registration_data.people_connected_in_society ? (
                                <div className="col-md-3">
                                  <div className="d-grid">
                                    <a
                                      className="btn btn-light border-button"
                                      onClick={() => {
                                        this.openModal()
                                      }}
                                    >
                                      {' '}
                                      Shto
                                    </a>
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            {this.props.user.registration_data.people_connected_in_society ? (
                              <div
                                className="col-md-12"
                                style={{
                                  marginTop: '3rem',
                                  marginBottom: '3rem',
                                }}
                              >
                                {/*<h5 className="card-title text-center text-uppercase" style={{ color: '#bf1e2e' }}>{' '}{shareholders_alert_form_data.shareholder_type}{' '}</h5>*/}

                                <div className="table-responsive bg-white shadow rounded">
                                  <table className="table mb-0 table-center invoice-tb">
                                    <thead className="bg-light">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Id
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Lloji lidhjes
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Emër
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Mbiemër
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Gjinia
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Datëlindje
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Vëndlindje
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Atësi
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Shtetësia
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Adresë Banimi
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Shtetësi e dytë
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Origjina
                                        </th>

                                        {/*<th scope="col" className="border-bottom text-start">Veprime</th>*/}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.props.user.registration_data?.shareholders?.map(
                                        (item, key) => {
                                          return (
                                            <tr key={key}>
                                              <td
                                                scope="row"
                                                className="text-start"
                                              >
                                                {key + 1}
                                              </td>
                                              <td
                                                scope="row"
                                                className="text-start"
                                              >
                                                {capitalize(
                                                  item.shareholder_type
                                                )}
                                              </td>
                                              <td
                                                scope="row"
                                                className="text-start"
                                              >
                                                {capitalize(item.firstName)}
                                              </td>
                                              <td className="text-start">
                                                {capitalize(item.lastName)}
                                              </td>
                                              <td className="text-start">
                                                {capitalize(item.gender)}
                                              </td>
                                              <td className="text-start">
                                                {item.birthday.toString()}
                                              </td>
                                              <td className="text-start">
                                                {capitalize(item.birthplace)}
                                              </td>
                                              <td className="text-start">
                                                {item.fatherName}
                                              </td>
                                              <td className="text-start">
                                                {item.nationality}
                                              </td>
                                              <td className="text-start">
                                                {item.resident_address}
                                              </td>
                                              <td className="text-start">
                                                {item.secondNationality}
                                              </td>
                                              <td className="text-start">
                                                {item.origin}
                                              </td>

                                              {/*<td className="text-center" style={{display: 'flex', justifyContent:'space-evenly'}}>*/}
                                              {/*    <i onClick={() => this.editShareholder(item.firstName)} className="uil uil-edit user_profile_text_green cursor_pointer"/>*/}
                                              {/*    <i onClick={(item) => this.deleteShareholder(item)} className="uil uil-trash user_profile_text_green cursor_pointer"/>*/}
                                              {/*</td>*/}
                                            </tr>
                                          )
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            ) : null}
                          </section>
                        ) : null}



                        {/*PROFIL SIPERMARRES I VETPUNESUAR*/}
                        {this.props.user.registration_data.bussines_category ==='PSV' ? (
                          <section>
                            {/* Kodi Fiskal PSV */}
                            <div className="mb-4">
                              <label
                                className={
                                  errors.fiscal_code
                                    ? 'form-label label_error'
                                    : 'form-label'
                                }
                              >
                                {' '}
                                Kodi fiskal{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="form-icon position-relative">
                                <Icon
                                  icon="bx:bx-building-house"
                                  width="17"
                                  height="17"
                                  className="custom_icon"
                                />
                                <input
                                  type="text"
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        fiscal_code: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        fiscal_code: false,
                                      },
                                    }))
                                    setRegistrationValue('fiscal_code', value)
                                  }}
                                  value={
                                    this.props.user.registration_data
                                      .fiscal_code
                                  }
                                  className={
                                    errors.fiscal_code
                                      ? 'form-control ps-5 input_error'
                                      : 'form-control ps-5'
                                  }
                                  placeholder="Kodi fiskal"
                                  id="fiscal_code"
                                  name="Kodi fiskal"
                                  required=""
                                />
                              </div>
                            </div>

                            {/* Emri PSV */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.name
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Emër <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-building-house"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="text"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          name: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          name: false,
                                        },
                                      }))
                                      setRegistrationValue('name', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.name
                                    }
                                    className={
                                      errors.name
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Emër"
                                    id="name"
                                    name="Emër"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Emri Tregtare PSV */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.bussines_name
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  {' '}
                                  Emri Tregtar{' '}
                                  <span className="text-danger">*</span>{' '}
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-building-house"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="text"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          bussines_name: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          bussines_name: false,
                                        },
                                      }))
                                      setRegistrationValue(
                                        'bussines_name',
                                        value
                                      )
                                    }}
                                    value={
                                      this.props.user.registration_data
                                        .bussines_name
                                    }
                                    className={
                                      errors.bussines_name
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Emri Tregtar"
                                    id="bussines_name"
                                    name="Emri Tregtar"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Logo e Biznesit         */}
                            {/*<div className="col-md-12">*/}
                            {/*    <div className="mb-4">*/}
                            {/*        <label*/}
                            {/*            className={*/}
                            {/*                errors.file*/}
                            {/*                    ? 'form-label label_error'*/}
                            {/*                    : 'form-label'*/}
                            {/*            }*/}
                            {/*            htmlFor="customFile"*/}
                            {/*        >*/}
                            {/*            Ngarko ketu logon e biznesit:*/}
                            {/*            <span className="text-danger">*</span>*/}
                            {/*        </label>*/}
                            {/*        <input*/}
                            {/*            type="file"*/}
                            {/*            accept="image/png"*/}
                            {/*            className={*/}
                            {/*                errors.file*/}
                            {/*                    ? 'form-control input_error'*/}
                            {/*                    : 'form-control'*/}
                            {/*            }*/}
                            {/*            onChange={(event) => {*/}
                            {/*                const value = event.target.files[0]*/}
                            {/*                this.setState(*/}
                            {/*                    (prevState) => ({*/}
                            {/*                        ...prevState,*/}
                            {/*                        registration_data: {*/}
                            {/*                            ...prevState.registration_data,*/}
                            {/*                            file: value,*/}
                            {/*                        },*/}
                            {/*                        errors: {*/}
                            {/*                            ...prevState.errors,*/}
                            {/*                            file: false,*/}
                            {/*                        },*/}
                            {/*                    }),*/}
                            {/*                    () => {*/}
                            {/*                        uploadMedia(value)*/}
                            {/*                    }*/}
                            {/*                )*/}
                            {/*            }}*/}
                            {/*            id="customFile"*/}
                            {/*        />*/}
                            {/*        /!*<p className="text-danger"> Pranohen vetem imazhe .png me permasa 300x300 px </p>*!/*/}
                            {/*        <p className="text-danger"> Pranohen vetem imazhe .png</p>*/}
                            {/*    </div>*/}

                            {/*</div>*/}

                            {/*Fotoja e produkit/sherbimit         */}
                            {/*<div className="col-md-12">*/}
                            {/*    <div className="mb-4">*/}
                            {/*        <label*/}
                            {/*            className={*/}
                            {/*                errors.productImage*/}
                            {/*                    ? 'form-label label_error'*/}
                            {/*                    : 'form-label'*/}
                            {/*            }*/}
                            {/*            htmlFor="customFile"*/}
                            {/*        >*/}
                            {/*            Ngarko ketu foton e produktit/shërbimit:*/}
                            {/*            <span className="text-danger">*</span>*/}
                            {/*        </label>*/}
                            {/*        <input*/}
                            {/*            type="file"*/}
                            {/*            accept="image/png"*/}
                            {/*            className={*/}
                            {/*                errors.productImage*/}
                            {/*                    ? 'form-control input_error'*/}
                            {/*                    : 'form-control'*/}
                            {/*            }*/}
                            {/*            onChange={(event) => {*/}
                            {/*                const value = event.target.files[0]*/}
                            {/*                this.setState(*/}
                            {/*                    (prevState) => ({*/}
                            {/*                        ...prevState,*/}
                            {/*                        registration_data: {*/}
                            {/*                            ...prevState.registration_data,*/}
                            {/*                            productImage: value,*/}
                            {/*                        },*/}
                            {/*                        errors: {*/}
                            {/*                            ...prevState.errors,*/}
                            {/*                            productImage: false,*/}
                            {/*                        },*/}
                            {/*                    }),*/}
                            {/*                    () => {*/}
                            {/*                        uploadMedia(value)*/}
                            {/*                    }*/}
                            {/*                )*/}
                            {/*            }}*/}
                            {/*            id="customProductFile"*/}
                            {/*        />*/}
                            {/*        /!*<p className="text-danger"> Pranohen vetem imazhe .png me permasa 300x300 px </p>*!/*/}
                            {/*        <p className="text-danger"> Pranohen vetem imazhe .png</p>*/}
                            {/*    </div>*/}

                            {/*</div>*/}

                            {/* Statusi Ligjor PSV */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.legal_status
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Statusi Ligjor{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-grid-alt"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.legal_status
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh statusin ligjor"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          legal_status: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          legal_status: false,
                                        },
                                      }))
                                      setRegistrationValue('legal_status', value)
                                      this.setState({otherLegalStatusValue: ''})
                                    }}
                                    id="psv_legal_status"
                                    // value={
                                    //   this.props.user.registration_data.legal_status !== ''
                                    //     ? this.props.user.registration_data.legal_status
                                    //     : undefined
                                    // }
                                    aria-label="Default select example"
                                    defaultValue={'DEFAULT_LEGAL_STATUS'}
                                  >
                                    <option value="DEFAULT_LEGAL_STATUS" disabled>{default_legal_status_value}</option>
                                    {legal_status_list.map(
                                      (district, index) => {
                                        return (
                                          <option key={`district${index}.id`} value={district.name}>{district.name}</option>
                                        )
                                      }
                                    )}
                                  </select>
                                </div>
                              </div>

                              {/* Tjeter Specifiko PSV*/}
                              {this.props.user.registration_data.legal_status === 'Tjetër' && (
                                <SweetAlert
                                  title={''}
                                  onConfirm={this.setLegalStatusValue}
                                  type={'controlled'}
                                  dependencies={[otherLegalStatusValue]}
                                >
                                  {(renderProps: SweetAlertRenderProps) => (
                                    <form>
                                      <input
                                        type={'text'}
                                        ref={renderProps.setAutoFocusInputRef}
                                        className="form-control"
                                        value={otherLegalStatusValue}
                                        onKeyDown={
                                          renderProps.onEnterKeyDownConfirm
                                        }
                                        onChange={(e) =>
                                          this.setState({otherLegalStatusValue: e.target.value})
                                        }
                                        placeholder={'Specifiko statusin ligjor'}
                                      />
                                    </form>
                                  )}
                                </SweetAlert>
                              )}

                              {otherLegalStatusValue.length > 0 && (
                                <div className="mt-4 mb-4">
                                  <label className={'form-label'}>
                                    Statusi ligjor tjetër
                                  </label>
                                  <input
                                    type="text"
                                    value={otherLegalStatusValue}
                                    placeholder=""
                                    id="psv_other_legal_status"
                                    name="psv_other_legal_status"
                                    className={'form-control'}
                                    required=""
                                  />
                                </div>
                              )}
                            </div>

                            {/* Objekti PSV */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.area
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Objekti
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-grid-alt"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.area
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh objektin"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          area: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          area: false,
                                        },
                                      }))
                                      setRegistrationValue('area', value)
                                      this.setState({otherAreaValue: ''})
                                    }}
                                    id="area"
                                    // value={
                                    //   this.props.user.registration_data.area !==
                                    //   ''
                                    //     ? this.props.user.registration_data.area
                                    //     : undefined
                                    // }
                                    aria-label="Default select example"
                                    defaultValue={'DEFAULT_OBJECT_VALUE'}
                                  >
                                    <option
                                      value="DEFAULT_OBJECT_VALUE"
                                      disabled
                                    >
                                      {' '}
                                      {default_legal_status_value}{' '}
                                    </option>

                                    {area_list.map((district, index) => {
                                      return (
                                        <option
                                          key={`district${index}.id`}
                                          value={district.name}
                                        >
                                          {district.name}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>

                              {/* Tjeter Specifiko */}
                              {this.props.user.registration_data.area === 'Tjetër' && (
                                <SweetAlert
                                  title={''}
                                  onConfirm={this.setAreaValue}
                                  type={'controlled'}
                                  dependencies={[this.state.otherAreaValue]}
                                >
                                  {(renderProps: SweetAlertRenderProps) => (
                                    <form>
                                      <input
                                        type={'text'}
                                        ref={renderProps.setAutoFocusInputRef}
                                        className="form-control"
                                        value={this.state.otherAreaValue}
                                        onKeyDown={
                                          renderProps.onEnterKeyDownConfirm
                                        }
                                        onChange={(e) =>
                                          this.setState({
                                            otherAreaValue: e.target.value,
                                          })
                                        }
                                        placeholder={'Specifiko objektin'}
                                      />
                                    </form>
                                  )}
                                </SweetAlert>
                              )}

                              {otherAreaValue.length > 0 && (
                                <div className="mt-4 mb-4">
                                  <label className={'form-label'}>
                                    Objekti tjetër
                                  </label>
                                  <input
                                    type="text"
                                    value={otherAreaValue}
                                    className={'form-control'}
                                    placeholder="Specifiko objektin"
                                    id="other_district"
                                    name="other_district"
                                    required=""
                                  />
                                </div>
                              )}
                            </div>

                            {/* Viti Themelimit PSV */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.year_of_establishment
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Viti i themelimit
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-grid-alt"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.year_of_establishment
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh vitin"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          year_of_establishment: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          year_of_establishment: false,
                                        },
                                      }))
                                      setRegistrationValue(
                                        'year_of_establishment',
                                        value
                                      )
                                    }}
                                    id="year_of_establishment"
                                    value={
                                      this.props.user.registration_data
                                        .year_of_establishment !== ''
                                        ? this.props.user.registration_data
                                            .year_of_establishment
                                        : undefined
                                    }
                                    aria-label="Default select example"
                                    defaultValue={
                                      'DEFAULT_YEAR_OF_ESTABLISHMENT'
                                    }
                                  >
                                    <option
                                      value="DEFAULT_YEAR_OF_ESTABLISHMENT"
                                      disabled
                                    >
                                      Zhgjidh vitin e themelimit ...
                                    </option>

                                    {this.years.map((year, index) => {
                                      return (
                                        <option
                                          key={`year${index}`}
                                          value={year}
                                        >
                                          {year}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>

                            {/* Emaili PSV */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.email
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  E-mail
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <PermIdentityIcon className="fea icon-sm custom_icon" />
                                  <input
                                    type="email"
                                    autoComplete="off"
                                    className={
                                      errors.email
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          email: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          email: false,
                                        },
                                      }))
                                      setRegistrationValue('email', value)
                                    }}
                                    placeholder="Email"
                                    name="email"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/*Paswordi PSV*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.password
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Password
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="feather:key"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="password"
                                    autoComplete="new-password"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          password: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          password: false,
                                        },
                                      }))
                                      setRegistrationValue('password', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.password
                                    }
                                    className={
                                      errors.password
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Password"
                                    id="password"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/*Konfirmo Paswordi PSV*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.confirm_password
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Konfirmo Passwordin
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="feather:key"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="password"
                                    autoComplete="new-password"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          confirm_password: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          confirm_password: false,
                                        },
                                      }))
                                      setRegistrationValue(
                                        'confirm_password',
                                        value
                                      )
                                    }}
                                    value={
                                      this.props.user.registration_data
                                        .confirm_password
                                    }
                                    className={
                                      errors.confirm_password
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Konfirmo Passwordin"
                                    id="confirm_password"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {this.props.user.registration_data.password !== this.props.user.registration_data.confirm_password && (
                              <div className="mt-4 mb-4">
                                <label className={'form-label label_error'}>
                                  {password_dont_match_msg}
                                </label>
                              </div>
                            )}

                            {/* Telefoni PSV */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.telefono
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Telefoni
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="ci:phone-outline"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="number"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          telefono: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          telefono: false,
                                        },
                                      }))
                                      setRegistrationValue('telefono', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.telefono
                                    }
                                    className={
                                      errors.telefono
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Telefoni"
                                    name="telefono"
                                    id="telefono"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Old Adresa e Biznesit */}
                            {/* <div className="col-md-12">
                                    <div className="mb-4">
                                      <label
                                        className={
                                          errors.address
                                            ? 'form-label label_error'
                                            : 'form-label'
                                        }
                                      >
                                        Adresa
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bxs-map"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <input
                                          type="text"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                address: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                address: false,
                                              },
                                            }))
                                            setRegistrationValue('address', value)
                                          }}
                                          value={
                                            this.props.user.registration_data
                                              .address
                                          }
                                          className={
                                            errors.address
                                              ? 'form-control ps-5 input_error'
                                              : 'form-control ps-5'
                                          }
                                          placeholder="Adresa"
                                          id="address"
                                          name="Adresa"
                                          required=""
                                        />
                                      </div>
                                    </div>
                                  </div> */}

                            {/* Adresa e Biznesit */}
                            <div className="row">
                              <section>
                                <div className="mb-2">
                                  <label className={errors.address ? 'form-label label_error' : 'form-label'}>Adresa<span className="text-danger">*</span></label>
                                </div>

                                <div className="row">
                                  {/* Rruga */}
                                  <div className="col-md-4">
                                    <div className="mb-4">
                                      {/* <label className={errors.address ? 'form-label label_error' : 'form-label'}>
                                                Rruga/Nr.Shpisë
                                              </label> */}

                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bxs-map"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <input
                                          type="text"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                address_street: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                address_street: false,
                                              },
                                            }))
                                            setRegistrationValue(
                                              'address_street',
                                              value
                                            )
                                          }}
                                          value={
                                            this.props.user.registration_data
                                              .address_street
                                          }
                                          className={
                                            errors.address_street
                                              ? 'form-control ps-5 input_error'
                                              : 'form-control ps-5'
                                          }
                                          placeholder="Rruga / numri banesës"
                                          id="address_street"
                                          name="address_street"
                                          required=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* Kodi Postar */}
                                  <div className="col-md-4">
                                    <div className="mb-4">
                                      {/* <label className={errors.address ? 'form-label label_error' : 'form-label'}>
                                                Kodi Postar
                                              </label> */}

                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bxs-map"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <input
                                          type="number"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                address_postal_code: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                address_postal_code: false,
                                              },
                                            }))
                                            setRegistrationValue(
                                              'address_postal_code',
                                              value
                                            )
                                          }}
                                          value={
                                            this.props.user.registration_data
                                              .address_postal_code
                                          }
                                          className={
                                            errors.address_postal_code
                                              ? 'form-control ps-5 input_error'
                                              : 'form-control ps-5'
                                          }
                                          placeholder="Kodi postar"
                                          id="address_postal_code"
                                          name="address_postal_code"
                                          required=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* Qyteti */}
                                  <div className="col-md-4">
                                    <div className="mb-4">
                                      {/* <label className={errors.qyteti ? 'form-label label_error' : 'form-label'}>
                                                Qyteti
                                              </label> */}

                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bxs-map"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <input
                                          type="text"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                address_qyteti: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                address_qyteti: false,
                                              },
                                            }))
                                            setRegistrationValue(
                                              'address_qyteti',
                                              value
                                            )
                                          }}
                                          value={
                                            this.props.user.registration_data
                                              .address_qyteti
                                          }
                                          className={
                                            errors.address_qyteti
                                              ? 'form-control ps-5 input_error'
                                              : 'form-control ps-5'
                                          }
                                          placeholder="Qyteti"
                                          id="address_qyteti"
                                          name="address_qyteti"
                                          required=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>

                            {/* Qarku */}
                            {/* <div className="col-md-12">
                                    <div className="mb-4">
                                      <label
                                        className={
                                          errors.district
                                            ? 'form-label label_error'
                                            : 'form-label'
                                        }
                                      >
                                        Qarku
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bx-grid-alt"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <select
                                          className={
                                            errors.district
                                              ? 'form-select form-control ps-5 input_error'
                                              : 'form-select form-control ps-5'
                                          }
                                          placeholder="Zhgjidh Qarkun"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                district: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                district: false,
                                              },
                                            }))
                                            setRegistrationValue(
                                              'district',
                                              value
                                            )
                                          }}
                                          id="district"
                                          value={
                                            this.props.user.registration_data
                                              .district !== ''
                                              ? this.props.user.registration_data
                                                  .district
                                              : undefined
                                          }
                                          aria-label="Default select example"
                                          defaultValue={'DEFAULT_DISTRICT'}
                                        >
                                          <option
                                            value="DEFAULT_DISTRICT"
                                            disabled
                                          >
                                            {default_district_value}
                                          </option>

                                          {district_list.map(
                                            (district, index) => {
                                              return (
                                                <option
                                                  key={`district${index}.id`}
                                                  value={district.name}
                                                >
                                                  {district.name}
                                                </option>
                                              )
                                            }
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                  </div> */}

                            {/* Shteti */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.country
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Shteti
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bxs-map"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.country
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh shtetin"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          country: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          country: false,
                                        },
                                      }))
                                      setRegistrationValue('country', value)
                                    }}
                                    id="country"
                                    value={
                                      this.props.user.registration_data
                                        .country !== ''
                                        ? this.props.user.registration_data
                                            .country
                                        : undefined
                                    }
                                    aria-label="Default select example"
                                    defaultValue={'DEFAULT_COUNTRY'}
                                  >
                                    <option value="DEFAULT_COUNTRY" disabled>
                                      {default_country_value}
                                    </option>

                                    {states_list.map((district, index) => {
                                      return (
                                        <option
                                          key={`district${index}.id`}
                                          value={district.name}
                                        >
                                          {district.name}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </section>
                        ) : null}




                        {/*PROFIL LIDERSHIPI*/}
                        {this.props.user.registration_data.bussines_category === 'PL' ? (
                          <section>

                            {/* Emri PL */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.name
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Emër <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-building-house"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="text"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          name: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          name: false,
                                        },
                                      }))
                                      setRegistrationValue('name', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.name
                                    }
                                    className={
                                      errors.name
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Emër"
                                    id="name"
                                    name="Emër"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Mbiemri PL */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.lastName
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Mbiemer <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-building-house"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="text"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          lastName: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          lastName: false,
                                        },
                                      }))
                                      setRegistrationValue('lastName', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.lastName
                                    }
                                    className={
                                      errors.lastName
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Mbiemër"
                                    id="lastName"
                                    name="Mbiemër"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/*Gjinia PL*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.gender
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Gjinia <span className="text-danger">*</span>
                                </label>
                                <select
                                  className={
                                    errors.gender
                                      ? 'form-select form-control input_error'
                                      : 'form-select form-control'
                                  }
                                  placeholder="Gjinia"
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        gender: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        gender: false,
                                      },
                                    }))
                                    setRegistrationValue('gender', value)
                                  }}
                                  id="gender"
                                  value={
                                    this.props.user.registration_data.gender !==
                                    ''
                                      ? this.props.user.registration_data.gender
                                      : undefined
                                  }
                                  aria-label="Default select example"
                                  defaultValue={'DEFAULT_GENDER'}
                                >
                                  <option value="DEFAULT_GENDER" disabled>
                                    Gjinia ...
                                  </option>
                                  <option value="M">Mashkull</option>
                                  <option value="F">Femer</option>
                                </select>
                              </div>
                            </div>

                            {/* <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.birthday
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Datëlindje
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type={'text'}
                                  className={
                                    errors.birthday
                                      ? 'form-control input_error'
                                      : 'form-control'
                                  }
                                  value={
                                    this.props.user.registration_data
                                      .birthday !== ''
                                      ? this.props.user.registration_data
                                          .birthday
                                      : undefined
                                  }
                                  // onKeyDown={renderProps.onEnterKeyDownConfirm}
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        birthday: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        birthday: false,
                                      },
                                    }))
                                    setRegistrationValue('birthday', value)
                                  }}
                                  placeholder={'Datlindje e.x 20/12/1998'}
                                />
                              </div>
                            </div> */}


                            {/*Birthday PL */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.birthday
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Datëlindje{' '}
                                  {/* <span className="text-danger">*</span> */}
                                </label>

                                <div>
                                  <label
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <DatePicker
                                      placeholderText="Datlindje e.x 20/12/1998"
                                      className={'form-control'}
                                      selected={selectedBirthday}
                                      // onSelect={(date) => this.handleStartDateSelect(date)}
                                      onChange={(date) => this.setBirthday(date)}

                                      // onChange={(event) => {
                                      //   const value = event.target.value
                                      //   this.setState((prevState) => ({
                                      //     ...prevState,
                                      //     registration_data: {
                                      //       ...prevState.registration_data,
                                      //       birthday: value,
                                      //     },
                                      //     errors: {
                                      //       ...prevState.errors,
                                      //       birthday: false,
                                      //     },
                                      //   }))
                                      //   setRegistrationValue('birthday', value)
                                      // }}

                                      // customInput={<CustomInput />}
                                      dateFormat="dd/MM/yyyy"
                                      todayButton="SOT"
                                    />
                                    {/* <span className="form-icon position-relative">
                                    <Icon
                                      icon="bx:bx-calendar"
                                      width="17"
                                      height="17"
                                      className="custom_icon"
                                    />
                                  </span> */}
                                  </label>
                                </div>
                              </div>
                            </div>

                            {/* Birthplace PL */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.birthplace
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Vëndlindje{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type={'text'}
                                  className={
                                    errors.birthplace
                                      ? 'form-control input_error'
                                      : 'form-control'
                                  }
                                  value={
                                    this.props.user.registration_data
                                      .birthplace !== ''
                                      ? this.props.user.registration_data
                                          .birthplace
                                      : undefined
                                  }
                                  // onKeyDown={renderProps.onEnterKeyDownConfirm}
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        birthplace: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        birthplace: false,
                                      },
                                    }))
                                    setRegistrationValue('birthplace', value)
                                  }}
                                  placeholder={'Vëndlindje'}
                                />
                              </div>
                            </div>

                            {/*Atesia PL*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.fatherName
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Atësia <span className="text-danger">*</span>
                                </label>
                                <input
                                  type={'text'}
                                  className={
                                    errors.fatherName
                                      ? 'form-control input_error'
                                      : 'form-control'
                                  }
                                  value={
                                    this.props.user.registration_data
                                      .fatherName !== ''
                                      ? this.props.user.registration_data
                                          .fatherName
                                      : undefined
                                  }
                                  // onKeyDown={renderProps.onEnterKeyDownConfirm}
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        fatherName: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        fatherName: false,
                                      },
                                    }))
                                    setRegistrationValue('fatherName', value)
                                  }}
                                  placeholder={'Atësi'}
                                />
                              </div>
                            </div>

                            {/*/!*Shtetesia PL*!/*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.nationality
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Shtetësi{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type={'text'}
                                  className={
                                    errors.nationality
                                      ? 'form-control input_error'
                                      : 'form-control'
                                  }
                                  value={
                                    this.props.user.registration_data
                                      .nationality !== ''
                                      ? this.props.user.registration_data
                                          .nationality
                                      : undefined
                                  }
                                  // onKeyDown={renderProps.onEnterKeyDownConfirm}
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        nationality: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        nationality: false,
                                      },
                                    }))
                                    setRegistrationValue('nationality', value)
                                  }}
                                  placeholder={'Shtetësi'}
                                />
                              </div>
                            </div>

                            {/*Adresa Banimit*/}
                            {/* <div className="col-md-12">
                                      <div className="mb-4">
                                        <label
                                          className={
                                            errors.resident_address
                                              ? 'form-label label_error'
                                              : 'form-label'
                                          }
                                        >
                                          Adresë Banimi{' '}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          type={'text'}
                                          className={
                                            errors.resident_address
                                              ? 'form-control input_error'
                                              : 'form-control'
                                          }
                                          value={
                                            this.props.user.registration_data
                                              .resident_address !== ''
                                              ? this.props.user.registration_data
                                                  .resident_address
                                              : undefined
                                          }
                                          // onKeyDown={renderProps.onEnterKeyDownConfirm}
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                resident_address: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                resident_address: false,
                                              },
                                            }))
                                            setRegistrationValue(
                                              'resident_address',
                                              value
                                            )
                                          }}
                                          placeholder={'Adrese banimi'}
                                        />
                                      </div>
                                    </div> */}

                            {/* Adresa e Banimit PL */}
                            <div className="row">
                              <section>
                                <div className="mb-2">
                                  <label
                                    className={
                                      errors.address
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Adresë Banimi{' '}<span className="text-danger">*</span>
                                  </label>
                                </div>

                                <div className="row">
                                  {/* Rruga */}
                                  <div className="col-md-4">
                                    <div className="mb-4">
                                      {/* <label className={errors.address ? 'form-label label_error' : 'form-label'}>
                                                  Rruga/Nr.Shpisë
                                                </label> */}

                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bxs-map"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <input
                                          type="text"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                address_street: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                address_street: false,
                                              },
                                            }))
                                            setRegistrationValue(
                                              'address_street',
                                              value
                                            )
                                          }}
                                          value={
                                            this.props.user.registration_data.address_street
                                          }
                                          className={
                                            errors.address_street
                                              ? 'form-control ps-5 input_error'
                                              : 'form-control ps-5'
                                          }
                                          placeholder="Rruga / numri banesës"
                                          id="address_street"
                                          name="address_street"
                                          required=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* Kodi Postar */}
                                  <div className="col-md-4">
                                    <div className="mb-4">
                                      {/* <label className={errors.address ? 'form-label label_error' : 'form-label'}>
                                                  Kodi Postar
                                                </label> */}

                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bxs-map"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <input
                                          type="number"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                address_postal_code: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                address_postal_code: false,
                                              },
                                            }))
                                            setRegistrationValue('address_postal_code',value)
                                          }}
                                          value={
                                            this.props.user.registration_data.address_postal_code
                                          }
                                          className={
                                            errors.address_postal_code
                                              ? 'form-control ps-5 input_error'
                                              : 'form-control ps-5'
                                          }
                                          placeholder="Kodi postar"
                                          id="address_postal_code"
                                          name="address_postal_code"
                                          required=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* Qyteti */}
                                  <div className="col-md-4">
                                    <div className="mb-4">
                                      {/* <label className={errors.qyteti ? 'form-label label_error' : 'form-label'}>
                                                  Qyteti
                                                </label> */}

                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bxs-map"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <input
                                          type="text"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                address_qyteti: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                address_qyteti: false,
                                              },
                                            }))
                                            setRegistrationValue('address_qyteti',value)
                                          }}
                                          value={
                                            this.props.user.registration_data.address_qyteti
                                          }
                                          className={
                                            errors.address_qyteti
                                              ? 'form-control ps-5 input_error'
                                              : 'form-control ps-5'
                                          }
                                          placeholder="Qyteti"
                                          id="address_qyteti"
                                          name="address_qyteti"
                                          required=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>

                            {/*/!*Shtetesia Dyte *!/*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.secondNationality
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Shtetësi Tjeter
                                </label>
                                <input
                                  type={'text'}
                                  className={
                                    errors.secondNationality
                                      ? 'form-control input_error'
                                      : 'form-control'
                                  }
                                  value={
                                    this.props.user.registration_data.secondNationality !== ''
                                      ? this.props.user.registration_data.secondNationality
                                      : undefined
                                  }
                                  // onKeyDown={renderProps.onEnterKeyDownConfirm}
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        secondNationality: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        secondNationality: false,
                                      },
                                    }))
                                    setRegistrationValue('secondNationality',value)
                                  }}
                                  placeholder={'Shtetësi Tjeter'}
                                />
                              </div>
                            </div>

                            {/*Origjina PL*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.resident_address
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Origjina{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  className={
                                    errors.origin
                                      ? 'form-select form-control input_error'
                                      : 'form-select form-control'
                                  }
                                  placeholder="Zhgjidh origjinen ..."
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        origin: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        origin: false,
                                      },
                                    }))
                                    setRegistrationValue('origin', value)
                                  }}
                                  id="origin"
                                  value={
                                    this.props.user.registration_data.origin !==
                                    ''
                                      ? this.props.user.registration_data.origin
                                      : undefined
                                  }
                                  aria-label="Default select example"
                                  defaultValue={'DEFAULT_ORIGIN'}
                                >
                                  <option value="DEFAULT_ORIGIN" disabled>
                                    {' '}
                                    {default_origin_value}
                                  </option>

                                  {origin_list.map((origin, index) => {
                                    return (
                                      <option
                                        key={`origin${index}.id`}
                                        value={origin.name}
                                      >
                                        {origin.name}
                                      </option>
                                    )
                                  })}
                                </select>
                              </div>
                            </div>

                            {/*Arsimi PL*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.education
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Arsimi <span className="text-danger">*</span>
                                </label>
                                <select
                                  className={
                                    errors.education
                                      ? 'form-select form-control input_error'
                                      : 'form-select form-control'
                                  }
                                  placeholder="Zhgjidh arsimin ..."
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        education: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        education: false,
                                      },
                                    }))
                                    setRegistrationValue('education', value)
                                  }}
                                  id="education"
                                  value={
                                    this.props.user.registration_data
                                      .education !== ''
                                      ? this.props.user.registration_data
                                          .education
                                      : undefined
                                  }
                                  aria-label="Default select example"
                                  defaultValue={'DEFAULT_ORIGIN'}
                                >
                                  <option value="DEFAULT_ORIGIN" disabled>
                                    {' '}
                                    {default_education_value}
                                  </option>

                                  {education_list.map((origin, index) => {
                                    return (
                                      <option
                                        key={`origin${index}.id`}
                                        value={origin.name}
                                      >
                                        {origin.name}
                                      </option>
                                    )
                                  })}
                                </select>
                              </div>
                            </div>

                            {/*Fusha Arsimit*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.educationField
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Fusha e Arsimit{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type={'text'}
                                  className={
                                    errors.educationField
                                      ? 'form-control input_error'
                                      : 'form-control'
                                  }
                                  value={
                                    this.props.user.registration_data
                                      .educationField !== ''
                                      ? this.props.user.registration_data
                                          .educationField
                                      : undefined
                                  }
                                  // onKeyDown={renderProps.onEnterKeyDownConfirm}
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        educationField: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        educationField: false,
                                      },
                                    }))
                                    setRegistrationValue(
                                      'educationField',
                                      value
                                    )
                                  }}
                                  placeholder={'Fusha e Arsimit'}
                                />
                              </div>
                            </div>

                            {/* Emaili */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.email
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  E-mail
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <PermIdentityIcon className="fea icon-sm custom_icon" />
                                  <input
                                    type="email"
                                    autoComplete="off"
                                    className={
                                      errors.email
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          email: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          email: false,
                                        },
                                      }))
                                      setRegistrationValue('email', value)
                                    }}
                                    placeholder="Email"
                                    name="email"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/*Paswordi*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.password
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Password
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="feather:key"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="password"
                                    autoComplete="new-password"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          password: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          password: false,
                                        },
                                      }))
                                      setRegistrationValue('password', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.password
                                    }
                                    className={
                                      errors.password
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Password"
                                    id="password"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/*Konfirmo Paswordi*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.confirm_password
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Konfirmo Passwordin
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="feather:key"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="password"
                                    autoComplete="new-password"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          confirm_password: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          confirm_password: false,
                                        },
                                      }))
                                      setRegistrationValue(
                                        'confirm_password',
                                        value
                                      )
                                    }}
                                    value={
                                      this.props.user.registration_data
                                        .confirm_password
                                    }
                                    className={
                                      errors.confirm_password
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Konfirmo Passwordin"
                                    id="confirm_password"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>
                            {this.props.user.registration_data.password !== this.props.user.registration_data.confirm_password && (
                              <div className="mt-4 mb-4">
                                <label className={'form-label label_error'}>
                                  {password_dont_match_msg}
                                </label>
                              </div>
                            )}

                            {/* Telefoni */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.telefono
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Telefoni {' '}
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="ci:phone-outline"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="number"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          telefono: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          telefono: false,
                                        },
                                      }))
                                      setRegistrationValue('telefono', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.telefono
                                    }
                                    className={
                                      errors.telefono
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Telefoni"
                                    name="telefono"
                                    id="telefono"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              className="row"
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                              }}
                            >
                              {/*Facebook*/}
                              <div className="col-md-4">
                                <div className="mb-4">
                                  <label
                                    className={
                                      errors.facebookProfile
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Facebook{' '}
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                  <div className="form-icon position-relative">
                                    <PermIdentityIcon className="fea icon-sm custom_icon" />
                                    <input
                                      type="text"
                                      onChange={(event) => {
                                        const value = event.target.value
                                        this.setState((prevState) => ({
                                          ...prevState,
                                          registration_data: {
                                            ...prevState.registration_data,
                                            facebookProfile: value,
                                          },
                                          errors: {
                                            ...prevState.errors,
                                            facebookProfile: false,
                                          },
                                        }))
                                        setRegistrationValue(
                                          'facebookProfile',
                                          value
                                        )
                                      }}
                                      value={
                                        this.props.user.registration_data
                                          .facebookProfile
                                      }
                                      className={
                                        errors.facebookProfile
                                          ? 'form-control ps-5 input_error'
                                          : 'form-control ps-5'
                                      }
                                      placeholder="Profili Facebook"
                                      id="facebookProfile"
                                      name="Facebook"
                                      required=""
                                    />
                                    <a
                                      href={
                                        'https://www.facebook.com/' +
                                        this.props.user.registration_data
                                          .facebookProfile
                                      }
                                      target="_blank"
                                    >
                                      {
                                        <span
                                          className="mt-10"
                                          style={{
                                            color: '#bf1e2e',
                                            fontSize: '1rem',
                                          }}
                                        >
                                          {this.props.user.registration_data
                                            .facebookProfile &&
                                            'https://www.facebook.com/' +
                                              this.props.user.registration_data
                                                .facebookProfile}
                                        </span>
                                      }
                                    </a>
                                  </div>
                                </div>
                              </div>

                              {/*Twitter*/}
                              <div className="col-md-4">
                                <div className="mb-4">
                                  <label
                                    className={
                                      errors.twitterProfile
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Twitter{' '}
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                  <div className="form-icon position-relative">
                                    <PermIdentityIcon className="fea icon-sm custom_icon" />
                                    <input
                                      type="text"
                                      onChange={(event) => {
                                        const value = event.target.value
                                        this.setState((prevState) => ({
                                          ...prevState,
                                          registration_data: {
                                            ...prevState.registration_data,
                                            twitterProfile: value,
                                          },
                                          errors: {
                                            ...prevState.errors,
                                            twitterProfile: false,
                                          },
                                        }))
                                        setRegistrationValue(
                                          'twitterProfile',
                                          value
                                        )
                                      }}
                                      value={
                                        this.props.user.registration_data
                                          .twitterProfile
                                      }
                                      className={
                                        errors.twitterProfile
                                          ? 'form-control ps-5 input_error'
                                          : 'form-control ps-5'
                                      }
                                      placeholder=" Profili Twitter"
                                      id="twitterProfile"
                                      name="Twitter"
                                      required=""
                                    />
                                    <a
                                      href={
                                        'https://twitter.com/' +
                                        this.props.user.registration_data
                                          .twitterProfile
                                      }
                                      target="_blank"
                                    >
                                      {
                                        <span
                                          className="mt-10"
                                          style={{
                                            color: '#bf1e2e',
                                            fontSize: '1rem',
                                          }}
                                        >
                                          {this.props.user.registration_data
                                            .twitterProfile &&
                                            'https://twitter.com/' +
                                              this.props.user.registration_data
                                                .twitterProfile}
                                        </span>
                                      }
                                    </a>
                                  </div>
                                </div>
                              </div>

                              {/*Linkedin*/}
                              <div className="col-md-4">
                                <div className="mb-4">
                                  <label
                                    className={
                                      errors.linkedinProfile
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Linkedin{' '}
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                  <div className="form-icon position-relative">
                                    <PermIdentityIcon className="fea icon-sm custom_icon" />
                                    <input
                                      type="text"
                                      onChange={(event) => {
                                        const value = event.target.value
                                        this.setState((prevState) => ({
                                          ...prevState,
                                          registration_data: {
                                            ...prevState.registration_data,
                                            linkedinProfile: value,
                                          },
                                          errors: {
                                            ...prevState.errors,
                                            linkedinProfile: false,
                                          },
                                        }))
                                        setRegistrationValue(
                                          'linkedinProfile',
                                          value
                                        )
                                      }}
                                      value={
                                        this.props.user.registration_data
                                          .linkedinProfile
                                      }
                                      className={
                                        errors.linkedinProfile
                                          ? 'form-control ps-5 input_error'
                                          : 'form-control ps-5'
                                      }
                                      placeholder="Profili Linkedin"
                                      id="linkedinProfile"
                                      name="Linkedin"
                                      required=""
                                    />
                                    <a
                                      href={
                                        'https://www.linkedin.com/in/' +
                                        this.props.user.registration_data
                                          .linkedinProfile
                                      }
                                      target="_blank"
                                    >
                                      {
                                        <span
                                          className="mt-10"
                                          style={{
                                            color: '#bf1e2e',
                                            fontSize: '1rem',
                                          }}
                                        >
                                          {this.props.user.registration_data
                                            .linkedinProfile &&
                                            'https://www.linkedin.com/in/' +
                                              this.props.user.registration_data
                                                .linkedinProfile}
                                        </span>
                                      }
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/*/!*Fotoja e Profilit*!/*/}
                            {/*<div className="col-md-12">*/}
                            {/*    <div className="mb-4">*/}
                            {/*        <label*/}
                            {/*            className={*/}
                            {/*                errors.profilePicture*/}
                            {/*                    ? 'form-label label_error'*/}
                            {/*                    : 'form-label'*/}
                            {/*            }*/}
                            {/*            htmlFor="customFile"*/}
                            {/*        >*/}
                            {/*            Ngarko ketu foton e profilit:*/}
                            {/*            <span className="text-danger">*</span>*/}
                            {/*        </label>*/}
                            {/*        <input*/}
                            {/*            type="file"*/}
                            {/*            accept="image/png"*/}
                            {/*            className={*/}
                            {/*                errors.profilePicture*/}
                            {/*                    ? 'form-control input_error'*/}
                            {/*                    : 'form-control'*/}
                            {/*            }*/}
                            {/*            onChange={(event) => {*/}
                            {/*                const value = event.target.files[0]*/}
                            {/*                this.setState(*/}
                            {/*                    (prevState) => ({*/}
                            {/*                        ...prevState,*/}
                            {/*                        registration_data: {*/}
                            {/*                            ...prevState.registration_data,*/}
                            {/*                            profilePicture: value,*/}
                            {/*                        },*/}
                            {/*                        errors: {*/}
                            {/*                            ...prevState.errors,*/}
                            {/*                            profilePicture: false,*/}
                            {/*                        },*/}
                            {/*                    }),*/}
                            {/*                    () => {*/}
                            {/*                        uploadMedia(value)*/}
                            {/*                    }*/}
                            {/*                )*/}
                            {/*            }}*/}
                            {/*            id="customProfilePicture"*/}
                            {/*        />*/}
                            {/*        /!*<p className="text-danger"> Pranohen vetem imazhe .png me permasa 300x300 px </p>*!/*/}
                            {/*        <p className="text-danger"> Pranohen vetem imazhe .png</p>*/}
                            {/*    </div>*/}

                            {/*</div>*/}

                            {/*/!*CV*!/*/}
                            {/*<div className="col-md-12">*/}
                            {/*    <div className="mb-4">*/}
                            {/*        <label*/}
                            {/*            className={*/}
                            {/*                errors.cv*/}
                            {/*                    ? 'form-label label_error'*/}
                            {/*                    : 'form-label'*/}
                            {/*            }*/}
                            {/*            htmlFor="customFile"*/}
                            {/*        >*/}
                            {/*            Ngarko ketu CV:*/}
                            {/*            <span className="text-danger">*</span>*/}
                            {/*        </label>*/}
                            {/*        <input*/}
                            {/*            type="file"*/}
                            {/*            accept=".doc,.docx,.pdf"*/}
                            {/*            className={*/}
                            {/*                errors.cv*/}
                            {/*                    ? 'form-control input_error'*/}
                            {/*                    : 'form-control'*/}
                            {/*            }*/}
                            {/*            onChange={(event) => {*/}
                            {/*                const value = event.target.files[0]*/}
                            {/*                this.setState(*/}
                            {/*                    (prevState) => ({*/}
                            {/*                        ...prevState,*/}
                            {/*                        registration_data: {*/}
                            {/*                            ...prevState.registration_data,*/}
                            {/*                            cv: value,*/}
                            {/*                        },*/}
                            {/*                        errors: {*/}
                            {/*                            ...prevState.errors,*/}
                            {/*                            cv: false,*/}
                            {/*                        },*/}
                            {/*                    }),*/}
                            {/*                    () => {*/}
                            {/*                        uploadMedia(value)*/}
                            {/*                    }*/}
                            {/*                )*/}
                            {/*            }}*/}
                            {/*            id="cv"*/}
                            {/*        />*/}
                            {/*        /!*<p className="text-danger"> Pranohen vetem imazhe .png me permasa 300x300 px </p>*!/*/}
                            {/*        <p className="text-danger"> Pranohen vetem file .doc, .docx dhe .pdf</p>*/}
                            {/*    </div>*/}

                            {/*</div>*/}
                          </section>
                        ) : null}




                        {/*PROFIL EKSPERTI*/}
                        {this.props.user.registration_data.bussines_category === 'PE' || this.props.user.registration_data.bussines_category === 'PI' ? (
                          <section>

                            {/* Emri PE*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.name
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Emër <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-building-house"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="text"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          name: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          name: false,
                                        },
                                      }))
                                      setRegistrationValue('name', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.name
                                    }
                                    className={
                                      errors.name
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Emër"
                                    id="name"
                                    name="Emër"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Mbiemri  */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.lastName
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Mbiemer <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-building-house"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="text"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          lastName: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          lastName: false,
                                        },
                                      }))
                                      setRegistrationValue('lastName', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.lastName
                                    }
                                    className={
                                      errors.lastName
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Mbiemër"
                                    id="lastName"
                                    name="Mbiemër"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/*Gjinia PE*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.gender
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Gjinia <span className="text-danger">*</span>
                                </label>
                                <select
                                  className={
                                    errors.gender
                                      ? 'form-select form-control input_error'
                                      : 'form-select form-control'
                                  }
                                  placeholder="Gjinia"
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        gender: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        gender: false,
                                      },
                                    }))
                                    setRegistrationValue('gender', value)
                                  }}
                                  id="gender"
                                  value={
                                    this.props.user.registration_data.gender !==
                                    ''
                                      ? this.props.user.registration_data.gender
                                      : undefined
                                  }
                                  aria-label="Default select example"
                                  defaultValue={'DEFAULT_GENDER'}
                                >
                                  <option value="DEFAULT_GENDER" disabled>
                                    Gjinia ...
                                  </option>
                                  <option value="M">Mashkull</option>
                                  <option value="F">Femer</option>
                                </select>
                              </div>
                            </div>

                            {/*Birthday PE */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.birthday
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Datëlindje{' '}
                                  {/* <span className="text-danger">*</span> */}
                                </label>

                                <div>
                                  <label
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <DatePicker
                                      placeholderText="Datlindje e.x 20/12/1998"
                                      className={'form-control'}
                                      selected={selectedBirthday}
                                      // onSelect={(date) => this.handleStartDateSelect(date)}
                                      onChange={(date) => this.setBirthday(date)}

                                      // onChange={(event) => {
                                      //   const value = event.target.value
                                      //   this.setState((prevState) => ({
                                      //     ...prevState,
                                      //     registration_data: {
                                      //       ...prevState.registration_data,
                                      //       birthday: value,
                                      //     },
                                      //     errors: {
                                      //       ...prevState.errors,
                                      //       birthday: false,
                                      //     },
                                      //   }))
                                      //   setRegistrationValue('birthday', value)
                                      // }}

                                      // customInput={<CustomInput />}
                                      dateFormat="dd/MM/yyyy"
                                      todayButton="SOT"
                                    />
                                    {/* <span className="form-icon position-relative">
                                    <Icon
                                      icon="bx:bx-calendar"
                                      width="17"
                                      height="17"
                                      className="custom_icon"
                                    />
                                  </span> */}
                                  </label>
                                </div>
                              </div>
                            </div>

                            {/*/!*Birthplace PE *!/*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.birthplace
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Vëndlindje{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type={'text'}
                                  className={
                                    errors.birthplace
                                      ? 'form-control input_error'
                                      : 'form-control'
                                  }
                                  value={
                                    this.props.user.registration_data
                                      .birthplace !== ''
                                      ? this.props.user.registration_data
                                          .birthplace
                                      : undefined
                                  }
                                  // onKeyDown={renderProps.onEnterKeyDownConfirm}
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        birthplace: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        birthplace: false,
                                      },
                                    }))
                                    setRegistrationValue('birthplace', value)
                                  }}
                                  placeholder={'Vëndlindje'}
                                />
                              </div>
                            </div>

                            {/*Atesia PE*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.fatherName
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Atësia <span className="text-danger">*</span>
                                </label>
                                <input
                                  type={'text'}
                                  className={
                                    errors.fatherName
                                      ? 'form-control input_error'
                                      : 'form-control'
                                  }
                                  value={
                                    this.props.user.registration_data
                                      .fatherName !== ''
                                      ? this.props.user.registration_data
                                          .fatherName
                                      : undefined
                                  }
                                  // onKeyDown={renderProps.onEnterKeyDownConfirm}
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        fatherName: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        fatherName: false,
                                      },
                                    }))
                                    setRegistrationValue('fatherName', value)
                                  }}
                                  placeholder={'Atësi'}
                                />
                              </div>
                            </div>

                            {/*/!*Shtetesia PE*!/*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.nationality
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Shtetësi{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type={'text'}
                                  className={
                                    errors.nationality
                                      ? 'form-control input_error'
                                      : 'form-control'
                                  }
                                  value={
                                    this.props.user.registration_data
                                      .nationality !== ''
                                      ? this.props.user.registration_data
                                          .nationality
                                      : undefined
                                  }
                                  // onKeyDown={renderProps.onEnterKeyDownConfirm}
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        nationality: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        nationality: false,
                                      },
                                    }))
                                    setRegistrationValue('nationality', value)
                                  }}
                                  placeholder={'Shtetësi'}
                                />
                              </div>
                            </div>

                            {/*Adresa Banimit PE*/}
                            <div className="row">
                              <section>
                                <div className="mb-2">
                                  <label
                                    className={
                                      errors.address
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Adresë Banimi{' '}<span className="text-danger">*</span>
                                  </label>
                                </div>

                                <div className="row">
                                  {/* Rruga */}
                                  <div className="col-md-4">
                                    <div className="mb-4">
                                      {/* <label className={errors.address ? 'form-label label_error' : 'form-label'}>
                                                  Rruga/Nr.Shpisë
                                                </label> */}

                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bxs-map"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <input
                                          type="text"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                address_street: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                address_street: false,
                                              },
                                            }))
                                            setRegistrationValue(
                                              'address_street',
                                              value
                                            )
                                          }}
                                          value={
                                            this.props.user.registration_data.address_street
                                          }
                                          className={
                                            errors.address_street
                                              ? 'form-control ps-5 input_error'
                                              : 'form-control ps-5'
                                          }
                                          placeholder="Rruga / numri banesës"
                                          id="address_street"
                                          name="address_street"
                                          required=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* Kodi Postar */}
                                  <div className="col-md-4">
                                    <div className="mb-4">
                                      {/* <label className={errors.address ? 'form-label label_error' : 'form-label'}>
                                                  Kodi Postar
                                                </label> */}

                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bxs-map"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <input
                                          type="number"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                address_postal_code: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                address_postal_code: false,
                                              },
                                            }))
                                            setRegistrationValue('address_postal_code',value)
                                          }}
                                          value={
                                            this.props.user.registration_data.address_postal_code
                                          }
                                          className={
                                            errors.address_postal_code
                                              ? 'form-control ps-5 input_error'
                                              : 'form-control ps-5'
                                          }
                                          placeholder="Kodi postar"
                                          id="address_postal_code"
                                          name="address_postal_code"
                                          required=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* Qyteti */}
                                  <div className="col-md-4">
                                    <div className="mb-4">
                                      {/* <label className={errors.qyteti ? 'form-label label_error' : 'form-label'}>
                                                  Qyteti
                                                </label> */}

                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bxs-map"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <input
                                          type="text"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                address_qyteti: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                address_qyteti: false,
                                              },
                                            }))
                                            setRegistrationValue('address_qyteti',value)
                                          }}
                                          value={
                                            this.props.user.registration_data.address_qyteti
                                          }
                                          className={
                                            errors.address_qyteti
                                              ? 'form-control ps-5 input_error'
                                              : 'form-control ps-5'
                                          }
                                          placeholder="Qyteti"
                                          id="address_qyteti"
                                          name="address_qyteti"
                                          required=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>

                            {/*/!*Shtetesia Dyte PE*!/*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.secondNationality
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Shtetësi Tjeter
                                </label>
                                <input
                                  type={'text'}
                                  className={
                                    errors.secondNationality
                                      ? 'form-control input_error'
                                      : 'form-control'
                                  }
                                  value={
                                    this.props.user.registration_data
                                      .secondNationality !== ''
                                      ? this.props.user.registration_data
                                          .secondNationality
                                      : undefined
                                  }
                                  // onKeyDown={renderProps.onEnterKeyDownConfirm}
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        secondNationality: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        secondNationality: false,
                                      },
                                    }))
                                    setRegistrationValue(
                                      'secondNationality',
                                      value
                                    )
                                  }}
                                  placeholder={'Shtetësi Tjeter'}
                                />
                              </div>
                            </div>

                            {/*Origjina PE*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.resident_address
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Origjina{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  className={
                                    errors.origin
                                      ? 'form-select form-control input_error'
                                      : 'form-select form-control'
                                  }
                                  placeholder="Zhgjidh origjinen ..."
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        origin: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        origin: false,
                                      },
                                    }))
                                    setRegistrationValue('origin', value)
                                  }}
                                  id="origin"
                                  value={
                                    this.props.user.registration_data.origin !==
                                    ''
                                      ? this.props.user.registration_data.origin
                                      : undefined
                                  }
                                  aria-label="Default select example"
                                  defaultValue={'DEFAULT_ORIGIN'}
                                >
                                  <option value="DEFAULT_ORIGIN" disabled>
                                    {' '}
                                    {default_origin_value}
                                  </option>

                                  {origin_list.map((origin, index) => {
                                    return (
                                      <option
                                        key={`origin${index}.id`}
                                        value={origin.name}
                                      >
                                        {origin.name}
                                      </option>
                                    )
                                  })}
                                </select>
                              </div>
                            </div>

                            {/*Arsimi PE*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.education
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Arsimi <span className="text-danger">*</span>
                                </label>
                                <select
                                  className={
                                    errors.education
                                      ? 'form-select form-control input_error'
                                      : 'form-select form-control'
                                  }
                                  placeholder="Zhgjidh arsimin ..."
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        education: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        education: false,
                                      },
                                    }))
                                    setRegistrationValue('education', value)
                                  }}
                                  id="education"
                                  value={
                                    this.props.user.registration_data
                                      .education !== ''
                                      ? this.props.user.registration_data
                                          .education
                                      : undefined
                                  }
                                  aria-label="Default select example"
                                  defaultValue={'DEFAULT_ORIGIN'}
                                >
                                  <option value="DEFAULT_ORIGIN" disabled>
                                    {' '}
                                    {default_education_value}
                                  </option>

                                  {education_list.map((origin, index) => {
                                    return (
                                      <option
                                        key={`origin${index}.id`}
                                        value={origin.name}
                                      >
                                        {origin.name}
                                      </option>
                                    )
                                  })}
                                </select>
                              </div>
                            </div>

                            {/*Fusha Arsimit PE*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.educationField
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Fusha e Arsimit{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type={'text'}
                                  className={
                                    errors.educationField
                                      ? 'form-control input_error'
                                      : 'form-control'
                                  }
                                  value={
                                    this.props.user.registration_data
                                      .educationField !== ''
                                      ? this.props.user.registration_data
                                          .educationField
                                      : undefined
                                  }
                                  // onKeyDown={renderProps.onEnterKeyDownConfirm}
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        educationField: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        educationField: false,
                                      },
                                    }))
                                    setRegistrationValue(
                                      'educationField',
                                      value
                                    )
                                  }}
                                  placeholder={'Fusha e Arsimit'}
                                />
                              </div>
                            </div>

                            {/* Emaili PE*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.email
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  E-mail
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <PermIdentityIcon className="fea icon-sm custom_icon" />
                                  <input
                                    type="email"
                                    autoComplete="off"
                                    className={
                                      errors.email
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          email: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          email: false,
                                        },
                                      }))
                                      setRegistrationValue('email', value)
                                    }}
                                    placeholder="Email"
                                    name="email"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/*Paswordi PE*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.password
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Password
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="feather:key"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="password"
                                    autoComplete="new-password"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          password: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          password: false,
                                        },
                                      }))
                                      setRegistrationValue('password', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.password
                                    }
                                    className={
                                      errors.password
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Password"
                                    id="password"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/*Konfirmo Paswordi PE*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.confirm_password
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Konfirmo Passwordin
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="feather:key"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="password"
                                    autoComplete="new-password"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          confirm_password: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          confirm_password: false,
                                        },
                                      }))
                                      setRegistrationValue(
                                        'confirm_password',
                                        value
                                      )
                                    }}
                                    value={
                                      this.props.user.registration_data
                                        .confirm_password
                                    }
                                    className={
                                      errors.confirm_password
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Konfirmo Passwordin"
                                    id="confirm_password"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>
                            {this.props.user.registration_data.password !== this.props.user.registration_data.confirm_password && (
                              <div className="mt-4 mb-4">
                                <label className={'form-label label_error'}>
                                  {password_dont_match_msg}
                                </label>
                              </div>
                            )}

                            {/* Telefoni PE*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.telefono
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Telefoni
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="ci:phone-outline"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="number"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          telefono: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          telefono: false,
                                        },
                                      }))
                                      setRegistrationValue('telefono', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.telefono
                                    }
                                    className={
                                      errors.telefono
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Telefoni"
                                    name="telefono"
                                    id="telefono"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              className="row"
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                              }}
                            >
                              {/*Facebook*/}
                              <div className="col-md-4">
                                <div className="mb-4">
                                  <label
                                    className={
                                      errors.facebookProfile
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Facebook{' '}
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                  <div className="form-icon position-relative">
                                    <PermIdentityIcon className="fea icon-sm custom_icon" />
                                    <input
                                      type="text"
                                      onChange={(event) => {
                                        const value = event.target.value
                                        this.setState((prevState) => ({
                                          ...prevState,
                                          registration_data: {
                                            ...prevState.registration_data,
                                            facebookProfile: value,
                                          },
                                          errors: {
                                            ...prevState.errors,
                                            facebookProfile: false,
                                          },
                                        }))
                                        setRegistrationValue(
                                          'facebookProfile',
                                          value
                                        )
                                      }}
                                      value={
                                        this.props.user.registration_data
                                          .facebookProfile
                                      }
                                      className={
                                        errors.facebookProfile
                                          ? 'form-control ps-5 input_error'
                                          : 'form-control ps-5'
                                      }
                                      placeholder="Profili Facebook"
                                      id="facebookProfile"
                                      name="Facebook"
                                      required=""
                                    />
                                    <a
                                      href={
                                        'https://www.facebook.com/' +
                                        this.props.user.registration_data
                                          .facebookProfile
                                      }
                                      target="_blank"
                                    >
                                      {
                                        <span
                                          className="mt-10"
                                          style={{
                                            color: '#bf1e2e',
                                            fontSize: '1rem',
                                          }}
                                        >
                                          {this.props.user.registration_data
                                            .facebookProfile &&
                                            'https://www.facebook.com/' +
                                              this.props.user.registration_data
                                                .facebookProfile}
                                        </span>
                                      }
                                    </a>
                                  </div>
                                </div>
                              </div>

                              {/*Twitter*/}
                              <div className="col-md-4">
                                <div className="mb-4">
                                  <label
                                    className={
                                      errors.twitterProfile
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Twitter{' '}
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                  <div className="form-icon position-relative">
                                    <PermIdentityIcon className="fea icon-sm custom_icon" />
                                    <input
                                      type="text"
                                      onChange={(event) => {
                                        const value = event.target.value
                                        this.setState((prevState) => ({
                                          ...prevState,
                                          registration_data: {
                                            ...prevState.registration_data,
                                            twitterProfile: value,
                                          },
                                          errors: {
                                            ...prevState.errors,
                                            twitterProfile: false,
                                          },
                                        }))
                                        setRegistrationValue(
                                          'twitterProfile',
                                          value
                                        )
                                      }}
                                      value={
                                        this.props.user.registration_data
                                          .twitterProfile
                                      }
                                      className={
                                        errors.twitterProfile
                                          ? 'form-control ps-5 input_error'
                                          : 'form-control ps-5'
                                      }
                                      placeholder=" Profili Twitter"
                                      id="twitterProfile"
                                      name="Twitter"
                                      required=""
                                    />
                                    <a
                                      href={
                                        'https://twitter.com/' +
                                        this.props.user.registration_data
                                          .twitterProfile
                                      }
                                      target="_blank"
                                    >
                                      {
                                        <span
                                          className="mt-10"
                                          style={{
                                            color: '#bf1e2e',
                                            fontSize: '1rem',
                                          }}
                                        >
                                          {this.props.user.registration_data
                                            .twitterProfile &&
                                            'https://twitter.com/' +
                                              this.props.user.registration_data
                                                .twitterProfile}
                                        </span>
                                      }
                                    </a>
                                  </div>
                                </div>
                              </div>

                              {/*Linkedin*/}
                              <div className="col-md-4">
                                <div className="mb-4">
                                  <label
                                    className={
                                      errors.linkedinProfile
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Linkedin{' '}
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                  <div className="form-icon position-relative">
                                    <PermIdentityIcon className="fea icon-sm custom_icon" />
                                    <input
                                      type="text"
                                      onChange={(event) => {
                                        const value = event.target.value
                                        this.setState((prevState) => ({
                                          ...prevState,
                                          registration_data: {
                                            ...prevState.registration_data,
                                            linkedinProfile: value,
                                          },
                                          errors: {
                                            ...prevState.errors,
                                            linkedinProfile: false,
                                          },
                                        }))
                                        setRegistrationValue(
                                          'linkedinProfile',
                                          value
                                        )
                                      }}
                                      value={
                                        this.props.user.registration_data
                                          .linkedinProfile
                                      }
                                      className={
                                        errors.linkedinProfile
                                          ? 'form-control ps-5 input_error'
                                          : 'form-control ps-5'
                                      }
                                      placeholder="Profili Linkedin"
                                      id="linkedinProfile"
                                      name="Linkedin"
                                      required=""
                                    />
                                    <a
                                      href={
                                        'https://www.linkedin.com/in/' +
                                        this.props.user.registration_data
                                          .linkedinProfile
                                      }
                                      target="_blank"
                                    >
                                      {
                                        <span
                                          className="mt-10"
                                          style={{
                                            color: '#bf1e2e',
                                            fontSize: '1rem',
                                          }}
                                        >
                                          {this.props.user.registration_data
                                            .linkedinProfile &&
                                            'https://www.linkedin.com/in/' +
                                              this.props.user.registration_data
                                                .linkedinProfile}
                                        </span>
                                      }
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        ) : null}





                        {/*PROFIL OJP*/}
                        {this.props.user.registration_data.bussines_category === 'POJF' ? (
                          <section>

                            {/* Kodi Fiskal OJP */}
                            <div className="mb-4">
                              <label
                                className={
                                  errors.fiscal_code
                                    ? 'form-label label_error'
                                    : 'form-label'
                                }
                              >
                                {' '}
                                Kodi fiskal{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="form-icon position-relative">
                                <Icon
                                  icon="bx:bx-building-house"
                                  width="17"
                                  height="17"
                                  className="custom_icon"
                                />
                                <input
                                  type="text"
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        fiscal_code: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        fiscal_code: false,
                                      },
                                    }))
                                    setRegistrationValue('fiscal_code', value)
                                  }}
                                  value={
                                    this.props.user.registration_data
                                      .fiscal_code
                                  }
                                  className={
                                    errors.fiscal_code
                                      ? 'form-control ps-5 input_error'
                                      : 'form-control ps-5'
                                  }
                                  placeholder="Kodi fiskal"
                                  id="fiscal_code"
                                  name="Kodi fiskal"
                                  required=""
                                />
                              </div>
                            </div>

                            {/* Emri OJP  */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.name
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Emër <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-building-house"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="text"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          name: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          name: false,
                                        },
                                      }))
                                      setRegistrationValue('name', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.name
                                    }
                                    className={
                                      errors.name
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Emër"
                                    id="name"
                                    name="Emër"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Emri Tregtare OJP */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.bussines_name
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  {' '}
                                  Emri Tregtar{' '}
                                  <span className="text-danger">*</span>{' '}
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-building-house"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="text"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          bussines_name: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          bussines_name: false,
                                        },
                                      }))
                                      setRegistrationValue(
                                        'bussines_name',
                                        value
                                      )
                                    }}
                                    value={
                                      this.props.user.registration_data
                                        .bussines_name
                                    }
                                    className={
                                      errors.bussines_name
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Emri Tregtar"
                                    id="bussines_name"
                                    name="Emri Tregtar"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/*Logo*/}
                            {/*<div className="col-md-12">*/}
                            {/*    <div className="mb-4">*/}
                            {/*        <label*/}
                            {/*            className={*/}
                            {/*                errors.logo*/}
                            {/*                    ? 'form-label label_error'*/}
                            {/*                    : 'form-label'*/}
                            {/*            }*/}
                            {/*            htmlFor="customFile"*/}
                            {/*        >*/}
                            {/*            Ngarko ketu logon:*/}
                            {/*            <span className="text-danger">*</span>*/}
                            {/*        </label>*/}
                            {/*        <input*/}
                            {/*            type="file"*/}
                            {/*            accept="image/png"*/}
                            {/*            className={*/}
                            {/*                errors.logo*/}
                            {/*                    ? 'form-control input_error'*/}
                            {/*                    : 'form-control'*/}
                            {/*            }*/}
                            {/*            onChange={(event) => {*/}
                            {/*                const value = event.target.files[0]*/}
                            {/*                this.setState(*/}
                            {/*                    (prevState) => ({*/}
                            {/*                        ...prevState,*/}
                            {/*                        registration_data: {*/}
                            {/*                            ...prevState.registration_data,*/}
                            {/*                            logo: value,*/}
                            {/*                        },*/}
                            {/*                        errors: {*/}
                            {/*                            ...prevState.errors,*/}
                            {/*                            logo: false,*/}
                            {/*                        },*/}
                            {/*                    }),*/}
                            {/*                    () => {*/}
                            {/*                        uploadMedia(value)*/}
                            {/*                    }*/}
                            {/*                )*/}
                            {/*            }}*/}
                            {/*            id="logo"*/}
                            {/*        />*/}
                            {/*        <p className="text-danger"> Pranohen vetem imazhe .png</p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}


                            {/* Statusi Ligjor OJP */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.legal_status
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Statusi Ligjor{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-grid-alt"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.legal_status
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh statusin ligjor"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          legal_status: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          legal_status: false,
                                        },
                                      }))
                                      setRegistrationValue('legal_status', value)
                                      this.setState({otherLegalStatusValue: ''})
                                    }}
                                    id="legal_status"
                                    // value={
                                    //   this.props.user.registration_data.legal_status !== ''
                                    //     ? this.props.user.registration_data.legal_status
                                    //     : undefined
                                    // }
                                    aria-label="Default select example"
                                    defaultValue={'DEFAULT_LEGAL_STATUS'}
                                  >
                                    <option
                                      value="DEFAULT_LEGAL_STATUS"
                                      disabled
                                    >
                                      {default_legal_status_value}
                                    </option>

                                    {legal_status_list.map(
                                      (district, index) => {
                                        return (
                                          <option
                                            key={`district${index}.id`}
                                            value={district.name}
                                          >
                                            {district.name}
                                          </option>
                                        )
                                      }
                                    )}
                                  </select>
                                </div>
                              </div>

                              {/* Tjeter Specifiko */}
                              {this.props.user.registration_data
                                .legal_status === 'Tjetër' && (
                                <SweetAlert
                                  title={''}
                                  onConfirm={this.setLegalStatusValue}
                                  type={'controlled'}
                                  dependencies={[otherLegalStatusValue]}
                                >
                                  {(renderProps: SweetAlertRenderProps) => (
                                    <form>
                                      <input
                                        type={'text'}
                                        ref={renderProps.setAutoFocusInputRef}
                                        className="form-control"
                                        value={otherLegalStatusValue}
                                        onKeyDown={
                                          renderProps.onEnterKeyDownConfirm
                                        }
                                        onChange={(e) =>
                                          this.setState({
                                            otherLegalStatusValue:
                                              e.target.value,
                                          })
                                        }
                                        placeholder={
                                          'Specifiko statusin ligjor'
                                        }
                                      />
                                    </form>
                                  )}
                                </SweetAlert>
                              )}

                              {otherLegalStatusValue.length > 0 && (
                                <div className="mt-4 mb-4">
                                  <label className={'form-label'}>
                                    Statusi ligjor tjetër
                                  </label>
                                  <input
                                    type="text"
                                    value={otherLegalStatusValue}
                                    placeholder=""
                                    id="other_legal_status"
                                    name="other_legal_status"
                                    className={'form-control'}
                                    required=""
                                  />
                                </div>
                              )}
                            </div>

                            {/* Objekti OJP*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.area
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Objekti
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-grid-alt"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.area
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh objektin"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          area: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          area: false,
                                        },
                                      }))
                                      setRegistrationValue('area', value)
                                      this.setState({ otherAreaValue: '' })
                                    }}
                                    id="area"
                                    // value={
                                    //   this.props.user.registration_data.area !== ''
                                    //     ? this.props.user.registration_data.area
                                    //     : undefined
                                    // }
                                    aria-label="Default select example"
                                    defaultValue={'DEFAULT_OBJECT_VALUE'}
                                  >
                                    <option
                                      value="DEFAULT_OBJECT_VALUE"
                                      disabled
                                    >
                                      {' '}
                                      {default_legal_status_value}{' '}
                                    </option>

                                    {area_list.map((district, index) => {
                                      return (
                                        <option
                                          key={`district${index}.id`}
                                          value={district.name}
                                        >
                                          {district.name}
                                        </option>
                                      )
                                    })}
                                  </select>

                                  {/* Tjeter Specifiko */}
                                  {this.props.user.registration_data.area ===
                                    'Tjetër' && (
                                    <SweetAlert
                                      title={''}
                                      onConfirm={this.setAreaValue}
                                      type={'controlled'}
                                      dependencies={[this.state.otherAreaValue]}
                                    >
                                      {(renderProps: SweetAlertRenderProps) => (
                                        <form>
                                          <input
                                            type={'text'}
                                            ref={
                                              renderProps.setAutoFocusInputRef
                                            }
                                            className="form-control"
                                            value={this.state.otherAreaValue}
                                            onKeyDown={
                                              renderProps.onEnterKeyDownConfirm
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                otherAreaValue: e.target.value,
                                              })
                                            }
                                            placeholder={'Specifiko objektin'}
                                          />
                                        </form>
                                      )}
                                    </SweetAlert>
                                  )}

                                  {otherAreaValue.length > 0 && (
                                    <div className="mt-4 mb-4">
                                      <label className={'form-label'}>
                                        Objekti tjetër
                                      </label>
                                      <input
                                        type="text"
                                        value={otherAreaValue}
                                        className={'form-control'}
                                        placeholder="Specifiko objektin"
                                        id="other_district"
                                        name="other_district"
                                        required=""
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* Viti Themelimit   */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.year_of_establishment
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Viti i themelimit
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-grid-alt"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.year_of_establishment
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh vitin"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          year_of_establishment: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          year_of_establishment: false,
                                        },
                                      }))
                                      setRegistrationValue(
                                        'year_of_establishment',
                                        value
                                      )
                                    }}
                                    id="year_of_establishment"
                                    value={
                                      this.props.user.registration_data
                                        .year_of_establishment !== ''
                                        ? this.props.user.registration_data
                                            .year_of_establishment
                                        : undefined
                                    }
                                    aria-label="Default select example"
                                    defaultValue={
                                      'DEFAULT_YEAR_OF_ESTABLISHMENT'
                                    }
                                  >
                                    <option
                                      value="DEFAULT_YEAR_OF_ESTABLISHMENT"
                                      disabled
                                    >
                                      Zhgjidh vitin e themelimit ...
                                    </option>

                                    {this.years.map((year, index) => {
                                      return (
                                        <option
                                          key={`year${index}`}
                                          value={year}
                                        >
                                          {year}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>

                            {/* Pronësia OJP  */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.property
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Pronësia
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-grid-alt"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.property
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh llojin e pronësisë"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          property: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          property: false,
                                        },
                                      }))
                                      setRegistrationValue('property', value)
                                    }}
                                    id="property"
                                    // value={
                                    //   this.props.user.registration_data
                                    //     .property !== ''
                                    //     ? this.props.user.registration_data
                                    //         .property
                                    //     : undefined
                                    // }
                                    aria-label="Default select example"
                                    defaultValue={'DEFAULT_PROPERTY_VALUE'}
                                  >
                                    <option
                                      value="DEFAULT_PROPERTY_VALUE"
                                      disabled
                                    >
                                      Zhgjidh llojin e pronësisë ...
                                    </option>

                                    {/* <option value="Shqiptare">
                                              Shqiptare
                                            </option>

                                            <option value="E perbashket">
                                              E perbashket
                                            </option>

                                            <option value="Kosova">Kosova</option>

                                            <option value="Maqedonia e Veriut">
                                              Maqedonia e Veriut
                                            </option>

                                            <option value="Lugina e Presheves">
                                              Lugina e Presheves
                                            </option>

                                            <option value="Mali i Zi">
                                              Mali i Zi
                                            </option>

                                            <option value="Arberesh">
                                              Arberesh
                                            </option>

                                            <option value="shto_dhe_cileso">
                                              Shto edhe Cilëso
                                            </option> */}

                                    {property_list.map((district, index) => {
                                      return (
                                        <option
                                          key={`district${index}.id`}
                                          value={district.name}
                                        >
                                          {district.name}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>

                              {/* Tjeter Specifiko */}
                              {this.props.user.registration_data.property ===
                                'Shto edhe Cilëso' && (
                                <SweetAlert
                                  title={''}
                                  onConfirm={this.setPropertyValue}
                                  type={'controlled'}
                                  dependencies={[this.state.otherPropertyValue]}
                                >
                                  {(renderProps: SweetAlertRenderProps) => (
                                    <form>
                                      <input
                                        type={'text'}
                                        ref={renderProps.setAutoFocusInputRef}
                                        className="form-control"
                                        value={this.state.otherPropertyValue}
                                        onKeyDown={
                                          renderProps.onEnterKeyDownConfirm
                                        }
                                        onChange={(e) =>
                                          this.setState({
                                            otherPropertyValue: e.target.value,
                                          })
                                        }
                                        placeholder={
                                          'Specifiko llojin e pronësise...'
                                        }
                                      />
                                    </form>
                                  )}
                                </SweetAlert>
                              )}

                              {otherPropertyValue.length > 0 && (
                                <div className="mt-4 mb-4">
                                  <label className={'form-label'}>
                                    Pronësi tjetër
                                  </label>
                                  <input
                                    type="text"
                                    value={otherPropertyValue}
                                    className={'form-control'}
                                    placeholder=""
                                    id="other_property"
                                    name="other_property"
                                    required=""
                                  />
                                </div>
                              )}
                            </div>

                            {/* Emaili OJP */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.email
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  E-mail
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <PermIdentityIcon className="fea icon-sm custom_icon" />
                                  <input
                                    type="email"
                                    autoComplete="off"
                                    className={
                                      errors.email
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          email: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          email: false,
                                        },
                                      }))
                                      setRegistrationValue('email', value)
                                    }}
                                    placeholder="Email"
                                    name="email"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/*Paswordi OJP*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.password
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Password
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="feather:key"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="password"
                                    autoComplete="new-password"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          password: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          password: false,
                                        },
                                      }))
                                      setRegistrationValue('password', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.password
                                    }
                                    className={
                                      errors.password
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Password"
                                    id="password"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/*Konfirmo Paswordi OJP*/}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.confirm_password
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Konfirmo Passwordin
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="feather:key"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="password"
                                    autoComplete="new-password"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          confirm_password: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          confirm_password: false,
                                        },
                                      }))
                                      setRegistrationValue(
                                        'confirm_password',
                                        value
                                      )
                                    }}
                                    value={
                                      this.props.user.registration_data
                                        .confirm_password
                                    }
                                    className={
                                      errors.confirm_password
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Konfirmo Passwordin"
                                    id="confirm_password"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>
                            {this.props.user.registration_data.password !== this.props.user.registration_data.confirm_password && (
                              <div className="mt-4 mb-4">
                                <label className={'form-label label_error'}>
                                  {password_dont_match_msg}
                                </label>
                              </div>
                            )}

                            {/* Telefoni OJP */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.telefono
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Telefoni
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="ci:phone-outline"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="number"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          telefono: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          telefono: false,
                                        },
                                      }))
                                      setRegistrationValue('telefono', value)
                                    }}
                                    value={
                                      this.props.user.registration_data.telefono
                                    }
                                    className={
                                      errors.telefono
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Telefoni"
                                    name="telefono"
                                    id="telefono"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Adresa e Biznesit OJP */}
                            <div className="row">
                              <section>
                                <div className="mb-2">
                                  <label
                                    className={
                                      errors.address
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Adresë Banimi{' '}<span className="text-danger">*</span>
                                  </label>
                                </div>

                                <div className="row">
                                  {/* Rruga */}
                                  <div className="col-md-4">
                                    <div className="mb-4">
                                      {/* <label className={errors.address ? 'form-label label_error' : 'form-label'}>
                                                  Rruga/Nr.Shpisë
                                                </label> */}

                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bxs-map"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <input
                                          type="text"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                address_street: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                address_street: false,
                                              },
                                            }))
                                            setRegistrationValue(
                                              'address_street',
                                              value
                                            )
                                          }}
                                          value={
                                            this.props.user.registration_data.address_street
                                          }
                                          className={
                                            errors.address_street
                                              ? 'form-control ps-5 input_error'
                                              : 'form-control ps-5'
                                          }
                                          placeholder="Rruga / numri banesës"
                                          id="address_street"
                                          name="address_street"
                                          required=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* Kodi Postar */}
                                  <div className="col-md-4">
                                    <div className="mb-4">
                                      {/* <label className={errors.address ? 'form-label label_error' : 'form-label'}>
                                                  Kodi Postar
                                                </label> */}

                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bxs-map"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <input
                                          type="number"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                address_postal_code: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                address_postal_code: false,
                                              },
                                            }))
                                            setRegistrationValue('address_postal_code',value)
                                          }}
                                          value={
                                            this.props.user.registration_data.address_postal_code
                                          }
                                          className={
                                            errors.address_postal_code
                                              ? 'form-control ps-5 input_error'
                                              : 'form-control ps-5'
                                          }
                                          placeholder="Kodi postar"
                                          id="address_postal_code"
                                          name="address_postal_code"
                                          required=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* Qyteti */}
                                  <div className="col-md-4">
                                    <div className="mb-4">
                                      {/* <label className={errors.qyteti ? 'form-label label_error' : 'form-label'}>
                                                  Qyteti
                                                </label> */}

                                      <div className="form-icon position-relative">
                                        <Icon
                                          icon="bx:bxs-map"
                                          width="17"
                                          height="17"
                                          className="custom_icon"
                                        />
                                        <input
                                          type="text"
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              registration_data: {
                                                ...prevState.registration_data,
                                                address_qyteti: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                address_qyteti: false,
                                              },
                                            }))
                                            setRegistrationValue('address_qyteti',value)
                                          }}
                                          value={
                                            this.props.user.registration_data.address_qyteti
                                          }
                                          className={
                                            errors.address_qyteti
                                              ? 'form-control ps-5 input_error'
                                              : 'form-control ps-5'
                                          }
                                          placeholder="Qyteti"
                                          id="address_qyteti"
                                          name="address_qyteti"
                                          required=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>

                            {/* Qarku */}
                            {/* <div className="col-md-12">
                                <div className="mb-4">
                                  <label
                                    className={
                                      errors.district
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Qarku
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <Icon
                                      icon="bx:bx-grid-alt"
                                      width="17"
                                      height="17"
                                      className="custom_icon"
                                    />
                                    <select
                                      className={
                                        errors.district
                                          ? 'form-select form-control ps-5 input_error'
                                          : 'form-select form-control ps-5'
                                      }
                                      placeholder="Zhgjidh Qarkun"
                                      onChange={(event) => {
                                        const value = event.target.value
                                        this.setState((prevState) => ({
                                          ...prevState,
                                          registration_data: {
                                            ...prevState.registration_data,
                                            district: value,
                                          },
                                          errors: {
                                            ...prevState.errors,
                                            district: false,
                                          },
                                        }))
                                        setRegistrationValue(
                                          'district',
                                          value
                                        )
                                      }}
                                      id="district"
                                      value={
                                        this.props.user.registration_data
                                          .district !== ''
                                          ? this.props.user.registration_data
                                              .district
                                          : undefined
                                      }
                                      aria-label="Default select example"
                                      defaultValue={'DEFAULT_DISTRICT'}
                                    >
                                      <option
                                        value="DEFAULT_DISTRICT"
                                        disabled
                                      >
                                        {default_district_value}
                                      </option>

                                      {district_list.map(
                                        (district, index) => {
                                          return (
                                            <option
                                              key={`district${index}.id`}
                                              value={district.name}
                                            >
                                              {district.name}
                                            </option>
                                          )
                                        }
                                      )}
                                    </select>
                                  </div>
                                </div>
                              </div> */}

                            {/* Shteti OJP */}
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.country
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Shteti
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bxs-map"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.country
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh shtetin"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          country: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          country: false,
                                        },
                                      }))
                                      setRegistrationValue('country', value)
                                    }}
                                    id="country"
                                    value={
                                      this.props.user.registration_data
                                        .country !== ''
                                        ? this.props.user.registration_data
                                            .country
                                        : undefined
                                    }
                                    aria-label="Default select example"
                                    defaultValue={'DEFAULT_COUNTRY'}
                                  >
                                    <option value="DEFAULT_COUNTRY" disabled>
                                      {default_country_value}
                                    </option>

                                    {states_list.map((district, index) => {
                                      return (
                                        <option
                                          key={`district${index}.id`}
                                          value={district.name}
                                        >
                                          {district.name}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>

                            {/* Persona qe lidhen ne shoqeri?  OJP*/}
                            <div
                              className="col-md-12"
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div className="mb-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={(event) => {
                                      const value = event.target.checked
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        registration_data: {
                                          ...prevState.registration_data,
                                          people_connected_in_society: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          people_connected_in_society: false,
                                        },
                                      }))
                                      setRegistrationValue('people_connected_in_society',value)
                                    }}
                                    value={this.props.user.registration_data.people_connected_in_society}
                                    id="people_connected_in_society"
                                  />
                                  <label
                                    className={
                                      errors.people_connected_in_society
                                        ? 'form-check-label label_error'
                                        : 'form-check-label'
                                    }
                                    htmlFor="people_connected_in_society"
                                  >
                                    Persona të tjerë që lidhen në shoqëri?
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                              </div>

                              {this.props.user.registration_data.people_connected_in_society
                                ?
                                  (
                                    <div className="col-md-3">
                                      <div className="d-grid">
                                        <a className="btn btn-light border-button" onClick={() => {this.openModal()}}>{' '}Shto</a>
                                      </div>
                                    </div>
                                  )
                                : null
                              }
                            </div>

                            {this.props.user.registration_data.people_connected_in_society ? (
                              <div
                                className="col-md-12"
                                style={{
                                  marginTop: '3rem',
                                  marginBottom: '3rem',
                                }}
                              >
                                {/*<h5 className="card-title text-center text-uppercase" style={{ color: '#bf1e2e' }}>{' '}{shareholders_alert_form_data.shareholder_type}{' '}</h5>*/}

                                <div className="table-responsive bg-white shadow rounded">
                                  <table className="table mb-0 table-center invoice-tb">
                                    <thead className="bg-light">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Id
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Lloji lidhjes
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Emër
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Mbiemër
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Gjinia
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Datëlindje
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Vëndlindje
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Atësi
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Shtetësia
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Adresë Banimi
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Shtetësi e dytë
                                        </th>
                                        <th
                                          scope="col"
                                          className="border-bottom text-start"
                                        >
                                          Origjina
                                        </th>

                                        {/*<th scope="col" className="border-bottom text-start">Veprime</th>*/}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.props.user.registration_data?.shareholders?.map(
                                        (item, key) => {
                                          return (
                                            <tr key={key}>
                                              <td
                                                scope="row"
                                                className="text-start"
                                              >
                                                {key + 1}
                                              </td>
                                              <td
                                                scope="row"
                                                className="text-start"
                                              >
                                                {capitalize(
                                                  item.shareholder_type
                                                )}
                                              </td>
                                              <td
                                                scope="row"
                                                className="text-start"
                                              >
                                                {capitalize(item.firstName)}
                                              </td>
                                              <td className="text-start">
                                                {capitalize(item.lastName)}
                                              </td>
                                              <td className="text-start">
                                                {capitalize(item.gender)}
                                              </td>
                                              <td className="text-start">
                                                {item.birthday.toString()}
                                              </td>
                                              <td className="text-start">
                                                {capitalize(item.birthplace)}
                                              </td>
                                              <td className="text-start">
                                                {item.fatherName}
                                              </td>
                                              <td className="text-start">
                                                {item.nationality}
                                              </td>
                                              <td className="text-start">
                                                {item.resident_address}
                                              </td>
                                              <td className="text-start">
                                                {item.secondNationality}
                                              </td>
                                              <td className="text-start">
                                                {item.origin}
                                              </td>

                                              {/*<td className="text-center" style={{display: 'flex', justifyContent:'space-evenly'}}>*/}
                                              {/*    <i onClick={() => this.editShareholder(item.firstName)} className="uil uil-edit user_profile_text_green cursor_pointer"/>*/}
                                              {/*    <i onClick={(item) => this.deleteShareholder(item)} className="uil uil-trash user_profile_text_green cursor_pointer"/>*/}
                                              {/*</td>*/}
                                            </tr>
                                          )
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            ) : null}
                          </section>
                        ) : null}

                        <div className="col-md-12">
                          <div className="d-grid">
                            <button
                              className="btn btn-primary border-button"
                              // disabled={!errorOnForm}
                              onClick={(e) => this.initUserRegistration(e)}
                            >
                              {' '}
                              Rregjistrohu{' '}
                            </button>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="d-grid">
                            <p className="text-danger mt-3">
                              {' '}
                              * Fusha të detyrueshme{' '}
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>

                    {
                      showAlertProfilBiznesi
                          ? (
                              <Col xl={12} lg={12} sm={12} className="mb-2">
                                <SweetAlert
                                  title={''}
                                  showCancel
                                  cancelBtnBsStyle="light"
                                  onConfirm={() => {
                                    this.addShareholders()
                                  }}
                                  onCancel={() => {
                                    this.closeModal()
                                  }}
                                  type={'controlled'}
                                  dependencies={
                                    [
                                      // shareholders_alert_form_data.shareholder_type,
                                      // shareholders_alert_form_data.firstName,
                                      // shareholders_alert_form_data.lastName,
                                    ]
                                  }
                                >
                                  {(renderProps) => (
                                    <div>
                                      <h4 style={{ color: '#bf1e2e' }}>
                                        Persona të tjerë që lidhen në shoqëri
                                      </h4>
                                      {/*<h5 style={{color: '#bf1e2e'}}>{this.props.user.registration_data.persona_qe_lidhen_ne_shoqeri_type}</h5>*/}
                                      <hr />

                                      <select
                                        className={
                                          shareholders_errors.shareholder_type
                                            ? 'form-select form-control input_error'
                                            : 'form-select form-control'
                                        }
                                        placeholder="Tipi lidhjes me shoqerine"
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            shareholders_alert_form_data: {
                                              ...prevState.shareholders_alert_form_data,
                                              shareholder_type: value,
                                            },
                                            shareholders_errors: {
                                              ...prevState.shareholders_errors,
                                              shareholder_type: false,
                                            },
                                          }))
                                          setShareholderValue('shareholder_type', value)
                                        }}
                                        id="shareholder_type"
                                        value={
                                          shareholders_alert_form_data.shareholder_type !==
                                          ''
                                            ? shareholders_alert_form_data.shareholder_type
                                            : undefined
                                        }
                                        aria-label="Default select example"
                                        defaultValue={'DEFAULT_SHAREHOLDER_TYPE'}
                                      >
                                        <option
                                          value="DEFAULT_SHAREHOLDER_TYPE"
                                          disabled
                                        >
                                          Tipi lidhjes ...
                                        </option>
                                        <option value="Aksioner/Ortak">
                                          Aksioner/Ortak
                                        </option>
                                        <option value="Keshill Mbikqyres">
                                          Keshill Mbikqyres
                                        </option>
                                        <option value="Administrator">
                                          Administrator
                                        </option>
                                        {/*<option value="Tjeter">Tjeter</option>*/}
                                      </select>

                                      <br />

                                      <input
                                        type={'text'}
                                        className={
                                          shareholders_errors.firstName
                                            ? 'form-control input_error'
                                            : 'form-control'
                                        }
                                        value={
                                          shareholders_alert_form_data.firstName !== ''
                                            ? shareholders_alert_form_data.firstName
                                            : undefined
                                        }
                                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            shareholders_alert_form_data: {
                                              ...prevState.shareholders_alert_form_data,
                                              firstName: value,
                                            },
                                            shareholders_errors: {
                                              ...prevState.shareholders_errors,
                                              firstName: false,
                                            },
                                          }))
                                          setShareholderValue('firstName', value)
                                        }}
                                        placeholder={'Emër'}
                                      />

                                      <br />
                                      <input
                                        type={'text'}
                                        className={
                                          shareholders_errors.lastName
                                            ? 'form-control input_error'
                                            : 'form-control'
                                        }
                                        value={
                                          shareholders_alert_form_data.lastName !== ''
                                            ? shareholders_alert_form_data.lastName
                                            : undefined
                                        }
                                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            shareholders_alert_form_data: {
                                              ...prevState.shareholders_alert_form_data,
                                              lastName: value,
                                            },
                                            shareholders_errors: {
                                              ...prevState.shareholders_errors,
                                              lastName: false,
                                            },
                                          }))
                                          setShareholderValue('lastName', value)
                                        }}
                                        placeholder={'Mbiemër'}
                                      />
                                      <br />

                                      <select
                                        className={
                                          shareholders_errors.gender
                                            ? 'form-select form-control input_error'
                                            : 'form-select form-control'
                                        }
                                        placeholder="Gjinia"
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            shareholders_alert_form_data: {
                                              ...prevState.shareholders_alert_form_data,
                                              gender: value,
                                            },
                                            shareholders_errors: {
                                              ...prevState.shareholders_errors,
                                              gender: false,
                                            },
                                          }))
                                          setShareholderValue('gender', value)
                                        }}
                                        id="gender"
                                        value={
                                          shareholders_alert_form_data.gender !== ''
                                            ? shareholders_alert_form_data.gender
                                            : undefined
                                        }
                                        aria-label="Default select example"
                                        defaultValue={'DEFAULT_GENDER'}
                                      >
                                        <option value="DEFAULT_GENDER" disabled>
                                          Gjinia ...
                                        </option>
                                        <option value="M">Mashkull</option>
                                        <option value="F">Femer</option>
                                      </select>

                                      <br />

                                      {/*<div style={{display: 'flex'}}>*/}
                                      {/*    <DatePicker*/}
                                      {/*        className={shareholders_errors.birthday ? 'form-control input_error' : 'form-control'}*/}
                                      {/*        placeholderText={'Datlindje'}*/}
                                      {/*        dateFormat="dd/MM/yyyy"*/}
                                      {/*        selected={shareholders_alert_form_data.birthday}*/}

                                      {/*        value={shareholders_alert_form_data.birthday !== '' ? shareholders_alert_form_data.birthday : undefined}*/}
                                      {/*        onKeyDown={renderProps.onEnterKeyDownConfirm}*/}
                                      {/*        onChange={(date) => {*/}
                                      {/*            this.setState((prevState) => ({*/}
                                      {/*                ...prevState,*/}
                                      {/*                shareholders_alert_form_data: {*/}
                                      {/*                    ...prevState.shareholders_alert_form_data,*/}
                                      {/*                    birthday: date,*/}
                                      {/*                },*/}
                                      {/*                shareholders_errors: {*/}
                                      {/*                    ...prevState.shareholders_errors,*/}
                                      {/*                    birthday: false,*/}
                                      {/*                },*/}
                                      {/*            }))*/}
                                      {/*            setShareholderValue('birthday', date.toLocaleDateString('en-GB'))*/}
                                      {/*        }}*/}
                                      {/*    />*/}
                                      {/*</div>*/}

                                      <input
                                        type={'text'}
                                        className={
                                          shareholders_errors.birthday
                                            ? 'form-control input_error'
                                            : 'form-control'
                                        }
                                        value={shareholders_alert_form_data.birthday !== '' ? shareholders_alert_form_data.birthday : undefined}
                                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            shareholders_alert_form_data: {
                                              ...prevState.shareholders_alert_form_data,
                                              birthday: value,
                                            },
                                            shareholders_errors: {
                                              ...prevState.shareholders_errors,
                                              birthday: false,
                                            },
                                          }))
                                          setShareholderValue('birthday', value)
                                        }}
                                        placeholder={'Datlindje e.x 20/12/1998'}
                                      />

                                      {/* <div className="col-md-12 ">
                                          <div>
                                            <label style={{display: 'flex',flexDirection: 'row'}}>
                                              <DatePicker
                                                placeholderText="Datlindje e.x 20/12/1998"
                                                className={'form-control'}
                                                selected={shareholders_alert_form_data.birthday !== '' ? shareholders_alert_form_data.birthday : undefined}
                                                onChange={(date) => this.setShareholderBirthday(date)}
                                                dateFormat="dd/MM/yyyy"
                                                todayButton="SOT"
                                              />
                                            </label>
                                          </div>
                                      </div> */}

                                      <br />
                                      <input
                                        type={'text'}
                                        className={
                                          shareholders_errors.birthplace
                                            ? 'form-control input_error'
                                            : 'form-control'
                                        }
                                        value={
                                          shareholders_alert_form_data.birthplace !== ''
                                            ? shareholders_alert_form_data.birthplace
                                            : undefined
                                        }
                                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            shareholders_alert_form_data: {
                                              ...prevState.shareholders_alert_form_data,
                                              birthplace: value,
                                            },
                                            shareholders_errors: {
                                              ...prevState.shareholders_errors,
                                              birthplace: false,
                                            },
                                          }))
                                          setShareholderValue('birthplace', value)
                                        }}
                                        placeholder={'Vëndlindje'}
                                      />

                                      <br />
                                      <input
                                        type={'text'}
                                        className={
                                          shareholders_errors.fatherName
                                            ? 'form-control input_error'
                                            : 'form-control'
                                        }
                                        value={
                                          shareholders_alert_form_data.fatherName !== ''
                                            ? shareholders_alert_form_data.fatherName
                                            : undefined
                                        }
                                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            shareholders_alert_form_data: {
                                              ...prevState.shareholders_alert_form_data,
                                              fatherName: value,
                                            },
                                            shareholders_errors: {
                                              ...prevState.shareholders_errors,
                                              fatherName: false,
                                            },
                                          }))
                                          setShareholderValue('fatherName', value)
                                        }}
                                        placeholder={'Atësi'}
                                      />

                                      <br />
                                      <input
                                        type={'text'}
                                        className={
                                          shareholders_errors.nationality
                                            ? 'form-control input_error'
                                            : 'form-control'
                                        }
                                        value={
                                          shareholders_alert_form_data.nationality !==
                                          ''
                                            ? shareholders_alert_form_data.nationality
                                            : undefined
                                        }
                                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            shareholders_alert_form_data: {
                                              ...prevState.shareholders_alert_form_data,
                                              nationality: value,
                                            },
                                            shareholders_errors: {
                                              ...prevState.shareholders_errors,
                                              nationality: false,
                                            },
                                          }))
                                          setShareholderValue('nationality', value)
                                        }}
                                        placeholder={'Shtetësi'}
                                      />

                                      <br />

                                      <input
                                        type={'text'}
                                        className={
                                          shareholders_errors.resident_address
                                            ? 'form-control input_error'
                                            : 'form-control'
                                        }
                                        value={
                                          shareholders_alert_form_data.resident_address !==
                                          ''
                                            ? shareholders_alert_form_data.resident_address
                                            : undefined
                                        }
                                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            shareholders_alert_form_data: {
                                              ...prevState.shareholders_alert_form_data,
                                              resident_address: value,
                                            },
                                            shareholders_errors: {
                                              ...prevState.shareholders_errors,
                                              resident_address: false,
                                            },
                                          }))
                                          setShareholderValue('resident_address', value)
                                        }}
                                        placeholder={'Adrese banimi'}
                                      />

                                      <br />
                                      <input
                                        type={'text'}
                                        className={
                                          shareholders_errors.secondNationality
                                            ? 'form-control input_error'
                                            : 'form-control'
                                        }
                                        value={
                                          shareholders_alert_form_data.secondNationality !==
                                          ''
                                            ? shareholders_alert_form_data.secondNationality
                                            : undefined
                                        }
                                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            shareholders_alert_form_data: {
                                              ...prevState.shareholders_alert_form_data,
                                              secondNationality: value,
                                            },
                                            shareholders_errors: {
                                              ...prevState.shareholders_errors,
                                              secondNationality: false,
                                            },
                                          }))
                                          setShareholderValue(
                                            'secondNationality',
                                            value
                                          )
                                        }}
                                        placeholder={'Shtetësia dytë'}
                                      />

                                      <br />

                                      <select
                                        className={
                                          shareholders_errors.origin
                                            ? 'form-select form-control input_error'
                                            : 'form-select form-control'
                                        }
                                        placeholder="Zhgjidh origjinen ..."
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            shareholders_alert_form_data: {
                                              ...prevState.shareholders_alert_form_data,
                                              origin: value,
                                            },
                                            shareholders_errors: {
                                              ...prevState.shareholders_errors,
                                              origin: false,
                                            },
                                          }))
                                          setShareholderValue('origin', value)
                                        }}
                                        id="origin"
                                        value={
                                          shareholders_alert_form_data.origin !== ''
                                            ? shareholders_alert_form_data.origin
                                            : undefined
                                        }
                                        aria-label="Default select example"
                                        defaultValue={'DEFAULT_ORIGIN'}
                                      >
                                        <option value="DEFAULT_ORIGIN" disabled>
                                          {' '}
                                          {default_origin_value}
                                        </option>

                                        {origin_list.map((origin, index) => {
                                          return (
                                            <option
                                              key={`origin${index}.id`}
                                              value={origin.name}
                                            >
                                              {origin.name}
                                            </option>
                                          )
                                        })}
                                      </select>
                                    </div>
                                  )}
                                </SweetAlert>
                              </Col>
                            )
                          : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        code_error: state.user.code_error,
    }
}

const mapDispatchToProps = {
    //uploadNewUpdate,
    setShareholderValue,
    setRegistrationValue,
    registerNewUser,
    setErrorMapTrue,
    setErrorMapFalse,
    setErrorCodeFalse,
    //uploadMedia,
    getProfileCategories,
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)