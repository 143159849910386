import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

import './slogan.css'

class Slogan extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <React.Fragment>
                <div style={{position: 'relative', backgroundColor:'#bf1e2e', color: '#fff', fontSize: '1.1rem'}}>
                    <section className="slogan slogan-bar">
                        <div className="container text-center">
                            <div className="row align-items-center">
                                <div className="col-sm-12">
                                    <div className="text-sm-start mt-3" >
                                        <p className="mb-4">
                                            Made By Albanians është një markë krenarie e patriotizmit ekonomik.<br/>
                                            Të rritesh nën këtë emërtim do të thotë të identifikohesh me krenari mbi
                                            origjinën tënde dhe ta lidhësh suksesin tend nëpër botë me atdheun.<br/>
                                            E ku ka më bukur.
                                            Fol gjuhën e biznesit "SHQIP".<br/>
                                        </p>
                                    </div>
                                    {/*<div className="text-sm-start mt-3" style={{textAlign: 'justify'}}>*/}
                                    {/*    <p>Made By Albanians është një markë krenarie e patriotizmit ekonomik.</p>*/}
                                    {/*    <p> Të rritesh nën këtë emërtim do të thotë të identifikohesh me krenari mbi origjinën tënde dhe ta lidhësh suksesin tend nëpër botë me atdheun.</p>*/}
                                    {/*    <p> E ku ka më bukur.</p>*/}
                                    {/*    <p> Fol gjuhën e biznesit "SHQIP".</p>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/*<a onClick={() => this.scrollToTop()} id="back-to-top" className="btn btn-icon btn-primary border-button back-to-top" >*/}
                {/*    <ArrowUpwardIcon className="arrow"/>*/}
                {/*</a>*/}
            </React.Fragment>
        )
    }
}

export default withRouter(Slogan)
