import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import '../../assets/css/jquery-jvectormap-2.0.5.css';
import '../../assets/css/custom.css';
import {setRegistrationValue, setErrorMapFalse,setMapCodeSelected} from "../../store/user/actions";
import {map_codes, included_codes} from "../../assets/helpers/map_codes";
import excluded_codes from "../../assets/helpers/excluded_codes";

const jQuery = require('jquery');
const $ = jQuery;
window.jQuery = jQuery;
require('jvectormap');
require('../../assets/js/italy_vector_map');


class Map extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_code: props.user.map_code_selected,
            included_codes: [],
            map_codes: []
        };
    }


    componentDidMount() {
        this.setState((prevState) => ({
            ...prevState,
            included_codes: included_codes
        }));
        this.drawMap();
    }


    drawMap = () => {
        const self = this;
        const {setRegistrationValue,setErrorMapFalse,setMapCodeSelected} = self.props;
        $('#map').vectorMap({
            map: 'it_mill',
            regionStyle: {
                initial: {
                    fill: '#DEDBDB',
                    "fill-opacity": 1,
                    stroke: 'none',
                    "stroke-width": 0,
                    "stroke-opacity": 1,
                },
                hover: {
                    "fill-opacity": 0.9,
                    cursor: 'pointer',
                    fill: '#ff5722'
                },
                selected: {
                    fill: '#ff5722'
                },
            },
            regionsColors: {
                fill: 'red'
            },
            series: {
                regions: [{
                    attribute: "fill",
                    values: excluded_codes,
                }]
            },
            selectedRegions: self.state.selected_code,
            regionsSelectable: true,
            regionsSelectableOne: true,
            onRegionClick: function (event, code) {
                if (!self.state.included_codes.includes(code)) {
                    event.preventDefault();
                }
            },
            onRegionTipShow: function (event, tip, code) {
                if (!self.state.included_codes.includes(code)) {
                    return false;
                }
            },
            onRegionSelected: function (event, code, isSelected, selectedRegions) {
                self.setState((prevState) => ({
                    ...prevState,
                    selectedRegions: selectedRegions
                }))
                const value = map_codes.find(cod => cod.map_code === code).province;
                setRegistrationValue('provincia_terreno', value);
                setMapCodeSelected(code);
                setErrorMapFalse();

            }, zoomAnimate: false,
            zoomButtons: false,
            zoomOnScroll: false,
            backgroundColor: '#ffffff',
            onRegionOver: function (event, code) {
                if (!self.state.included_codes.includes(code)) {
                    return false;
                }
            }
        });
    }


    render() {
        const error = this.props.user.error_map;
        return (
            <div className={error ? "jvectormap-container input_error" : "jvectormap-container"}
                 style={{width: "700px", height: '400px'}}>
                <div id="map">

                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        user: state.user
    }
}


const mapDispatchToProps = {
    setRegistrationValue,
    setErrorMapFalse,
    setMapCodeSelected
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Map));
