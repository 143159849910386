import cookie from 'react-cookies';

import {
    LOGIN,
    LOGOUT,
    GET_CURRENT_USER,
    SET_CURRENT_VALUE,
    INIT_RECOVER_PASSWORD_PROCEDURE,
    CONFIRM_NEW_PASSWORD,
    SET_REDIRECT_TO_LOGIN_FALSE
} from './actionTypes';
import {toast} from "react-toastify";

function getTokenCookie() {
    if (cookie.load("token") === undefined) {
        return null;
    } else {
        return cookie.load("token");
    }
}

function setTokenCookie(token) {
    cookie.save("token", token, {
        "path": "/"
    });
}

const initialState = {
    authentication: {
        loading: false
    },
    token: getTokenCookie(),
    user: null,
    redirect_to_login: false,
    loading: false
};

export default (state = initialState, action) => {
    let redirect_to_login;
    let user_data;
    let loading;
    switch (action.type) {
        case SET_CURRENT_VALUE:
        user_data = Object.assign({}, state.user);
        console.log(state, user_data)
        user_data[action.payload.field] = action.payload.value;
        state = {
            ...state,
            user: user_data
        }
        console.log("current state", state)
        action.cb();
        console.log("current state1", state)
        break;
        case LOGIN:
            state = {
                ...state,
                authentication: {
                    ...state.authentication,
                    loading: true
                }
            };

            break;
        case LOGIN.concat("_SUCCESS"):

            console.log(action.payload);

            setTokenCookie(action.payload.access_token);

            state = {
                ...state,
                authentication: {
                    ...state.authentication,
                    loading: false,
                },
                token: action.payload.access_token
            };

            break;
        case LOGIN.concat("_FAILURE"):
            state = {
                ...state,
                authentication: {
                    ...state.authentication,
                    loading: false
                }
            };
            toast.error(action.payload.data.error, {position: 'bottom-center'});
            break;
        case LOGOUT:
            cookie.remove('token');
            state = {
                ...state,
                user: null,
                token: null
            };
            window.location.reload();
            break;
        case GET_CURRENT_USER:
            console.log("getCurrentUser call start api")
            loading = true;
            state = {
                ...state,
                loading: loading
            }
            break;

        case GET_CURRENT_USER.concat("_SUCCESS"):
            console.log("getCurrentUser call success")
            loading = false;

            console.log("GET_CURRENT_USER", action.payload);

            state = {
                ...state,
                user: action.payload,
                loading: loading
            };
            break;
        case GET_CURRENT_USER.concat("_FAILURE"):
            console.log("getCurrentUser call failure")
            loading = false;
            state = {
                ...state,
                loading: loading
            };
            cookie.remove('token');
            break;
        case INIT_RECOVER_PASSWORD_PROCEDURE.concat("_SUCCESS"):
            toast('Kontrolloni emailin tuaj dhe ndiqni udhëzimet', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                    color: '#bf1e2e'
                }
            });
            break;
        case INIT_RECOVER_PASSWORD_PROCEDURE.concat("_FAILURE"):
            toast.error(action.payload.data.error);
            break;
        case CONFIRM_NEW_PASSWORD.concat("_SUCCESS"):
            toast('Fjalëkalimi u ndryshua me sukses!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                    color: '#bf1e2e'
                }
            });
            redirect_to_login = state.redirect_to_login;
            redirect_to_login = true;
            state = {
                ...state,
                redirect_to_login: redirect_to_login
            }
            break;

        case CONFIRM_NEW_PASSWORD.concat("_FAILURE"):
            toast.error('Përditësimi i fjalëkalimit dështoi!');
            break;
        case SET_REDIRECT_TO_LOGIN_FALSE:
            redirect_to_login = state.redirect_to_login;
            redirect_to_login = false;
            state = {
                ...state,
                redirect_to_login: redirect_to_login
            }
            break;
        default:
            state = {...state};
            break;
    }

    return state;
};
