import {
    GET_CLIENT_DETAILS_FOR_PREVIEW,
    GET_DISTRIBUTOR_CLIENTS,
    CLEAR_CLIENT_DETAILS,
    DISABLE_CLIENT
} from "./actionTypes";
import {toast} from "react-toastify";


const initialState = {

    clients: {
        loading: false,
        data: []
    },
    client_detail: null
};

export default (state = initialState, action) => {
    let clients;
    let client_detail;
    switch (action.type) {
        case GET_DISTRIBUTOR_CLIENTS:
            clients = Object.assign({}, state.clients);
            clients.loading = true;
            state = {
                clients: clients
            }
            break;
        case GET_DISTRIBUTOR_CLIENTS.concat("_SUCCESS"):
            clients = Object.assign({}, state.clients);
            clients.loading = false;
            clients.data = action.payload;
            state = {
                clients: clients
            }
            break;
        case GET_DISTRIBUTOR_CLIENTS.concat("_FAILURE"):
            clients = Object.assign({}, state.clients);

            state = {
                ...state
            }
            toast.error('Impossibile ottenere clienti!');
            break;
        case GET_CLIENT_DETAILS_FOR_PREVIEW:
            client_detail = state.client_detail;
            client_detail = action.payload.client_data;
            state = {
                ...state,
                client_detail: client_detail
            }
            break;
        case CLEAR_CLIENT_DETAILS:
            client_detail = state.client_detail;
            client_detail = null;
            state = {
                ...state,
                client_detail: client_detail
            }
            break;
        case DISABLE_CLIENT.concat("_SUCCESS"):
            state = {
                ...state
            }
            toast.success('Cliente bloccato con successo!');
            break;

        default:
            state = {...state};
            break;
    }

    return state;
};