import cookie from "react-cookies";


const setToken = (token) => {
    cookie.save("data", token);
}

const capitalize = (string) => string && string[0].toUpperCase() + string.slice(1);

const removeNumbersFromString = (string) => {
    return string.replace(/[0-9]/g, '')
}

const clearApiBaseUrl = (string) => {
    return string.replace('\\home\\maximus\\addw_api\\', '');
}

const formatUpdateCreationDate = (date) => {
    const new_date = new Date(date);
    const year = new_date.getFullYear();
    const month = new_date.getMonth() + 1;
    const day = new_date.getDate();
    const hour = new_date.getHours();
    let minute = new_date.getMinutes();
    let seconds = new_date.getSeconds();

    if (minute < 10) {
        minute = '0' + minute
    }
    if (seconds < 10) {
        seconds = '0' + seconds
    }

    return day + '/' + month + '/' + year + '  ' + hour + ':' + minute + ':' + seconds;
}

const sorting = ['ragione_sociale', 'nome', 'cognome', 'via', 'citta_di_residenza', 'cap', 'provincia_di_residenza', 'email', 'telefono', 'partita_iva', 'password', 'provincia_terreno', 'varieta_coltivabile', 'tot_ha_coltivabile', 'num_ha_cereali_partecipa', 'codice_univoco', 'termini_e_condizioni'];

const max_file_size = 157286400;

export {
    capitalize,
    removeNumbersFromString,
    formatUpdateCreationDate,
    setToken,
    clearApiBaseUrl,
    sorting,
    max_file_size
}