import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import ReactPlayer from "react-player";
import {Button, Col} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import {Icon} from '@iconify/react';

import {
    clearClientDetails,
    disableClient,
    getClientDetailsForPreview,
    getDistributorClients
} from "../store/distributor/actions";

import {getAllBenchmarks, getPhotosAndVideosUpdates, setBenchmarkFilterValue} from "../store/common/actions";

import {
    capitalize,
    clearApiBaseUrl,
    formatUpdateCreationDate,
    removeNumbersFromString
} from "../assets/helpers/helpers";

import cereali_img from '../assets/images/icon/cereali.svg';
import axios from "axios";
import {toast} from "react-toastify";

class DistributorProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            project_open: true,
            clients_open: false,
            updates_open: false,
            client_details: false,
            filter: props.media.filter,
            show_disabled_client_alert: false,
            client_to_be_disabled_email: null
        };
    }

    componentDidMount() {
        const {getAllBenchmarks, media} = this.props;
        if (media.benchmarks.list.data.length === 0 && media.benchmarks.list.loading === false) {
            getAllBenchmarks();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.updates_open !== this.state.updates_open) {
            const {setBenchmarkFilterValue, getPhotosAndVideosUpdates, media} = this.props;
            if (media.filter.benchmark === '') {
                setBenchmarkFilterValue('benchmark', media.benchmarks.list.data[0]?.campo_benchmark);
                getPhotosAndVideosUpdates();
            }
        }
    }


    handleToggle = (type) => {
        const {clearClientDetails} = this.props;
        const client_detail = this.props.distributor.client_detail;
        if (client_detail !== null) {
            clearClientDetails();
        }

        if (type === 'project') {
            this.setState({
                project_open: true,
                clients_open: false,
                updates_open: false,
                client_details: false
            })
        }

        if (type === 'client') {
            this.setState({
                project_open: false,
                clients_open: true,
                updates_open: false,
                client_details: false
            })
        }

        if (type === 'updates') {
            this.setState({
                project_open: false,
                clients_open: false,
                updates_open: true,
                client_details: false
            })
        }
    }


    initGetClients = () => {
        const {getDistributorClients} = this.props;
        this.handleToggle('client');
        if (this.props.distributor.clients.data.length === 0) {
            getDistributorClients();
        }
    }


    getClientDetail = (client) => {
        const {getClientDetailsForPreview} = this.props;
        this.setState({
            client_details: true
        });
        getClientDetailsForPreview(client);
    }

    confirmDisableClient = () => {
        const {client_to_be_disabled_email} = this.state;
        const {disableClient, clearClientDetails} = this.props;
        disableClient(client_to_be_disabled_email);
        clearClientDetails();
        this.setState((prevState) => ({
            ...prevState,
            show_disabled_client_alert: !prevState.show_disabled_client_alert,
            client_details: !prevState.client_details
        }))
    }

    handleDownload = () => {
        const url = process.env.REACT_APP_API_HOST.concat('/user/getPdfCard');
        const code = this.props.currentUser?.codice_univoco;
        axios.get(url, {params: {codice_univoco_distributore: code}, responseType: 'blob'})
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data], {type: "application/pdf"}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${code}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);

            })
            .catch((error) => {
                toast.error("Nessuna card digitale trovata.");
            });
    }


    render() {

        const {project_open, clients_open, updates_open, client_details, show_disabled_client_alert} = this.state;
        const {currentUser, auth, setBenchmarkFilterValue, getPhotosAndVideosUpdates} = this.props;
        const clients = this.props.distributor.clients.data;
        const client_detail = this.props.distributor.client_detail;
        const benchmarks = this.props.media.benchmarks.list.data;
        const medias = this.props.media.content.data;
        const imgBaseUrl = process.env.REACT_APP_API_HOST;

        if (auth.token === null) {
            return <Redirect to='/login'/>
        }

        return (
            <React.Fragment>
                <section>
                    <div className="container">
                        <nav aria-label="breadcrumb" className="d-inline-block">
                            <ul className="breadcrumb mb-0">
                                <li className="breadcrumb-item" style={{marginRight: '5px'}}><a
                                    onClick={() => this.props.history.push('/')}>Home</a>
                                </li>
                                <li className={project_open ? "breadcrumb-item active" : "breadcrumb-item"}><a
                                    onClick={() => this.handleToggle('project')}>{removeNumbersFromString(currentUser?.ragione_sociale)}</a>
                                </li>
                                {(clients_open) &&
                                <li className={(clients_open && client_detail === null) ? "breadcrumb-item active" : "breadcrumb-item"}>
                                    <a
                                        onClick={() => this.initGetClients()}>I miei clienti</a>
                                </li>
                                }
                                {updates_open &&
                                <li className={updates_open ? "breadcrumb-item active" : "breadcrumb-item"}><a
                                    onClick={() => this.handleToggle('updates')}>Campi benchmark</a>
                                </li>
                                }
                                {(client_details && client_detail !== null) &&
                                <li className="breadcrumb-item active">
                                    <a>{client_detail?.ragione_sociale} </a></li>
                                }
                            </ul>
                        </nav>
                    </div>
                </section>
                <section className="d-table w-100 mt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt-3 mb-3">
                                <div className="card public-profile border-0 rounded shadow">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-lg-2 text-center profile-icon">
                                                <i className="uil uil-user-circle"/>
                                            </div>

                                            <div className="col-lg-10 col-md-9">
                                                <div className="row align-items-end">
                                                    <div className="col-md-7 text-md-start text-center mt-4 mt-sm-0">
                                                        <h4 className="title mb-0 text-black">{removeNumbersFromString(currentUser?.ragione_sociale)}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 d-lg-block">
                                <div className="sidebar sticky-bar p-4 rounded shadow">
                                    <div className="widget">
                                        <ul className="list-unstyled sidebar-nav mb-0" id="navmenu-nav">
                                            <li className={project_open ? "navbar-item account-menu px-0 active" : "navbar-item account-menu px-0"}>
                                                <a className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer"
                                                   onClick={() => this.handleToggle('project')}
                                                >
                                                    <span className="h4 mb-0"><i
                                                        className="uil uil-building"/></span>
                                                    <h6 className="mb-0 ms-2 custom_font">{removeNumbersFromString(currentUser?.ragione_sociale)}</h6>
                                                </a>
                                            </li>
                                            <li className={clients_open ? "navbar-item account-menu px-0 mt-2 active" : "navbar-item account-menu px-0 mt-2"}>
                                                <a className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer"
                                                   onClick={() => this.initGetClients()}
                                                >
                                                    <span className="h4 mb-0"><i
                                                        className="uil uil-users-alt"/></span>
                                                    <h6 className="mb-0 ms-2 custom_font">I miei clienti</h6>
                                                </a>
                                            </li>
                                            <li className={updates_open ? "navbar-item account-menu px-0 mt-2 active " : "navbar-item account-menu px-0 mt-2"}>
                                                <a className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer"
                                                   onClick={() => this.handleToggle('updates')}
                                                >
                                                    <span className="h4 mb-0"><i className="uil uil-bell"/></span>
                                                    <h6 className="mb-0 ms-2 custom_font">Campi benchmark</h6>
                                                </a>
                                            </li>

                                            <li className="navbar-item account-menu px-0 mt-2">
                                                <a onClick={() => this.handleDownload()}
                                                   className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer">
                                                    <span className="h4 mb-0"><i className="uil uil-import"/></span>
                                                    <h6 className="mb-0 ms-2 custom_font">Download Card Digitale</h6>
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {project_open ?
                                <div className="col-lg-8 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-12 mt-4">
                                                <h5 className='custom_font'>Dati personali:</h5>
                                                <div className="mt-4">
                                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <i className="uil uil-building text-muted me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Ragione
                                                                sociale:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.ragione_sociale}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="feather:divide-circle"
                                                              className="fea icon-ex-md text-muted me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Partita
                                                                IVA:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.iva}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="feather:map-pin"
                                                              className="fea icon-ex-md text-muted me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Via:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.via}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="feather:map-pin"
                                                              className="fea icon-ex-md text-muted me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Città:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.citta}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="feather:map-pin"
                                                              className="fea icon-ex-md text-muted me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Cap:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.cap}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-2 pb-2">
                                                        <Icon icon="feather:map-pin"
                                                              className="fea icon-ex-md text-muted me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Provincia:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.provincia}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-2 pb-2">
                                                        <Icon icon="bi:code"
                                                              className="fea icon-ex-md text-muted me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Codice
                                                                Univoco:</h6>
                                                            <p className="text-muted mb-0">
                                                                {currentUser?.codice_univoco}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : clients_open ? <> {
                                        client_details ?
                                            <div className="col-lg-8 col-12">
                                                <div>
                                                    <div className="row">
                                                        <div className="col-md-12 mt-4">
                                                            <h5>Dati personali:</h5>
                                                            <div className="mt-4">
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="bx:bx-user" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"/>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Nome:</h6>
                                                                        <p className="text-muted mb-0">{capitalize(client_detail?.nome)}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="bx:bx-user" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"/>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Cognome:</h6>
                                                                        <p className="text-muted mb-0">{capitalize(client_detail?.cognome)}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <i className="uil uil-building text-muted me-3"/>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Ragione
                                                                            sociale:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.ragione_sociale}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="feather:map-pin" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"
                                                                    />
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Via:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.via}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="feather:map-pin" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"
                                                                    />
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Città:</h6>
                                                                        <p className="text-muted mb-0">{capitalize(client_detail?.citta_di_residenza)}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="feather:map-pin" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"
                                                                    />
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Cap:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.cap}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="feather:map-pin" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"
                                                                    />
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Provincia:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.provincia_di_residenza}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="ic:outline-email" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"
                                                                    />
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Email:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.email}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="clarity:phone-handset-line" width="17"
                                                                          height="17"
                                                                          className="fea icon-ex-md text-muted me-3"
                                                                    />
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Telefono:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.telefono}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="lucide:divide-circle" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"
                                                                    />
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Partita
                                                                            IVA:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.partita_iva}</p>
                                                                    </div>
                                                                </div>
                                                                <h5 className='mb-4 mt-4'>Dati campo Revy+:</h5>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="feather:map-pin" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"/>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Zona -
                                                                            Provincia</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.zona} - {client_detail?.provincia}</p>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="feather:map-pin" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"/>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Il mio
                                                                            campo Benchmark</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.campo_benchmark}</p>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="feather:divide-circle"
                                                                          className="fea icon-ex-md me-3"/>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Varietà
                                                                            coltivabile:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.varieta_coltivabile}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="carbon:layers"
                                                                          className="fea icon-ex-md me-3"/>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Numero
                                                                            totale di ettari di superficie coltivabile:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.tot_ha_coltivabile}</p>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <i className="fea icon-ex-md me-3"><img
                                                                        src={cereali_img}
                                                                        className="img-fluid mt-n2" alt="cereali"/></i>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Numero
                                                                            ettari di cereali partecipanti
                                                                            all'iniziativa:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.num_ha_cereali_partecipa}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <Icon icon="bi:code"
                                                                          className="fea icon-ex-md me-3"/>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Codice
                                                                            fornito al momento dell'acquisto:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.codice_univoco}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-center mt-5'>
                                                        <Button size='lg'
                                                                onClick={() => this.setState((prevState) => ({
                                                                    ...prevState,
                                                                    show_disabled_client_alert: !prevState.show_disabled_client_alert,
                                                                    client_to_be_disabled_email: client_detail.email
                                                                }))}
                                                                color='danger'>
                                                            Blocca Utente
                                                        </Button>
                                                    </div>

                                                </div>
                                            </div>
                                            : <div className="col-lg-8 col-12 mb-5">
                                                <div className="pb-4">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-4">
                                                            <h5 className='custom_font'>I miei clienti:</h5>
                                                            <div className="table-responsive bg-white shadow rounded">
                                                                <table className="table mb-0 table-center invoice-tb">
                                                                    <thead className="bg-light">
                                                                    <tr>
                                                                        <th scope="col"
                                                                            className="border-bottom text-start">Nome
                                                                        </th>
                                                                        <th scope="col"
                                                                            className="border-bottom text-start">Cognome
                                                                        </th>
                                                                        <th scope="col"
                                                                            className="border-bottom text-start">Città
                                                                        </th>
                                                                        <th scope="col"
                                                                            className="border-bottom text-start">Provincia
                                                                        </th>
                                                                        <th scope="col"
                                                                            className="border-bottom text-start">Email
                                                                        </th>
                                                                        <th scope="col"
                                                                            className="border-bottom text-start">Telefono
                                                                        </th>
                                                                        <th scope="col"
                                                                            className="border-bottom text-center">Azioni
                                                                        </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {clients.map((client, key) => {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td scope="row"
                                                                                    className="text-start">{capitalize(client.nome)}</td>
                                                                                <td className="text-start">{capitalize(client.cognome)}</td>
                                                                                <td className="text-start">{capitalize(client.citta_di_residenza)}</td>
                                                                                <td className="text-start">{capitalize(client.provincia_di_residenza)}</td>
                                                                                <td className="text-start">{client.email}</td>
                                                                                <td className="text-start">{client.telefono}</td>
                                                                                <td className="text-center"><i
                                                                                    onClick={() => this.getClientDetail(client)}
                                                                                    className="uil uil-eye user_profile_text_green cursor_pointer"/>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }</>

                                    : <div className="col-lg-8 col-12">
                                        <div className="pb-4">
                                            <div className="row">
                                                <div className="col-md-12 mt-4">
                                                    <h5 className='custom_font'>Campi benchmark:</h5>
                                                    <div className="row">
                                                        <div className="col-md-12 mt-3">
                                                            <div className="mb-3">
                                                                <label className="form-label">Filtra per
                                                                    benchmark</label>
                                                                <div className="form-icon position-relative">
                                                                    <Icon icon="bx:bx-building-house"
                                                                          className="fea icon-sm icons"/>
                                                                    <select
                                                                        onChange={(event) => {
                                                                            const value = event.target.value
                                                                            this.setState((prevState) => ({
                                                                                    ...prevState,
                                                                                    filter: {
                                                                                        benchmark: value
                                                                                    }
                                                                                }), () => {
                                                                                    setBenchmarkFilterValue('benchmark', value);
                                                                                    getPhotosAndVideosUpdates();
                                                                                }
                                                                            )
                                                                        }}
                                                                        value={this.props.media.filter.benchmark}
                                                                        className="form-select form-control ps-5"
                                                                        aria-label="Default select example">
                                                                        {benchmarks.map((benchmark, key) => {
                                                                            return (
                                                                                <option key={key}
                                                                                        value={benchmark.campo_benchmark}>{benchmark.campo_benchmark}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {medias.length === 0 ?
                                                            <div className='text-center custom_font'>Non sono
                                                                presenti
                                                                aggiornamenti</div> :
                                                            <>
                                                                {medias.map((media, key) => {
                                                                    return (
                                                                        <div key={key}
                                                                             className="col-md-6 mt-4 pt-2">
                                                                            <div
                                                                                className='card rounded border-0 shadow'>
                                                                                <div className="position-relative">
                                                                                    {media.file_type.includes('image') ?
                                                                                        <img
                                                                                            src={imgBaseUrl.concat(clearApiBaseUrl(media.path))}
                                                                                            className="card-img-top rounded-top"
                                                                                            alt="..."/>
                                                                                        :
                                                                                        <ReactPlayer
                                                                                            controls={true}
                                                                                            width={"fit-content"}
                                                                                            height={"fit-content"}
                                                                                            volume={0.5}
                                                                                            config={{
                                                                                                file: {
                                                                                                    attributes: {
                                                                                                        controlsList: 'nodownload',
                                                                                                        disablepictureinpicture: 'true',
                                                                                                        disableremoteplayback: 'true'
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                            url={imgBaseUrl.concat(clearApiBaseUrl(media.path))}/>
                                                                                    }
                                                                                </div>
                                                                                <div className="card-body content">
                                                                                    <h6 className='text-muted text-left creation-date'>{formatUpdateCreationDate(media.data_creazione)}</h6>
                                                                                    <h5 className='text-left'><a
                                                                                        className="updates-title">{media.titolo}</a>
                                                                                    </h5>
                                                                                    <h6 className='text-muted text-left'>{media.descrizione}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </section>

                {show_disabled_client_alert ?
                    <Col xl={4} lg={6} sm={8} className="mb-2">
                        <SweetAlert
                            title="Sei sicuro di voler bloccare l'utente?"
                            warning
                            showCancel
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="#bf1e2e"
                            confirmBtnText='Sì'
                            cancelBtnText='Anulla'
                            cancelBtnStyle={{padding: '7px 12px', backgroundColor: "#dd613b", color: "white"}}
                            confirmBtnStyle={{padding: '7px 12px', width: '70px'}}
                            onConfirm={() => this.confirmDisableClient()}
                            onCancel={() =>
                                this.setState((prevState) => ({
                                    ...prevState,
                                    show_disabled_client_alert: !prevState.show_disabled_client_alert,
                                    client_to_be_disabled_email: null
                                }))}
                        />
                        )
                    </Col> : null}
            </React.Fragment>
        )
    }

}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.user,
        auth: state.auth,
        distributor: state.distributor,
        media: state.media
    }
}


const mapDispatchToProps = {
    getDistributorClients,
    getClientDetailsForPreview,
    clearClientDetails,
    getAllBenchmarks,
    setBenchmarkFilterValue,
    getPhotosAndVideosUpdates,
    disableClient
}


export default connect(mapStateToProps, mapDispatchToProps)(DistributorProfile);