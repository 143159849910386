import {
    GET_BASF_DISTRIBUTORS,
    GET_DISTRIBUTORS_COMPETENCE_AREAS,
    GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR,
    GET_BASF_BUSSINESS_LIST,
    SET_BASF_SELECT_VALUE,
    GET_CLIENT_DETAILS_FOR_BASF_PREVIEW,
    CLEAR_CLIENT_DETAILS,
    CLEAR_CLIENTS,
    CLEAR_DISTRIBUTORS,
    SET_NEW_UPDATE_VALUE,
    UPLOAD_NEW_UPDATE,
    UPLOAD_MEDIA,
    UPDATE_PROFILE,
    GET_DISTRIBUTOR_NAME,
    CLEAR_FORM,
    CLEAR_BUSSINES_LIST,
} from "./actionTypes";
import {toast} from "react-toastify";


const initialState = {
    distributors: {
        list: {
            data: [],
            loading: false
        }
    },
    bussines_list:{
        list: {
            data: [],
            loading: false
        }
    },
    clients: {
        list: {
            data: [],
            loading: false
        }
    },
    areas: {
        list: {
            data: [],
            loading: false
        }
    },
    params: {
        area_id: '',
        distributor_id: '',
        user_id: '',
        bussines_category: '',
    },
    new_update: {
        benchmark: '',
        title: '',
        description: '',
        file: null
    },
    client_detail: null,
    distributor_name: "",
    upload_loading: false,
    code_error: false,
};

export default (state = initialState, action) => {
    let distributors;
    let clients;
    let bussines_list;
    let areas;
    let params;
    let new_update;
    let client_detail;
    let category;
    let distributor_name;
    let upload_loading;
    let code_error;

    switch (action.type) {
        case GET_DISTRIBUTORS_COMPETENCE_AREAS:
            areas = Object.assign({}, state.areas);
            areas.list.loading = true;
            state = {
                ...state,
                areas: areas
            }
            break;
        case GET_DISTRIBUTORS_COMPETENCE_AREAS.concat("_SUCCESS"):
            areas = Object.assign({}, state.areas);
            areas.list.loading = false;
            areas.list.data = action.payload;
            state = {
                ...state,
                areas: areas
            }
            break;
        case GET_DISTRIBUTORS_COMPETENCE_AREAS.concat("_FAILURE"):
            areas = Object.assign({}, state.areas);
            areas.list.loading = false;
            areas.list.data = [];
            state = {
                ...state,
                areas: areas
            }
            break;

        case UPDATE_PROFILE.concat("_SUCCESS"):
            toast.success('Të dhënat e profilit u ruajtën me sukses!');
            //setTokenCookie(action.payload.token);
            //redirect_to_home = state.redirect_to_home;
            //action.cb();
            console.log("before callback", action)
            // console.log("before callback", action.num)
            // console.log("before callback", action.cb)
            console.log("UPDATE_CURRENT_USER before", state);
            action.requestAction.cb();
            console.log("after callback", action)
            console.log("UPDATE_CURRENT_USER", state);
            break;
        case UPDATE_PROFILE.concat("_FAILURE"):
            toast.error("Ka ndodhur nje error. Te dhenat e profilit nuk u ruajten me sukses!");
            code_error = state.code_error;

            if (action.payload.data.errorCode === "999") {
                code_error = true;
            }
            state = {
                ...state,
                code_error: code_error
            }
            break;
        case GET_BASF_DISTRIBUTORS:
            distributors = Object.assign({}, state.distributors);
            distributors.list.loading = true;
            state = {
                ...state,
                distributors: distributors
            }
            break;
        case GET_BASF_DISTRIBUTORS.concat("_SUCCESS"):
            distributors = Object.assign({}, state.distributors);
            distributors.list.loading = false;
            distributors.list.data = action.payload;
            state = {
                ...state,
                distributors: distributors
            }
            break;
        case GET_BASF_DISTRIBUTORS.concat("_FAILURE"):
            distributors = Object.assign({}, state.distributors);
            distributors.list.loading = false;
            distributors.list.data = [];
            state = {
                ...state,
                distributors: distributors
            }
            break;
        case GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR:
            clients = Object.assign({}, state.clients);
            clients.list.loading = true;
            state = {
                ...state,
                clients: clients
            }
            break;
        case GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR.concat("_SUCCESS"):
            clients = Object.assign({}, state.clients);
            clients.list.loading = false;
            clients.list.data = action.payload;
            state = {
                ...state,
                clients: clients
            }
            break;
        case GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR.concat("_FAILURE"):
            clients = Object.assign({}, state.clients);
            clients.list.loading = false;
            clients.list.data = [];
            state = {
                ...state,
                clients: clients
            }
            break;

        case GET_BASF_BUSSINESS_LIST:
            bussines_list = Object.assign({}, state.clients);
            bussines_list.list.loading = true;
            state = {
                ...state,
                bussines_list: bussines_list
            }
            break;
        case GET_BASF_BUSSINESS_LIST.concat("_SUCCESS"):
            bussines_list = Object.assign({}, state.bussines_list);
            bussines_list.list.loading = false;
            bussines_list.list.data = action.payload;
            state = {
                ...state,
                bussines_list: bussines_list
            }
            break;
        case GET_BASF_BUSSINESS_LIST.concat("_FAILURE"):
            bussines_list = Object.assign({}, state.bussines_list);
            bussines_list.list.loading = false;
            bussines_list.list.data = [];
            state = {
                ...state,
                bussines_list: bussines_list
            }
            break;

        case SET_BASF_SELECT_VALUE:
            params = Object.assign({}, state.params);
            params[action.payload.field] = action.payload.value;
            state = {
                ...state,
                params: params
            }
            break;
        case GET_CLIENT_DETAILS_FOR_BASF_PREVIEW:
            client_detail = state.client_detail;
            client_detail = action.payload.client_detail;
            state = {
                ...state,
                client_detail: client_detail
            }
            break;
        case CLEAR_CLIENT_DETAILS:
            client_detail = state.client_detail;
            client_detail = null;
            state = {
                ...state,
                client_detail: client_detail
            }
            break;

        case CLEAR_DISTRIBUTORS:
            state = {
                ...state,
                distributors: {
                    list: {
                        data: [],
                        loading: false
                    }
                },
                params: {
                    distributor_id: '',
                    area_id: state.params.area_id
                }
            }
            break;

        case CLEAR_BUSSINES_LIST:
            state = {
                ...state,
                bussines_list: {
                    list: {
                        data: [],
                        loading: false
                    }
                },
                params: {
                    user_id: '',
                    bussines_category: state.params.bussines_category
                }
            }
            break;

        case CLEAR_CLIENTS:
            state = {
                ...state,
                clients: {
                    list: {
                        data: [],
                        loading: false
                    }
                }
            }
            break;

        case SET_NEW_UPDATE_VALUE:
            new_update = Object.assign({}, state.new_update);
            new_update[action.payload.field] = action.payload.value;
            state = {
                ...state,
                new_update: new_update
            }
            break;
        case UPLOAD_NEW_UPDATE:
            upload_loading = state.upload_loading;
            upload_loading = true;
            state = {
                ...state,
                upload_loading: upload_loading
            }
            break;
        // case UPLOAD_NEW_UPDATE.concat('_SUCCESS'):
        //     upload_loading = state.upload_loading;
        //     upload_loading = false;
        //     state = {
        //         ...state,
        //         upload_loading: upload_loading
        //     }
        //     toast.success('Caricato con successo!')
        //     break;
        // case UPLOAD_NEW_UPDATE.concat('_FAILURE'):
        //     upload_loading = state.upload_loading;
        //     upload_loading = false;
        //     state = {
        //         ...state,
        //         upload_loading: upload_loading
        //     }
        //     toast.error('Caricamento fallito!');
        //     break;
        case UPLOAD_MEDIA:
            new_update = Object.assign({}, state.new_update);
            new_update.file = action.payload;
            console.log("LOGO UPLOAD", new_update.file)
            state = {
                ...state,
                new_update: new_update
            }
            break;
        case GET_DISTRIBUTOR_NAME:
            distributor_name = state.distributor_name;
            distributor_name = action.payload.filter(distributor => distributor.id === Number(state.params.distributor_id))[0]?.ragione_sociale;
            state = {
                ...state,
                distributor_name: distributor_name
            }
            break;
        case CLEAR_FORM:
            new_update = Object.assign({}, state.new_update);
            new_update.benchmark = '';
            new_update.title = '';
            new_update.description = '';
            new_update.file = null;
            state = {
                ...state,
                new_update: new_update
            }
            break;
        default:
            state = {...state};
            break;
    }
    return state;
};