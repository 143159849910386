import React, {Component} from "react";
import {connect} from "react-redux";
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import Index from "./pages";
import Login from "./pages/login";
import Newsletter from "./pages/newsletter";
import Register from "./pages/register";
import RecoverPassword from "./pages/recover_password";
import ConfirmNewPassword from "./pages/confirm_new_password";

import UserProfile from "./pages/user_profile";
import DistributorProfile from "./pages/distributor_profile";
import BasfSalesProfile from "./pages/basf_sales_profile";
import BasfPersonnelProfile from "./pages/basf_personnel_profile";
import PrivacyPolicy from "./pages/privacy_policy";
import Crediti from "./pages/crediti";
import Condizioni from "./pages/condizioni";
import Protezione from "./pages/protezione";


import RevyCare from "./pages/revycare";

import {getCurrentUser} from "./store/auth/actions";
import cookie from 'react-cookies';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jarallax/dist/jarallax.css';

import '../src/assets/css/style.min.css';
import '../src/assets/css/animate.css';
import '../src/assets/css/tobii.min.css';
import '../src/assets/css/aos.css';
import '../src/assets/css/swiper.min.css';
import '../src/assets/css/colors/green.css';


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

        const {auth, getCurrentUser} = this.props;
        if (auth.token && auth.user == null) {
            getCurrentUser();
        }
    }

    render() {
        const {auth} = this.props;

        if (this.props.location.pathname.includes('profile') && auth.token === null && auth.authentication.loading === false) {
            return <Redirect to='/login'/>
        }

        return (
            <React.Fragment>
                <Switch>
                    {!cookie.load('token') && <Route path='/register' component={Register}/>
                    }
                    <Route path='/login' component={Login}/>
                    <Route path='/recover-password' component={RecoverPassword}/>
                    <Route path='/confirm-password/:token' component={ConfirmNewPassword}/>
                    {/*<Route path='/privacy-policy' component={PrivacyPolicy}/>*/}
                    {/* <Route path='/revycare' component={RevyCare}/> */}
                    {/* <Route path='/crediti' component={Crediti}/> */}
                    {/*<Route path='/condizioni-uso' component={Condizioni}/>*/}
                    {/*<Route path='/protezione-dati' component={Protezione}/>*/}

                    {/* {
                    (
                        auth.user?.category === 'PE' || 
                        auth.user?.category === 'PSV' || 
                        auth.user?.category === 'PL' || 
                        auth.user?.category === 'PE' || 
                        auth.user?.category === 'POJF'
                    ) && <Route path='/bussines-profile' component={BussinesProfile}/>} */}
                    
                    {auth.user?.category === 'PB' && <Route path='/pb-profile' component={BasfSalesProfile}/>}

                    {auth.user?.category === 'PE' && <Route path='/pe-profile' component={BasfSalesProfile}/>}

                    {auth.user?.category === 'PSV' && <Route path='/psv-profile' component={BasfSalesProfile}/>}

                    {auth.user?.category === 'PI' && <Route path='/pi-profile' component={BasfSalesProfile}/>}

                    {auth.user?.category === 'PL' && <Route path='/pl-profile' component={BasfSalesProfile}/>}

                    {auth.user?.category === 'POJF' && <Route path='/pojf-profile' component={BasfSalesProfile}/>}


                    {/* {auth.user?.category === 'PE' && <Route path='/PE-profile' component={UserProfile}/>} */}
                    {/* {auth.user?.category === 'PE' && <Route path='/basf-sales-profile' component={BasfSalesProfile}/>} */}
                    {/* {auth.user?.category === 'PE' && <Route path='/distributor-profile' component={DistributorProfile}/>} */}
                    {/* {auth.user?.category === 'basf-personnel' && <Route path='/basf-personnel-profile' component={BasfPersonnelProfile}/>} */}

                    <Route path='/newsletter' component={Newsletter}/>
                    
                    <Route path='/' component={Index}/>
                </Switch>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}


const mapDispatchToProps = {
    getCurrentUser
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
