import {
    GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR,
    GET_BASF_DISTRIBUTORS,
    GET_BASF_BUSSINESS_LIST,
    GET_DISTRIBUTORS_COMPETENCE_AREAS,
    SET_BASF_SELECT_VALUE,
    GET_CLIENT_DETAILS_FOR_BASF_PREVIEW,
    CLEAR_CLIENT_DETAILS,
    CLEAR_DISTRIBUTORS,
    CLEAR_CLIENTS,
    SET_NEW_UPDATE_VALUE,
    UPLOAD_NEW_UPDATE,
    UPLOAD_MEDIA,
    UPDATE_PROFILE,
    GET_DISTRIBUTOR_NAME,
    CLEAR_FORM,
    CLEAR_BUSSINES_LIST,
} from "./actionTypes";

export const setBasfSelectValue = (field, value) => {
    return {
        type: SET_BASF_SELECT_VALUE,
        payload: {
            field,
            value
        }
    }
}

export const updateProfileById = (data,cb) => {
    return {
        type: UPDATE_PROFILE,
        payload: data,
        cb,
        api: true
    }
}

export const getDistributorsCompetenceAreas = () => {
    return {
        type: GET_DISTRIBUTORS_COMPETENCE_AREAS,
        api: true
    }
}

export const getBasfDistributors = () => {
    return {
        type: GET_BASF_DISTRIBUTORS,
        api: true
    }
}

export const getBusinessList = () => {
    return {
        type: GET_BASF_BUSSINESS_LIST,
        api: true
    }
}

export const getClientsForBasfByDistributor = () => {
    return {
        type: GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR,
        api: true
    }
}

export const getClientDetailsForBasfPreview = (client_detail) => {
    return {
        type: GET_CLIENT_DETAILS_FOR_BASF_PREVIEW,
        payload: {
            client_detail
        }
    }
}

export const clearClientDetails = () => {
    return {
        type: CLEAR_CLIENT_DETAILS
    }
}

export const clearDistributors = () => {
    return {
        type: CLEAR_DISTRIBUTORS
    }
}

export const clearBussinesList = () => {
    return {
        type: CLEAR_BUSSINES_LIST
    }
}

export const clearClients = () => {
    return {
        type: CLEAR_CLIENTS
    }
}

export const setNewUpdateValue = (field, value) => {
    return {
        type: SET_NEW_UPDATE_VALUE,
        payload: {
            field,
            value
        }
    }
}

export const uploadNewUpdate = (selectedFiles) => {
    //param data
    // const formData = new FormData();
    // formData.append('benchmark', data.benchmark);
    // formData.append('title', data.title);
    // formData.append('description', data.description);
    // formData.append('fileToUpload', data.file);
    // formData.append('id_user', data.id_user);

    const formData = new FormData();
    // formData.append('user_id', selectedFiles.user_id);
    formData.append('file_reference', selectedFiles.file_reference);
    formData.append('profile_id', selectedFiles.profile_id);
    formData.append('bussines_category', selectedFiles.bussines_category);
    for (const file of selectedFiles) {
      formData.append('files', file);
    }
    console.log("formData actiiiiionnn ",formData)
    return {
        type: UPLOAD_NEW_UPDATE,
        payload: formData,
        api: true
    }
}

export const uploadMedia = (file) => {
    return {
        type: UPLOAD_MEDIA,
        payload: file
    }
}

export const getDistributorName = (distributors) => {
    return {
        type: GET_DISTRIBUTOR_NAME,
        payload: distributors
    }
}

export const clearForm = () => {
    return {
        type: CLEAR_FORM
    }
}
