import {
    REGISTER_NEW_USER,
    REGISTER_TO_NEWSLETTER,
    SET_REGISTER_TO_NEWSLETTER_VALUE,
    GET_PROFILE_CATEGORIES,
    UPLOAD_NEW_UPDATE,
} from "../store/user/actionTypes";

import {
    CONFIRM_NEW_PASSWORD,
    GET_CURRENT_USER,
    INIT_RECOVER_PASSWORD_PROCEDURE,
    LOGIN
} from "../store/auth/actionTypes";

import {GET_USER_DATA} from "../store/common/actionTypes";

import {
//     GET_BASF_DISTRIBUTORS,
//     GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR,
    GET_BASF_BUSSINESS_LIST,
    UPDATE_PROFILE
//     GET_DISTRIBUTORS_COMPETENCE_AREAS,
//     UPLOAD_NEW_UPDATE
} from "../store/basf/actionTypes";


// import {DISABLE_CLIENT, GET_DISTRIBUTOR_CLIENTS} from "../store/distributor/actionTypes";
// import {GET_ALL_BENCHMARKS, GET_PHOTOS_AND_VIDEOS_UPDATES} from "../store/common/actionTypes";


const endpoints = {
    [REGISTER_NEW_USER]: {
        endpoint: state => `/client/signup`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [REGISTER_TO_NEWSLETTER]: {
        endpoint: state => `/client/registerToNewsletter`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [UPLOAD_NEW_UPDATE]: {
        endpoint: state => `/client/upload_documents`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [LOGIN]: {
        endpoint: state => `/client/login`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [UPDATE_PROFILE]: {
        endpoint: state => `/user/updateProfileById`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [GET_CURRENT_USER]: {
        endpoint: (state, action) => `/user/getUserDataByToken`,
        method: 'GET',
    },
    [GET_PROFILE_CATEGORIES]: {
        endpoint: (state, action) => `/user/getProfileCategories`,
        mdethod: 'GET',
    },
    [INIT_RECOVER_PASSWORD_PROCEDURE]: {
        endpoint: state => `/user/recoveryPassword`,
        method: 'POST',
        payload: (state, action) => action.payload,
    },
    [CONFIRM_NEW_PASSWORD]: {
        endpoint: state => `/user/confirmPassword`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [GET_USER_DATA]: {
        endpoint: (state, action) => `/user/getUserData`,
        mdethod: 'GET',
    },
    [GET_BASF_BUSSINESS_LIST]: {
        endpoint: state => `/user/get-basf-bussines/${state.auth.user.id}/${state.basf.params.bussines_category}`,
        mdethod: 'GET',
    },
    // [GET_BASF_DISTRIBUTORS]: {
    //     endpoint: state => `/user/get-distributors/${state.auth.user.id}/${state.basf.params.area_id}/${state.auth.user.role_code}`,
    //     method: 'GET'
    // },

    // [GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR]: {
    //     //endpoint: state => `/user/get-basf-clients/31/4/7/${state.auth.user.role_code}`,
    //     endpoint: state => `/user/get-basf-clients/${state.auth.user.id}/${state.basf.params.area_id}/${state.basf.params.distributor_id}/${state.auth.user.role_code}`,
    //     method: 'GET'
    // },


    // [GET_DISTRIBUTOR_CLIENTS]: {
    //     endpoint: (state, action) => `/user/get-distributor-clients/${state.auth.user.id}`,
    //     method: 'GET'
    // },
    // [GET_DISTRIBUTORS_COMPETENCE_AREAS]: {
    //     endpoint: state => `/user/get-areas/${state.auth.user.id}/${state.auth.user.role_code}`,
    //     method: 'GET'
    // },
    // [GET_BASF_DISTRIBUTORS]: {
    //     endpoint: state => `/user/get-distributors/${state.auth.user.id}/${state.basf.params.area_id}/${state.auth.user.role_code}`,
    //     method: 'GET'
    // },
    // [GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR]: {
    //     //endpoint: state => `/user/get-basf-clients/31/4/7/${state.auth.user.role_code}`,
    //     endpoint: state => `/user/get-basf-clients/${state.auth.user.id}/${state.basf.params.area_id}/${state.basf.params.distributor_id}/${state.auth.user.role_code}`,
    //     method: 'GET'
    // },

    // [GET_BUSSINES_CATEGORIES]: {
    //     endpoint: (state, action) => `/user/getProfileCategories`,
    //     mdethod: 'GET',
    // },

    // [GET_ALL_BENCHMARKS]: {
    //     endpoint: state => `/user/get-benchmark-fields`,
    //     method: 'GET'
    // },

    // [GET_PHOTOS_AND_VIDEOS_UPDATES]: {
    //     endpoint: state => `/user/get-updates-list/${state.media.filter.benchmark}`,
    //     method: 'GET'
    // },
    // [DISABLE_CLIENT]: {
    //     endpoint: state => `/client/updateStatus`,
    //     method: 'POST',
    //     payload: (state, action) => action.payload
    // },

};

export default endpoints;
