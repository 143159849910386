import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { initRecoverPasswordProcedure } from '../store/auth/actions'

import PermIdentityIcon from '@material-ui/icons/PermIdentity'

class RecoverPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      error: false,
    }
  }

  handleRecoverPassword = (e) => {
    e.preventDefault()
    const { initRecoverPasswordProcedure } = this.props
    let mail_regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const { email } = this.state
    if (email.trim() && mail_regex.test(email)) {
      initRecoverPasswordProcedure(email)
    } else {
      this.setState({ error: true })
    }
  }

  render() {
    const { error } = this.state
    return (
      <React.Fragment>
        <section className="bg-auth-home">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-7 m-auto">
                <div className="card shadow rounded border-0">
                  <div className="card-body">
                    <h4 className="card-title text-left text_green">Rikuperim i fjalekalimit</h4>
                    <p className="border-bottom pb-2" />
                    <form className="login-form mt-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label className={ error ? 'form-label label_error' : 'form-label' }>
                                Email-i i përdoruesit<span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                              <PermIdentityIcon className="fea icon-sm custom_icon" />
                              <input
                                type="email"
                                className={
                                  error
                                    ? 'form-control ps-5 input_error'
                                    : 'form-control ps-5'
                                }
                                onChange={(event) => {
                                  const value = event.target.value
                                  this.setState({
                                    email: value,
                                    error: false,
                                  })
                                }}
                                placeholder="Email"
                                name="email"
                                required=""
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 mb-0">
                          <div className="d-grid">
                            <button className="btn btn-primary" onClick={(e) => this.handleRecoverPassword(e)}>Dërgo</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = {
  initRecoverPasswordProcedure,
}

export default withRouter(connect(null, mapDispatchToProps)(RecoverPassword))
