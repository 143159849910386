import {
    CLEAR_CLIENT_DETAILS,
    DISABLE_CLIENT,
    GET_CLIENT_DETAILS_FOR_PREVIEW,
    GET_DISTRIBUTOR_CLIENTS,
    GET_PDF_CARD
} from "./actionTypes";

export const getDistributorClients = () => {
    return {
        type: GET_DISTRIBUTOR_CLIENTS,
        api: true
    }
}

export const getClientDetailsForPreview = (client_data) => {
    return {
        type: GET_CLIENT_DETAILS_FOR_PREVIEW,
        payload: {
            client_data
        }
    }
}


export const clearClientDetails = () => {
    return {
        type: CLEAR_CLIENT_DETAILS
    }
}


export const disableClient = (email) => {
    return {
        type: DISABLE_CLIENT,
        api: true,
        payload: {
            email
        }
    }
}
