const map_codes =[
    {
        "id":"19",
        "map_code":"IT-AG",
        "province":"Agrigento"
    },
    {
        "id":"22",
        "map_code":"IT-AL",
        "province":"Alessandria"
    },
    {
        "id":"20",
        "map_code":"IT-AN",
        "province":"Ancona"
    },
    {
        "id":"27",
        "map_code":"IT-AR",
        "province":"Arezzo"
    },
    {
        "id":"28",
        "map_code":"IT-AP",
        "province":"Ascoli Piceno"
    },
    {
        "id":"25",
        "map_code":"IT-AT",
        "province":"Asti"
    },
    {
        "id":"24",
        "map_code":"IT-AV",
        "province":"Avellino"
    },
    {
        "id":"1",
        "map_code":"IT-BA",
        "province":"Bari"
    },
    {
        "id":"9",
        "map_code":"IT-BT",
        "province":"Barletta-Andria-Trani"
    },
    {
        "id":"5",
        "map_code":"IT-BN",
        "province":"Benevento"
    },
    {
        "id":"4",
        "map_code":"IT-BO",
        "province":"Bologna"
    },
    {
        "id":"8",
        "map_code":"IT-BR",
        "province":"Brindisi"
    },
    {
        "id":"45",
        "map_code":"IT-CL",
        "province":"Caltanissetta"
    },
    {
        "id":"52",
        "map_code":"IT-CB",
        "province":"Campobasso"
    },
    {
        "id":"54",
        "map_code":"IT-CT",
        "province":"Catania"
    },
    {
        "id":"46",
        "map_code":"IT-CN",
        "province":"Cuneo"
    },
    {
        "id":"75",
        "map_code":"IT-EN",
        "province":"Enna"
    },
    {
        "id":"70",
        "map_code":"IT-FM",
        "province":"Fermo"
    },
    {
        "id":"68",
        "map_code":"IT-FE",
        "province":"Ferrara"
    },
    {
        "id":"71",
        "map_code":"IT-FI",
        "province":"Firenze"
    },
    {
        "id":"67",
        "map_code":"IT-FG",
        "province":"Foggia"
    },
    {
        "id":"69",
        "map_code":"IT-FC",
        "province":"Forlì-Cesena"
    },
    {
        "id":"32",
        "map_code":"IT-LE",
        "province":"Lecce"
    },
    {
        "id":"33",
        "map_code":"IT-LI",
        "province":"Livorno"
    },
    {
        "id":"77",
        "map_code":"IT-MC",
        "province":"Macerata"
    },
    {
        "id":"80",
        "map_code":"IT-MN",
        "province":"Mantova"
    },
    {
        "id":"83",
        "map_code":"IT-MT",
        "province":"Matera"
    },
    {
        "id":"78",
        "map_code":"IT-ME",
        "province":"Messina"
    },
    {
        "id":"79",
        "map_code":"IT-MI",
        "province":"Milano"
    },
    {
        "id":"81",
        "map_code":"IT-MO",
        "province":"Modena"
    },
    {
        "id":"91",
        "map_code":"IT-PD",
        "province":"Padova"
    },
    {
        "id":"93",
        "map_code":"IT-PA",
        "province":"Palermo"
    },
    {
        "id":"88",
        "map_code":"IT-PR",
        "province":"Parma"
    },
    {
        "id":"87",
        "map_code":"IT-PV",
        "province":"Pavia"
    },
    {
        "id":"92",
        "map_code":"IT-PG",
        "province":"Perugia"
    },
    {
        "id":"85",
        "map_code":"IT-PU",
        "province":"Pesaro e Urbino"
    },
    {
        "id":"94",
        "map_code":"IT-PC",
        "province":"Piacenza"
    },
    {
        "id":"96",
        "map_code":"IT-PN",
        "province":"Pordenone"
    },
    {
        "id":"89",
        "map_code":"IT-PZ",
        "province":"Potenza"
    },
    {
        "id":"13",
        "map_code":"IT-RG",
        "province":"Ragusa"
    },
    {
        "id":"12",
        "map_code":"IT-RA",
        "province":"Ravenna"
    },
    {
        "id":"14",
        "map_code":"IT-RE",
        "province":"Reggio Emilia"
    },
    {
        "id":"17",
        "map_code":"IT-RN",
        "province":"Rimini"
    },
    {
        "id":"16",
        "map_code":"IT-RO",
        "province":"Rovigo"
    },
    {
        "id":"64",
        "map_code":"IT-SA",
        "province":"Salerno"
    },
    {
        "id":"62",
        "map_code":"IT-SI",
        "province":"Siena"
    },
    {
        "id":"59",
        "map_code":"IT-SR",
        "province":"Siracusa"
    },
    {
        "id":"43",
        "map_code":"IT-TA",
        "province":"Taranto"
    },
    {
        "id":"41",
        "map_code":"IT-TO",
        "province":"Torino"
    },
    {
        "id":"34",
        "map_code":"IT-TP",
        "province":"Trapani"
    },
    {
        "id":"38",
        "map_code":"IT-TV",
        "province":"Treviso"
    },
    {
        "id":"30",
        "map_code":"IT-VE",
        "province":"Venezia"
    },
    {
        "id":"65",
        "map_code":"IT-VR",
        "province":"Verona"
    },
    {
        "id":"102",
        "map_code":"IT-VI",
        "province":"Vicenza"
    }
]


const included_codes = map_codes.map(code => code.map_code);



export  {included_codes,map_codes};


