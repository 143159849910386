import React, {Component, Fragment} from "react";
import logo_dark from '../assets/images/logo_dark.PNG';


class Protezione extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Fragment>
                <div id="protezione">
                    <div className='mt-3'><img src={logo_dark} alt="..."/></div>
                    <div className='mt-5 font-12'>BASF Italia S.p.A., Via Marconato 8, 20811 Cesano Maderno (MB),
                        Italia
                    </div>
                    <div className='mt-3 font-12'>Rev. 02 – Marzo 2021</div>
                    <h3 className='text-bold text-center mt-3'>INFORMAZIONI SULLA PROTEZIONE DEI DATI PER CLIENTI,
                        FORNITORI E ALTRI CONTRAENTI DI BASF
                    </h3>
                    <p className='mt-3'>La protezione dei dati è un tema di priorità assoluta per BASF (di seguito
                        denominata “la nostra
                        società”). Per garantire la dovuta trasparenza, con il presente documento BASF intende fornire
                        informazioni sul modo in cui la nostra società tratta i dati personali delle persone di contatto
                        dei nostri
                        clienti, dei partner di distribuzione (di seguito denominati "nostre persone di contatto"). BASF
                        tratta i dati
                        personali in stretta osservanza delle leggi applicabili in materia di protezione dei dati
                        personali.
                    </p>
                    <br/>
                    <p className='text-bold text-underline'>Chi è Titolare del trattamento dei dati e chi è il
                        Coordinatore per la protezione dei dati?</p>
                    <p className='text-bold'>Titolare del trattamento è:</p>
                    <p className='margin-bottom'>BASF Italia S.p.A.</p>
                    <p className='margin-bottom'>Via Marconato, 8</p>
                    <p className='margin-bottom'>Cesano Maderno (MB)</p>
                    <p className='margin-bottom'>Telefono 0362 512 1</p>
                    <p className='margin-bottom'>Fax 0362 512 539</p>
                    <p className='margin-bottom'>E-mail <a className='text_green'
                                                           href='mailto:data-protection.it@basf.com'>data-protection.it@basf.com</a>
                    </p>
                    <p className='mt-2'>
                        È possibile contattare il nostro <strong>Coordinatore per la protezione dei dati </strong>
                        (Sara Biscardini) al seguente
                        indirizzo e-mail: <a className='text_green'
                                             href='mailto:data-protection.it@basf.com'>data-protection.it@basf.com</a>
                    </p>

                    <br/>
                    <p className='text-bold text-underline mt-3'>Quali categorie di dati personali utilizziamo e da dove
                        provengono tali dati?</p>
                    <p>La nostra società tratta le seguenti categorie di dati personali:</p>
                    <ul>
                        <li>Riferimenti delle persone di contatto presso terzi (“persone nostre referenti”) (nome e
                            cognome,
                            indirizzo e numero di telefono, numero di cellulare, numero di fax e indirizzo e-mail).
                        </li>
                        <li>
                            Altre informazioni necessarie per l'esecuzione del rapporto contrattuale con i nostri
                            clienti o
                            distributori (per esempio i dettagli relativi ad un pagamento, ad un ordine ecc.) o altri
                            contraenti.
                        </li>
                        <li>
                            Altre informazioni necessarie per evadere le richieste delle persone nostre referenti o per
                            identificarle in modo inequivocabile all'interno dei nostri sistemi.
                        </li>
                    </ul>
                    <p>Raccogliamo le informazioni personali delle persone nostre referenti direttamente da queste
                        ultime o dai
                        nostri clienti o distributori o da contraenti durante lo svolgimento di un rapporto
                        contrattuale.
                    </p>
                    <br/>
                    <div className='row mt-2' style={{fontSize: '1rem'}}>
                        <div className='col-md-4'>
                            <p className='text-bold'>BASF Italia S.p.A.</p>
                            <p className='mt-2 margin-bottom'>Via Marconato 8</p>
                            <p className='margin-bottom'>20811 Cesano Maderno (MB), Italia</p>
                            <p className='margin-bottom'>Telefono +39 0362 5121</p>
                            <p className='margin-bottom'>Fax +39 0362 512210</p>
                            <p className='margin-bottom'><a className='text_green' href={process.env.REACT_APP_URL}>www.basf.it</a></p>
                        </div>
                        <div className='col-md-4'>
                            <p className='margin-bottom'>Società a socio unico</p>
                            <p className='margin-bottom'>Dir. e Coord. Gruppo BASF</p>
                            <p className='mt-4 margin-bottom'>Sede legale</p>
                            <p className='margin-bottom'>Via Marconato 8</p>
                            <p className='margin-bottom'>20811 Cesano Maderno (MB), Italia</p>
                        </div>
                        <div className='col-md-4'>
                            <p className='margin-bottom'>Cap. Soc. Euro 100.000.000</p>
                            <p className='margin-bottom'>Reg. Imp. MB 00717180152</p>
                            <p className='margin-bottom'>R.E.A. MB 349252</p>
                            <p className='margin-bottom'>Cod. mecc. MB 001324</p>
                            <p className='margin-bottom'>Codice Fiscale 00717180152</p>
                            <p className='margin-bottom'>Partita IVA IT00688460963 <span style={{marginLeft:'1.5rem'}}>Rev. 01</span></p>
                            <p></p>
                    </div>
                    </div>
                    <p className='mt-3'>Inoltre, trattiamo informazioni personali che abbiamo acquisito da fonti pubbliche in maniera appropriata.</p>

                    <br />
                    <p className='mt-3 text-bold text-underline'>Per quali finalità e su quali basi giuridiche vengono trattati i dati?</p>
                    <p>Trattiamo i dati personali delle persone in conformità alle disposizioni del Regolamento generale europeo
                        sulla protezione dei dati (GDPR), nonché a qualsivoglia ulteriore legge applicabile.
                    </p>
                    <p>Il trattamento dei dati viene effettuato per i seguenti scopi:</p>
                    <ul>
                        <li>Comunicare con le persone nostre referenti riguardo a prodotti, servizi e progetti (per esempio la
                            distribuzione regolare di informazioni e newsletter).
                        </li>
                        <li>Evadere le richieste delle persone nostre referenti, dei clienti, dei soggetti interessati o dei partners
                            di distribuzione.
                        </li>
                        <li>Pianificare, dare esecuzione o gestire il rapporto (contrattuale) con i nostri clienti, distributori, o
                            soggetti interessati contraenti, per esempio per processare gli ordini, per adempiere ad obblighi
                            di natura contabile, per eseguire le consegne ed organizzare il trasporto.
                        </li>
                        <li>Condurre sondaggi presso i clienti, svolgere campagne di marketing, analisi di mercato, lotterie,
                            concorsi o attività simili, relativamente ai prodotti commercializzati da BASF.
                        </li>
                        <li>Mantenere e proteggere la sicurezza dei nostri prodotti e servizi e garantire la salvaguardia e la
                            funzionalità dei nostri siti Web; prevenire e rilevare rischi per la sicurezza, attività fraudolente o
                            altre azioni criminali o dolose.
                        </li>
                        <li>Mantenere e proteggere la sicurezza delle nostre sedi e degli stabilimenti (per esempio
                            controllando gli accessi, rilasciando permessi di accesso temporanei).
                        </li>
                        <li>Garantire la conformità ai requisiti di legge (per esempio, il rispetto degli obblighi di conservazione
                            previsti dalle normative applicabili quale ad es. quella fiscale; prevenire il riciclaggio di denaro o
                            altri reati in ambito economico).
                        </li>
                        <li>Resistere in giudizio, instaurare cause legali, esercitare o difendersi in contenziosi, far valere
                            contratti esistenti.
                        </li>
                    </ul>
                    <p>Per conseguire tali scopi si rende necessario il trattamento delle categorie di dati sopra menzionate.</p>
                    <p>Salvo sia diversamente convenuto, il fondamento giuridico per il trattamento di dati personali è
                        rappresentato dall'articolo 6 comma 1 lettere (b) e (f) del GDPR.
                    </p>
                    <p>Qualora intendessimo trattare i dati personali delle persone nostre referenti per scopi diversi da quelli sopra menzionati,
                        provvederemo ad informare opportunamente le persone interessate prima di procedere con il trattamento e a richiederne il consenso laddove previsto dalla normativa vigente.
                    </p>
                    <br/>
                    <p className='text-bold text-underline'>A chi vengono trasmessi i dati personali?</p>
                    <p>All'interno della nostra società, avranno accesso ai dati personali delle persone nostre referenti soltanto le persone che necessitano
                        di detti dati per raggiungere le finalità sopra gli scopi di cui sopra.
                    </p>
                    <p>All'interno del nostro Gruppo, i dati personali delle persone nostre referenti vengono forniti a determinate società all'interno
                        del Gruppo stesso qualora queste eseguano centralmente attività per società collegate facenti parte del nostro Gruppo o eseguano
                        attività interaziendali in virtù della struttura organizzativa del Gruppo, o qualora sia necessario per raggiungere le finalità sopra menzionate.
                    </p>
                    <p>
                        La nostra società potrebbe trasferire i dati personali delle persone nostre referenti ad autorità di vigilanza,
                        tribunali o uffici pubblici nella misura necessaria a garantire il rispetto delle leggi applicabili o per esercitare, rivendicare o difendere diritti ove consentito dalla legge.
                    </p>
                    <p>
                        Per la realizzazione degli scopi sopra menzionati, ci avvaliamo altresì della collaborazione di fornitori di servizi, i quali si occupano del trattamento dei dati personali delle
                        persone nostre referenti per nostro conto ed esclusivamente in conformità alle nostre istruzioni. Tali fornitori sono contrattualmente vincolati al rispetto delle normative applicabili in materia di protezione dei dati.
                    </p>
                    <p>
                        In taluni casi trasmettiamo i dati personali ai fornitori di servizi o a società del Gruppo
                        con sede in paesi esterni allo Spazio economico europeo (“paesi terzi”),
                        nei quali le leggi applicabili non garantiscono un livello adeguato di protezione dei dati.
                        In tali casi adottiamo opportune misure atte a salvaguardare la protezione dei dati personali
                        delle persone nostre referenti e a garantire un livello adeguato di protezione dei dati.
                        Di conseguenza forniamo le informazioni personali delle persone nostre referenti a destinatari
                        esterni al nostro Gruppo, aventi sede in un paese terzo, solo se detti destinatari hanno convenuto
                        con la nostra società condizioni contrattuali secondo gli standard europei o se hanno posto in essere
                        le cosiddette Binding Corporate Rules (norme aziendali vincolanti).
                    </p>
                    <p>Per ulteriori informazioni e per avere una copia delle misure adottate, è possibile rivolgersi ai contatti sopra specificati.</p>

                    <br />
                    <p className='text-underline text-bold mt-3'>Per quanto tempo conserviamo i dati personali delle persone nostre referenti?</p>
                    <p>Salvo sia diversamente convenuto (per esempio, attraverso specifico consenso),
                        la nostra società elimina o blocca i dati personali delle persone nostre referenti
                        non appena tali dati non risultino più necessari agli scopi sopra citati, a meno che
                        l'eliminazione o il blocco si configurino come violazione dei nostri obblighi giuridici
                        di conservazione (per esempio, gli obblighi di conservazione previsti dalle normative fiscali).
                    </p>

                    <br />
                    <p className='text-bold text-underline'>Quali diritti relativi alla protezione dei dati possono rivendicare le parti interessate?</p>

                    <p>Le persone nostre referenti possono richiedere <strong>informazioni</strong> riguardo ai propri dati personali archiviati
                        e trattati dalla nostra società rivolgendosi agli indirizzi sopra specificati.
                        Inoltre, in determinate circostanze, le persone nostre referenti hanno la facoltà di richiedere
                        la <strong>correzione</strong> o l'<strong>eliminazione</strong> dei propri dati personali.
                        Esse possono altresì esercitare <strong>il diritto di limitare il trattamento</strong> dei dati personali
                        e <strong>il diritto di rivelare i dati da loro forniti</strong> in un formato strutturato,
                        ordinario e atto alla lettura automatica.
                    </p>
                    <div className='border-box'>
                        <p className='text-bold'>Diritto di opposizione</p>
                        <p className='text-bold'>Nel caso in cui il trattamento si basi sul consenso, le persone nostre referenti hanno il diritto
                            di opporsi al trattamento dei dati personali che li riguardano in qualsiasi momento.
                            Nel caso in cui il trattamento da parte nostra dei dati personali delle persone nostre
                            referenti sia finalizzato alla salvaguardia di nostri legittimi interessi,
                            le persone nostre referenti potranno opporsi al trattamento in qualsiasi momento per
                            ragioni connesse ad una propria situazione specifica. In caso di opposizione,
                            interromperemo il trattamento dei dati personali del soggetto che ne ha fatto richiesta,
                            salvo il caso in cui fossimo in grado di fornire ragioni impellenti prevalenti sugli interessi,
                            i diritti e le libertà delle persone nostre referenti, o qualora fossimo in grado di dimostrare
                            che il trattamento serve ad instaurare un giudizio, esercitare diritti, difendersi contro
                            rivendicazioni o resistere in contenziosi.
                        </p>
                    </div>
                    <p className='text-bold text-underline mt-5'>Dove è possibile presentare reclamo?</p>
                    <p>Fermo restando qualsivoglia altra azione legale prevista dalla legge,
                        le persone nostre referenti hanno diritto a presentare reclamo avanti l'autorità
                        di vigilanza competente, nello specifico nello stato membro nel quale tali persone
                        risiedono o dove ha avuto luogo la presunta violazione, qualora tali soggetti ritengano
                        che il trattamento dei dati personali che li riguardano abbia violato il GDPR.
                    </p>
                    <p>L'autorità di vigilanza alla quale viene presentato il reclamo provvederà ad informare
                        il ricorrente della situazione e degli esiti del reclamo in questione, nonché della possibilità
                        di presentare ricorso giurisdizionale come disposto dall'articolo 78 del GDPR.
                    </p>
                    <p>È possibile presentare reclamo anche alla seguente Autorità:</p>
                    <p className='margin-bottom'>Commissario statale per la protezione dei dati e la libertà di informazione Renania-Palatinato</p>
                    <p className='margin-bottom'>Hintere Bleiche 34</p>
                    <p className='margin-bottom'>55116 Mainz</p>
                    <p className='margin-bottom'>Germania</p>

                    <div style={{marginTop: '8rem', fontSize: '0.8rem'}}>
                        <span>Informativa per terzi BASF Italia S.p.A, Regolamento UE 679/2016</span><br/>
                        <span>Rev. 02 - Marzo 2021</span>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Protezione;
