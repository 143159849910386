import React, {Component} from 'react'
import {Icon} from '@iconify/react'

import {connect} from 'react-redux'
import {
    setRegisterToNewsletterValue,
    registerToNewsletter,
    setErrorMapTrue,
    setErrorMapFalse,
    setErrorCodeFalse,
} from '../store/user/actions'

import Map from '../components/vector_map/vector_map'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import SimpleBar from 'simplebar-react'
import {sorting} from '../assets/helpers/helpers'
import DotLoader from "react-spinners/DotLoader";

import 'simplebar/dist/simplebar.min.css'
import '../assets/css/materialdesignicons.min.css'
import '../assets/css/custom.css'

import SweetAlert from "react-bootstrap-sweetalert";

import {Button, Col} from "reactstrap";

import {capitalize, clearApiBaseUrl, formatUpdateCreationDate} from "../assets/helpers/helpers";

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';


class Newsletter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newsletter_data: props.user.newsletter_data,
      errors: {
      },
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const { setErrorMapFalse, user } = this.props
    if (user.error_map) {
      setErrorMapFalse()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.user.redirect_to_home !== this.props.user.redirect_to_home) {
      this.props.history.push('/')
    }
  }

  handleFormValidation = () => {
    const { errors } = this.state

    const form_data = this.props.user.newsletter_data
    const { setErrorMapTrue } = this.props
    let form_is_valid = true
    let mail_regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let phone_regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g

    if (!form_data?.name?.trim()) {
      errors.name = true
      form_is_valid = false
    }

    if (!form_data?.surname?.trim()) {
      errors.surname = true
      form_is_valid = false
    }

    if (!form_data?.email?.trim() || !mail_regex.test(form_data.email)) {
      errors.email = true
      form_is_valid = false
    }

    console.log('FORM_DATA_VALUES', form_data)
    console.log('FORM_DATA_VALUES_VALIDATION', errors)

    this.scrollToFirstError(errors)

    this.setState((prevState) => ({
      ...prevState,
      errors: errors,
    }))
    return form_is_valid
  }

  initNewsletterRegistration = (event) => {
    const self = this
    event.preventDefault()
    const { registerToNewsletter, user } = self.props
    const newsletter_data = user.newsletter_data
    // const data = this.props.user.new_update;

    if (self.handleFormValidation()) {
      console.log('newsletter_data', newsletter_data)
      registerToNewsletter(newsletter_data)
    }
  }

  scrollToFirstError = (errors) => {
    const all_errors = Object.entries(errors).filter(
      (error) => error[1] === true
    )

    const sorted_errors = all_errors.sort(function (a, b) {
      return sorting.indexOf(a[0]) - sorting.indexOf(b[0])
    })

    if (sorted_errors.length) {
      const input = document.getElementById(sorted_errors[0][0])
      input.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      })
    }
  }

  scrollToModalFirstError = (errors) => {
    const all_errors = Object.entries(errors).filter(
      (error) => error[1] === true
    )

    const sorted_errors = all_errors.sort(function (a, b) {
      return sorting.indexOf(a[0]) - sorting.indexOf(b[0])
    })

    if (sorted_errors.length) {
      const input = document.getElementById(sorted_errors[0][0])
      input.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      })
    }
  }

  render() {
    const { setRegisterToNewsletterValue, setErrorCodeFalse } = this.props

    const { errors } = this.state

    const error_map = this.props.user.error_map
    const code_error = this.props.code_error

    const errorOnForm = Object.values(errors).every((value) => {
      if (value === true) {
        return true
      }
      return false
    })

    console.log('valid_form_data', errorOnForm)

    return (
      <React.Fragment>
        <section className="bg-auth-home">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 m-auto">
                <div className="card shadow rounded border-0">
                  <div className="card-body">
                    <h4
                      className="card-title text-center text-uppercase"
                      style={{ color: '#bf1e2e' }}
                    >
                      {' '}
                      Rregjistrohu ne newsletterin tone{' '}
                    </h4>
                    <p className="border-bottom pb-2" />

                    <form className="login-form mt-4">
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          {/* Emri */}
                          <div className="col-md-12">
                            <div className="mb-4">
                              <label
                                className={
                                  errors.name
                                    ? 'form-label label_error'
                                    : 'form-label'
                                }
                              >
                                Emër <span className="text-danger">*</span>
                              </label>
                              <div className="form-icon position-relative">
                                <PermIdentityIcon className="fea icon-sm custom_icon" />
                                <input
                                  type="text"
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      newsletter_data: {
                                        ...prevState.newsletter_data,
                                        name: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        name: false,
                                      },
                                    }))
                                    setRegisterToNewsletterValue('name', value)
                                  }}
                                  value={this.props.user.newsletter_data.name}
                                  className={
                                    errors.name
                                      ? 'form-control ps-5 input_error'
                                      : 'form-control ps-5'
                                  }
                                  placeholder="Emër"
                                  id="name"
                                  name="Emër"
                                  required=""
                                />
                              </div>
                            </div>
                          </div>

                          {/* Mbiemri */}
                          <div className="col-md-12">
                            <div className="mb-4">
                              <label
                                className={
                                  errors.surname
                                    ? 'form-label label_error'
                                    : 'form-label'
                                }
                              >
                                Mbiemër <span className="text-danger">*</span>
                              </label>
                              <div className="form-icon position-relative">
                                <PermIdentityIcon className="fea icon-sm custom_icon" />
                                <input
                                  type="text"
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      newsletter_data: {
                                        ...prevState.newsletter_data,
                                        surname: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        surname: false,
                                      },
                                    }))
                                    setRegisterToNewsletterValue('surname',value)
                                  }}
                                  value={this.props.user.newsletter_data.surname}
                                  className={
                                    errors.surname
                                      ? 'form-control ps-5 input_error'
                                      : 'form-control ps-5'
                                  }
                                  placeholder="Mbiemër"
                                  id="surname"
                                  name="Mbiemër"
                                  required=""
                                />
                              </div>
                            </div>
                          </div>

                          {/* Emaili */}
                          <div className="col-md-12">
                            <div className="mb-4">
                              <label
                                className={
                                  errors.email
                                    ? 'form-label label_error'
                                    : 'form-label'
                                }
                              >
                                E-mail<span className="text-danger">*</span>
                              </label>
                              <div className="form-icon position-relative">
                                <PermIdentityIcon className="fea icon-sm custom_icon" />
                                <input
                                  type="email"
                                  autoComplete="off"
                                  className={
                                    errors.email
                                      ? 'form-control ps-5 input_error'
                                      : 'form-control ps-5'
                                  }
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      newsletter_data: {
                                        ...prevState.newsletter_data,
                                        email: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        email: false,
                                      },
                                    }))
                                    setRegisterToNewsletterValue('email', value)
                                  }}
                                  placeholder="Email"
                                  name="email"
                                  required=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="d-grid">
                            <button
                              className="btn btn-primary border-button"
                              // disabled={!errorOnForm}
                              onClick={(e) =>
                                this.initNewsletterRegistration(e)
                              }
                            >
                              {' '}
                              Rregjistrohu{' '}
                            </button>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="d-grid">
                            <p className="text-danger mt-3">
                              {' '}
                              * Fusha të detyrueshme{' '}
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        code_error: state.user.code_error,
    }
}

const mapDispatchToProps = {
    setRegisterToNewsletterValue,
    registerToNewsletter,
    setErrorMapTrue,
    setErrorMapFalse,
    setErrorCodeFalse,
}

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter)
