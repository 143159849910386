import React, {Component} from "react";
import background_image from '../assets/images/bg-2.jpg';
import product_1 from '../assets/images/prodotto-1.jpg';
import product_2 from '../assets/images/prodotto-2.jpg';

import 'jarallax/dist/jarallax.css';
import {
    jarallax
} from 'jarallax';



class RevyCare extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        jarallax(document.querySelectorAll('.jarallax'), {
            speed: 0.5
        });
    }

    render() {
        return (
            <React.Fragment>
                <div id='revycare'>
                    <section className="bg-auth-home d-table w-100 jarallax jarallax-img" data-jarallax="" data-speed="0.5"
                             style={{background: `url(${background_image}) top`}} >
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="title-heading">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section mt-5 pt-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <div className="display-5 mb-5">Revycare<sup>®</sup>, l'innovativo fungicida a base
                                        di Revysol<sup>®</sup></div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6 order-md-1 order-3">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                            <div className="card overflow-hidden rounded-0 border-0">
                                                <div className="card-body p-0">
                                                    <img src={product_1} className="img-fluid" alt="Revycare"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0 order-md-2 order-1 bg-light">
                                    <div className="ms-lg-2 pt-4">
                                        <div className="section-title">
                                            <p className="text-black">
                                                Revycare<sup>®</sup> è il fungicida a base Revysol<sup>®</sup>, triazolo
                                                BASF di nuova
                                                generazione, e F500<sup>®</sup> che garantisce risultati eccellenti in ogni
                                                circostanza
                                                climatica e condizione d’impiego
                                                Revycare® rappresenta una vera innovazione nel campo dei fungicidi perché ha
                                                caratteristiche uniche rispetto alla stessa classe di prodotti, i triazoli
                                                tradizionali.
                                            </p>
                                            <p className="text-black">
                                                È caratterizzato da un’eccezionale efficacia legata al comportamento di
                                                Revysol<sup>®</sup> all’interno della pianta, dove viene assorbito molto
                                                velocemente,
                                                svolgendo una attività curativa, e traslocato molto lentamente. Assicura
                                                così una
                                                protezione prolungata dai patogeni.</p>
                                            <p className="text-black">
                                                Grazie al suo assorbimento estremamente veloce e l'elevata resistenza al
                                                dilavamento,
                                                eventuali piogge successive al trattamento non compromettono la sua
                                                efficacia.
                                                L’assorbimento del prodotto da parte della pianta non è influenzato dalle
                                                basse
                                                temperature (condizione a cui sono soggetti gli altri triazoli) mantenendo
                                                inalterata
                                                l’efficacia nei confronti delle principali malattie dell’apparato fogliare.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="bg-white">
                        <div className="container pt-5 pb-5">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0 order-md-2 bg-light">
                                    <div className="section-title me-lg-5">
                                        <h2 className="title mb-4 text_green">Benefici</h2>
                                        <p className="text-black">
                                            <ul className="ps-3">
                                                <li className="mb-2">
                                                    <strong>Facilità nel programmare interventi</strong>: il
                                                    cerealicoltore può trattare
                                                    in ogni condizione meteo dato che l’efficacia e la selettività di
                                                    Revycare<sup>®</sup> non sono
                                                    influenzate da sbalzi termici, ritorni di freddo.
                                                </li>
                                                <li className="mb-2">
                                                    <strong>Tranquillità di utilizzo</strong>: non presenta effetti
                                                    fitotossici al
                                                    diserbo. Grazie all’ottima selettività di Revycare<sup>®</sup>,
                                                    l’agricoltore può effettuare
                                                    miscele con gli erbicidi (graminicidi e dicotiledonicidi) che
                                                    comunemente usa, senza
                                                    preoccuparsi di eventuali problemi di fitotossicità.
                                                </li>
                                                <li className="mb-2">
                                                    <strong>Sicurezza nei risultati</strong>: può essere utilizzato nel
                                                    corso degli
                                                    anni, senza paura di perdite di efficacia nel tempo.

                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 order-1 order-md-2">
                                    <img src={product_2} className="img-fluid" alt="Tanica"/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="bg-light">
                        <div className="container pt-5 pb-5">
                            <div className="row align-items-center">
                                <div className="col-lg-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <div className="section-title me-lg-5">
                                        <h2 className="title mb-4 text_green">DAL SEME ALLA SPIGA: L’OFFERTA DI BASF
                                            PER LA CEREALICOLTURA
                                        </h2>
                                        <p className="text-black">La cerealicoltura, settore di eccellenza per il Made
                                            in Italy, necessita
                                            di protezione dal seme fino alla spiga per garantire gli standard più
                                            elevati. A seconda del
                                            cereale, della sua destinazione e della collocazione geografica, BASF offre
                                            una linea
                                            completa di difesa dai principali patogeni, che aiutano i cerealicoltori
                                            nella tecnica
                                            colturale: dai concianti Systiva<sup>®</sup> o Rubin<sup>®</sup> Plus, Comet<sup>®</sup> 250
                                            EC, Priaxor<sup>®</sup> e Revycare<sup>®</sup>,
                                            utili nella fase di sviluppo della foglia, fino ad arrivare a
                                            Caramba<sup>®</sup> e Comet<sup>®</sup> Pro Pack,
                                            adatti nel periodo di spigatura, e Biathlon<sup>®</sup> 4D impiegato per il
                                            controllo delle infestanti
                                            dicotiledoni dei cereali a paglia.
                                        </p>
                                        <p>Per saperne di più: <a href="https://www.agro.basf.it"  className="text_green"
                                                                  title="www.agro.basf.it"
                                                                  target="_blank">www.agro.basf.it</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment>
        )
    }
}

export default RevyCare;