import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import ReactPlayer from 'react-player'


import {getCurrentUser} from "../store/auth/actions";
import {getAllBenchmarks, setBenchmarkFilterValue, getPhotosAndVideosUpdates} from "../store/common/actions";
import {capitalize,clearApiBaseUrl,formatUpdateCreationDate} from "../assets/helpers/helpers";

import {Icon} from '@iconify/react';
import cereali_img from '../assets/images/icon/cereali.svg';


import '../assets/css/tobii.min.css';
import '../assets/css/materialdesignicons.min.css';

import '../assets/css/style.min.css';
import '../assets/css/colors/green.css';
import '../assets/css/custom.css';


class UserProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            profile_open: true,
            project_open: false,
            filter: props.media.filter
        };
    }

    componentDidMount() {
        const {getAllBenchmarks, media} = this.props;
        if (media.benchmarks.list.data.length === 0 && media.benchmarks.list.loading === false) {
            getAllBenchmarks();
        }
        if (media.filter.benchmark === '') {
            setBenchmarkFilterValue('benchmark', this.props.auth.user?.campo_benchmark);
            getPhotosAndVideosUpdates();
        }
        const search_params = this.props.location.search;

        if (search_params.length !== 0) {
            this.props.history.push('/PE-profile');
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.project_open !== this.state.project_open) {
            const {setBenchmarkFilterValue, getPhotosAndVideosUpdates, media} = this.props;
            if (media.filter.benchmark === '') {
                setBenchmarkFilterValue('benchmark', this.props.auth.user?.campo_benchmark);
                getPhotosAndVideosUpdates();
            }
        }
    }

    handleToggle = (type) => {
        if (type === 'project') {
            this.setState({
                project_open: true,
                profile_open: false
            })
        }
        if (type === 'profile') {
            this.setState({
                project_open: false,
                profile_open: true
            })
        }
    }


    render() {
        const {profile_open, project_open} = this.state;
        const {currentUser, auth, setBenchmarkFilterValue, getPhotosAndVideosUpdates} = this.props;
        const benchmarks = this.props.media.benchmarks.list.data;
        const medias = this.props.media.content.data;
        const imgBaseUrl = process.env.REACT_APP_API_HOST;

        if (auth.token === null) {
            return <Redirect to='/login'/>
        }

        return (
            <React.Fragment>
                <section>
                    <div className="container">
                        <nav aria-label="breadcrumb" className="d-inline-block">
                            <ul className="breadcrumb mb-0">
                                <li className="breadcrumb-item" style={{marginRight:'5px'}}><a onClick={() => this.props.history.push('/')}>Home </a>
                                </li>
                                <li className={profile_open ? "breadcrumb-item active" : "breadcrumb-item"}><a
                                 onClick={() => this.handleToggle('profile')}> Të dhënat e profilit</a></li>
                                {project_open &&
                                <li className={project_open ? "breadcrumb-item active" : "breadcrumb-item"}><a
                                    onClick={() => this.handleToggle('project')}>Il mio campo Revy+</a>
                                </li>
                                }
                            </ul>
                        </nav>
                    </div>
                </section>
                <section className="d-table w-100 mt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt-3 mb-3">
                                <div className="card public-profile border-0 rounded shadow">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-lg-2 text-center profile-icon">
                                                <i className="uil uil-user-circle"/>
                                            </div>

                                            <div className="col-lg-10 col-md-9">
                                                <div className="row align-items-end">
                                                    <div className="col-md-7 text-md-start text-center mt-4 mt-sm-0">
                                                        <h3 className="title mb-0 custom_font">Ciao {currentUser?.ragione_sociale}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 d-lg-block">
                                <div className="sidebar sticky-bar p-4 rounded shadow">
                                    <div className="widget">
                                        <ul className="list-unstyled sidebar-nav mb-0" id="navmenu-nav">
                                            <li className={profile_open ? "navbar-item account-menu px-0 mt-2 active" : "navbar-item account-menu px-0 mt-2"}
                                                onClick={() => this.handleToggle('profile')}
                                                style={{cursor: "pointer"}}
                                            >
                                                <a className="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                                                    <span className="h4 mb-0"><i className="uil uil-user"/></span>
                                                    <h6 className="mb-0 ms-2 custom_font">Profilo</h6>
                                                </a>
                                            </li>

                                            <li className={project_open ? "navbar-item account-menu px-0 mt-2 active cursor_pointer" : "navbar-item account-menu px-0 mt-2 cursor_pointer"}
                                                onClick={() => this.handleToggle('project')}
                                                style={{cursor: "pointer"}}
                                            >
                                                <a className="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                                                    <span className="h4 mb-0"><i
                                                        className="uil uil-dashboard"/></span>
                                                    <h6 className="mb-0 ms-2 custom_font">Il mio campo Revy+</h6>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {project_open ?
                                <div className="col-lg-8 col-12">
                                    <div className=" pb-4">
                                        <div className="row">
                                            <div className="col-md-12 mt-4">
                                                <h5 className='custom_font'>Il mio campo Revy+</h5>
                                                <div className="mt-4">
                                                    <div
                                                        className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="feather:map-pin" width="17" height="17"
                                                              className="fea icon-ex-md text-muted me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Zona -
                                                                Provincia:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.zona} - {currentUser?.provincia}</p>
                                                        </div>
                                                    </div>


                                                    <div
                                                        className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="feather:map-pin" width="17" height="17"
                                                              className="fea icon-ex-md text-muted me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Il mio campo
                                                                benchmark</h6>
                                                            <p className="text-muted mb-0">{currentUser?.campo_benchmark}</p>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="bi:grid" className="fea icon-ex-md me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Varietà
                                                                coltivabile:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.varieta_coltivabile}</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="carbon:layers"
                                                              className="fea icon-ex-md me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Numero totale
                                                                di ettari di superficie coltivabile:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.tot_ha_coltivabile}</p>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <i className="fea icon-ex-md me-3"><img
                                                            src={cereali_img}
                                                            className="img-fluid mt-n2" alt="cereali"/></i>
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Numero ettari
                                                                di cereali partecipanti all'iniziativa:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.num_ha_cereali_partecipa}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <Icon icon="bi:code"
                                                              className="fea icon-ex-md me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Codice fornito
                                                                al momento dell'acquisto:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.codice_univoco}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <h5 className="mt-4 mb-0 custom_font">Campi benchmark:</h5>
                                        <div className="row">
                                            <div className="col-md-12 mt-3">
                                                <div className="mb-3">
                                                    <label className="form-label">Filtra per benchmark</label>
                                                    <div className="form-icon position-relative">
                                                        <Icon icon="bx:bx-building-house"
                                                              className="fea icon-sm icons"/>
                                                        <select
                                                            onChange={(event) => {
                                                                const value = event.target.value
                                                                this.setState((prevState) => ({
                                                                    ...prevState,
                                                                    filter: {
                                                                        benchmark: value
                                                                    }
                                                                }), () => {
                                                                    setBenchmarkFilterValue('benchmark', value);
                                                                    getPhotosAndVideosUpdates();
                                                                })
                                                            }}
                                                            value={this.props.media.filter.benchmark}
                                                            className="form-select form-control ps-5"
                                                            aria-label="Default select example">
                                                            {benchmarks.map((benchmark, key) => {
                                                                return (
                                                                    <option key={key}
                                                                            value={benchmark.campo_benchmark}>{benchmark.campo_benchmark}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {medias.length === 0 ?
                                                <div className='text-center custom_font'>Non sono
                                                    presenti
                                                    aggiornamenti</div> :
                                                <>
                                                    {medias.map((media, key) => {
                                                        return (
                                                            <div key={key}
                                                                 className="col-md-6 mt-4 pt-2 ">
                                                                <div className='card rounded border-0 shadow'>
                                                                    <div className="position-relative">
                                                                        {media.file_type.includes('image') ?
                                                                            <img
                                                                                src={imgBaseUrl.concat(clearApiBaseUrl(media.path))}
                                                                                className="card-img-top rounded-top"
                                                                                alt="..."/>
                                                                            :
                                                                            <div className='video'>
                                                                                <ReactPlayer
                                                                                    controls={true}
                                                                                    width={"fit-content"}
                                                                                    height={"fit-content"}
                                                                                    volume={0.5}
                                                                                    config={{file: {attributes: {controlsList: 'nodownload',disablepictureinpicture:'true',disableremoteplayback:'true'}}}}
                                                                                    url={imgBaseUrl.concat(clearApiBaseUrl(media.path))}/>
                                                                            </div>

                                                                        }

                                                                    </div>
                                                                    <div className="card-body content">
                                                                        <h6 className='text-muted text-left creation-date'>{formatUpdateCreationDate(media.data_creazione)}</h6>
                                                                        <h5 className='text-left'><a className="updates-title">{media.titolo}</a></h5>
                                                                        <h6 className='text-muted text-left'>{media.descrizione}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="col-lg-8 col-12">
                                    <div className="border-bottom pb-4">
                                        <div className="row">
                                            <div className="col-md-12 mt-4">
                                                <h5 className='custom_font'>Dati personali:</h5>
                                                <div className="mt-4">
                                                    <div
                                                        className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="bx:bx-user" width="17" height="17"
                                                              className="fea icon-ex-md text-muted me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Nome:</h6>
                                                            <p className="text-muted mb-0">{capitalize(currentUser?.nome)}</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="bx:bx-user" width="17" height="17"
                                                              className="fea icon-ex-md text-muted me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Cognome:</h6>
                                                            <p className="text-muted mb-0">{capitalize(currentUser?.cognome)}</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="bx:bx-building-house" width="17" height="17"
                                                              className="fea icon-ex-md text-muted me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Ragione
                                                                Sociale:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.ragione_sociale}</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="feather:map-pin" width="17" height="17"
                                                              className="fea icon-ex-md text-muted me-3"
                                                        />
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Via:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.via}</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="feather:map-pin" width="17" height="17"
                                                              className="fea icon-ex-md text-muted me-3"
                                                        />
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Città:</h6>
                                                            <p className="text-muted mb-0">{capitalize(currentUser?.citta_di_residenza)}</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="feather:map-pin" width="17" height="17"
                                                              className="fea icon-ex-md text-muted me-3"
                                                        />
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Cap:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.cap}</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="feather:map-pin" width="17" height="17"
                                                              className="fea icon-ex-md text-muted me-3"
                                                        />
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Provincia:</h6>
                                                            <p className="text-muted mb-0">{capitalize(currentUser?.provincia_di_residenza)}</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="ic:outline-email" width="17" height="17"
                                                              className="fea icon-ex-md text-muted me-3"
                                                        />
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Email:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.email}</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <Icon icon="clarity:phone-handset-line" width="17"
                                                              height="17"
                                                              className="fea icon-ex-md text-muted me-3"
                                                        />
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Telefono:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.telefono}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <Icon icon="lucide:divide-circle" width="17" height="17"
                                                              className="fea icon-ex-md text-muted me-3"
                                                        />
                                                        <div className="flex-1">
                                                            <h6 className="user_profile_text_green mb-0">Partita
                                                                IVA:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.partita_iva}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }

}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.user,
        auth: state.auth,
        media: state.media
    }
}


const mapDispatchToProps = {
    getCurrentUser,
    getAllBenchmarks,
    setBenchmarkFilterValue,
    getPhotosAndVideosUpdates
}


export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
