import React, {Component} from "react";


class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div id='privacy-policy'>
                <h2 className='text-center mt-2 mb-5'>Dichiarazione sulla Privacy di BASF</h2>
                <ul>Dichiarazione sulla Privacy di BASF
                    Ti diamo il benvenuto sul nostro sito web www.revyplus.it e ti ringraziamo per l’interesse ai
                    nostri servizi.
                    BASF prende in seria considerazione la protezione dei dati personali. La presente informativa
                    sulla
                    Privacy chiarisce:
                    <li>i dati personali che raccogliamo quando visiti il nostro sito web</li>
                    <li>le finalità per le quali utilizziamo tali dati</li>
                    <li>il fondamento giuridico per il trattamento dei dati personali</li>
                    <li>i destinatari di tali dati personali</li>
                    <li>il periodo di archiviazione di tali dati personali</li>
                    <li>se sei tenuto o meno a fornire i tuoi dati personali</li>
                </ul>
                <div>
                    <ul>Inoltre, vorremmo informarti in merito a:
                        <li>i diritti di cui godi in merito al trattamento dei dati personali</li>
                        <li>il Titolare del trattamento ai sensi della normativa vigente sulla protezione dei dati e,
                            ove
                            richiesto, il nostro Responsabile della protezione dei dati
                        </li>
                    </ul>
                </div>
                <ol className='bold'>1. Quali dati personali raccogliamo quando visiti il nostro sito web?</ol>
                <ol className='custom_ol'>(1) Quando visiti il nostro sito web</ol>
                <ul>Quando visiti il nostro sito web, senza contattarci o effettuare l’accesso, il tuo browser trasmette
                    automaticamente le seguenti informazioni al server di DigitalOcean, LLC localizzato ad Amsterdam,
                    North Holland, NL, postal code 1098:
                    <li>indirizzo IP del tuo computer</li>
                    <li>informazioni sul tuo browser</li>
                    <li>sito web sul quale ti trovavi prima dell’accesso al nostro sito web</li>
                    <li>URL o file richiesti</li>
                    <li>data e ora della visita</li>
                    <li>volume dei dati trasmessi</li>
                    <li>informazioni sullo stato, es. messaggi di errore</li>
                </ul>
                <ol>Quali sono le finalità di tale trattamento?</ol>
                <ol>
                    Il trattamento delle informazioni sopra indicate è necessario per inviare i contenuti richiesti al
                    tuo
                    browser. In tal modo, archiviamo l’indirizzo IP completo solo nella misura necessaria a rendere
                    disponibili le funzionalità del sito web da te richieste.
                </ol>
                <ol>
                    Inoltre, al fine di proteggerci dagli attacchi e salvaguardare il corretto funzionamento del nostro
                    sito
                    web, archiviamo questi dati in modo transitorio e con accesso limitato per un periodo massimo di 180
                    giorni. Tale periodo può essere esteso se e nella misura necessaria a perseguire attacchi e
                    incidenti.
                    Esamineremo l’utente di un dato indirizzo IP solo in caso di attacco illegale.
                </ol>
                <ol>Qual è il fondamento giuridico del trattamento?</ol>
                <ol>Il fondamento giuridico del trattamento dei dati personali è dato dal fatto che tale trattamento è
                    necessario per rendere disponibili le funzionalità del sito web richieste dall’utente (Art. 6 (1),
                    lettera b.)
                    del Regolamento Europeo sulla Protezione dei Dati Personali (General Data Protection Regulation).
                    Inoltre, l’archiviazione dei dati personali di cui sopra è effettuata per salvaguardare il nostro
                    interesse
                    legittimo, per difendere e proteggere il nostro sito web e per condurre delle indagini in caso di
                    attacchi
                    illegali (Art. 6 (1), lettera f.) Regolamento Europeo sulla Protezione dei Dati Personali).
                </ol>
                <ol className='custom_ol'>(2) Se ci contatti</ol>
                <ul>Se ci contatti via e-mail, riceviamo le seguenti informazioni:
                    <li>nome, cognome</li>
                    <li>il tuo indirizzo e-mail</li>
                    <li>data e ora del tuo messaggio</li>
                    <li>il tuo messaggio</li>
                </ul>
                <ol>Quali sono le finalità di tale trattamento?</ol>
                <ol>Se ci contatti via e-mail, utilizziamo i dati personali esclusivamente per elaborare la tua
                    richiesta.
                </ol>
                <ol>Qual è il fondamento giuridico del trattamento?</ol>
                <ol>
                    Il fondamento giuridico del trattamento è l’Art. 6 (1), lettera b.) Regolamento Europeo sulla
                    Protezione
                    dei Dati Personali (General Data Protection Regulation). L’interesse legittimo di BASF è dato dalle
                    finalità di trattamento sopra indicate.
                </ol>
                <ol className='custom_ol'>(3) Se ti registri al “Programma Revy+”</ol>
                <ol>Il nostro sito web offre l’opzione di iscriversi alla nostra Programma Revy+ per partecipare
                    all’iniziativa
                    promozionale e per visualizzare i relativi aggiornamenti.
                </ol>
                <ul>
                    Per iscriversi alla nostra area riservata del “Programma Revy+” dovrai compilare tutti i campi
                    contraddistinti con un asterisco presenti nel nostro format di registrazione. Dopo esserti
                    registrato al
                    Programma sul nostro sito web, ti invieremo quindi un messaggio all’indirizzo e-mail da te indicato
                    per
                    confermarti che la registrazione è avvenuta con successo. Per l’iscrizione tratteremo i seguenti
                    dati:
                    <li>indirizzo IP del computer che accede al sito</li>
                    <li>indirizzo e-mail</li>
                    <li>ora dell’iscrizione</li>
                    <li>nome e cognome</li>
                    <li>indirizzo/città/codice di avviamento postale/provincia</li>
                    <li>numero di telefono</li>
                    <li>partita IVA</li>
                    <li>denominazione azienda agricola</li>
                    <li>varietà coltivate</li>
                    <li>numero totale di ettari di superfice coltivabile</li>
                    <li>numero di ettari partecipanti all’iniziativa</li>
                    <li>codice fornito al momento dell’acquisto</li>
                </ul>
                <ol>Previo consenso da parte tua, raccoglieremo e utilizzeremo l’indirizzo e-mail da te indicato, così
                    come i
                    tuoi dati personali, come di seguito descritto, per dare seguito alle iniziative legate al Programma
                    Revy+ a cui ti sei iscritto:
                </ol>
                <ol>Quali sono le finalità del trattamento?</ol>
                <ol>
                    Lo scopo dell'area riservata al “Programma Revy+” e del trattamento di dati personali inseriti è
                    quello di
                    permetterti di partecipare all’iniziativa “Programma Revy+” e per visualizzare i relativi
                    aggiornamenti
                    disponibili sul nostro sito.
                </ol>
                <ol>Qual è il fondamento giuridico del trattamento?</ol>
                <ol>
                    Il fondamento giuridico del trattamento indicato al par. (3) di cui sopra è il consenso dell’utente
                    (Art. 6
                    (1), lettera a.) Regolamento Europeo sulla Protezione dei Dati Personali (General Data Protection
                    Regulation). Tale consenso può essere in futuro revocato in qualsiasi momento, annullando
                    l’iscrizione
                    al servizio o cancellando il proprio account.
                </ol>
                <ol className='bold'>2. Sei tenuto a fornire i tuoi dati?</ol>
                <ol>Quando visiti il nostro sito web, il tuo browser trasmette automaticamente le informazioni di cui
                    alla
                    Sezione 1 (1) al nostro server. Sei libero di trasmettere tali dati. Senza fornire tali dati non
                    siamo però in
                    grado di fornirti il contenuto richiesto.
                </ol>
                <ol>
                    Non sei obbligato a consentirci l’utilizzo dei tuoi dati anonimi per la misurazione dell’audience
                    web.
                    Inoltre, non sei obbligato a consentire l’utilizzo del tuo indirizzo IP per mappare la tua azienda o
                    le
                    informazioni relative alla tua attività.
                </ol>
                <ol>
                    Se desideri contattarci via e-mail, sei libero di trasmettere i dati di cui alla Sezione 1 (2).
                </ol>
                <ol>
                    Sei libero di scegliere se desideri ricevere informazioni sui prodotti e servizi offerti da BASF nel
                    campo
                    della protezione delle colture.
                </ol>
                <ol>
                    Se intendo registrarti al Programma Revy+, è necessario che tu ci fornisca i dati richiesti come
                    sopra
                    indicati.
                </ol>
                <ol className='bold'>3. A quali destinatari vengono trasmessi i tuoi dati?</ol>
                <ol>
                    Trasmettiamo i dati menzionati al paragrafo 1 a Responsabili del Trattamento dei Dati aventi sede
                    nell’Unione Europea per le finalità stabilite al paragrafo 2. Tali Responsabili del Trattamento
                    trattano i
                    dati personali esclusivamente sulla base delle informazioni agli stessi pervenute da parte nostra e
                    per
                    nostro nome e conto.
                </ol>
                <ul>
                    I tuoi dati personali potranno essere trasmessi senza il tuo consenso espresso solo qualora sia
                    legalmente consentito o necessario.
                    <li>I dati da te forniti al momento dell’iscrizione al Programma Revy+ saranno condivisi
                        all’interno del nostro gruppo di società per scopi amministrativi interni, compresa
                        l’assistenza ai clienti condivisa, se necessario. Tale trasferimento avviene nel nostro
                        legittimo interesse a trattare i dati per scopi amministrativi nell’ambito del nostro gruppo di
                        società.
                    </li>
                    <li>I tuoi dati personali possono essere trasmessi alle autorità nel contesto della rispettiva
                        competenza territoriale (es. autorità tributarie, forze dell’ordine, magistratura). I dati
                        saranno
                        trasferiti poiché abbiamo un obbligo di legge a trasmettere i dati, oppure perché è nostro
                        legittimo interesse condividere tali dati per rilevare abusi o in sede giudiziaria.
                    </li>
                    <li>Al momento dell’iscrizione al Programma Revy+ i dati forniti saranno trasmessi ai
                        seguenti fornitori di servizi, da cui dipendiamo per i nostri servizi: Akinda Italia S.r.l. via
                        Margherita De Vizzi 58 - 20092 Cinisello Balsamo.
                    </li>
                    <li>Al momento dell’iscrizione al Programma Revy+ i dati forniti saranno trasmessi al
                        distributore dei prodotti BASF oggetto del Programma e che ti ha fornito il coupon per
                        partecipare al Programma stesso. Tale trasferimento avviene per la finalità di dare
                        esecuzione al Programma Revy+.
                    </li>
                </ul>
                <ol>
                    Abbiamo selezionato con cura le nostre società esterne e i nostri fornitori di servizi esterni come
                    responsabili del trattamento ed essi hanno l’obbligo contrattuale di trattare tutti i dati personali
                    esclusivamente nel rispetto delle nostre istruzioni.
                </ol>
                <ol>
                    Nelle transazioni per cui la struttura della nostra società può risultare modificata, le
                    informazioni sui
                    clienti potrebbero essere condivise con la parte della società da trasferire. Qualsiasi trasmissione
                    di dati
                    personali è giustificata dal fatto che abbiamo il legittimo interesse di adattare la nostra forma di
                    attività
                    alle circostanze economiche e giuridiche richieste.
                </ol>
                <ol className='bold'>4. Come proteggiamo i tuoi dati personali?</ol>
                <ol>Abbiamo implementato misure tecniche e organizzative al fine di assicurare un adeguato livello di
                    sicurezza per proteggere i tuoi dati personali da accidentale o illecita modifica, distruzione,
                    perdita o
                    diffusione non autorizzata. Tali misure saranno costantemente implementate, in linea con gli
                    sviluppi
                    tecnologici.
                </ol>
                <ol className='bold'>5. Periodo di archiviazione dei dati</ol>
                <ol>
                    Cancelliamo, o rendiamo anonimi i tuoi dati personali non appena non sono più richiesti ai fini per
                    cui si
                    procede al loro trattamento, come indicato nei paragrafi di cui sopra, salvo che la loro
                    cancellazione o
                    blocco non risultino in violazione di un obbligo di legge a presentare e conservare i registri (come
                    ad
                    esempio il periodo di archiviazione prescritto dalle norme tributarie o in materia di commercio).
                </ol>
                <ol>
                    I dati personali trattati in base al tuo consenso saranno archiviati fino a quando tale consenso non
                    sarà
                    revocato.
                </ol>
                <ol className='bold'>6. Quali sono i tuoi diritti?</ol>
                <ol>Ti sono riconosciuti una serie di diritti ai sensi del Regolamento Europeo sulla Protezione dei Dati
                    Personali, compresa la facoltà di richiedere copia delle informazioni personali che ti riguardano da
                    ni
                    detenute, se richiedi le stesse in forma scritta:
                </ol>
                <ol>
                    6.1. Diritto di Accesso: il diritto di ottenere l’accesso ai tuoi dati (se sono oggetto di
                    trattamento da parte
                    nostra) e ad altre informazioni (quali quelle fornite in questa informativa sulla privacy).
                </ol>
                <ol>
                    6.2. Diritto di Rettifica: nel caso in cui i tuoi dati personali siano inesatti o incompleti, è
                    prevista la tua
                    facoltà di chiedere la rettifica delle tue informazioni personali.
                </ol>
                <ol>
                    6.3. Diritto alla Cancellazione: esso è anche conosciuto come “diritto all’oblio” e, in sintesi,
                    consente di
                    richiedere la deindicizzazione o la rimozione delle tue informazioni nel caso in cui non vi sia una
                    valida
                    ragione per continuare a utilizzare le stesse. Esso non costituisce un diritto generale alla
                    cancellazione;
                    ci sono infatti delle eccezioni. Per esempio, è nostro diritto continuare a utilizzare i tuoi dati
                    personali se
                    tale utilizzo è necessario ai fini del rispetto dei nostri obblighi di legge o per accertare,
                    esercitare o
                    difendere un diritto in sede giudiziaria.
                </ol>
                <ol>
                    6.4. Diritto di limitazione al trattamento delle tue informazioni: è il diritto di sospendere
                    l’utilizzo delle tue
                    informazioni personali o limitare le modalità di utilizzo delle stesse da parte nostra. Va
                    rammentato che
                    tale diritto è limitato a situazioni definite: nel caso di trattamento delle tue informazioni
                    personali che
                    abbiamo raccolto da te tramite il tuo consenso, è tua facoltà richiedere la limitazione
                    dell’utilizzo delle
                    stesse solo in caso di: (a) inesattezza dei dati, (b) il nostro trattamento è illecito e non vuoi
                    che i tuoi
                    dati siano cancellati, (c) lo necessiti per ragioni legali, o (d) se non abbiamo più necessità di
                    utilizzare i
                    dati per le finalità per le quali li abbiamo conservati. Quando il trattamento dei dati è limitato,
                    è ancora
                    nostra facoltà conservare esclusivamente le informazioni, ma non utilizzarle ulteriormente.
                    Conserviamo degli elenchi dei soggetti che hanno richiesto la limitazione al trattamento dei propri
                    dati
                    personali per essere sicuri che tali limitazioni siano osservate anche in futuro.
                </ol>
                <ol>
                    6.5. Diritto alla Portabilità dei Dati: il diritto di richiederci di spostare, copiare o trasferire
                    (se
                    tecnicamente possibile) i tuoi dati personali in formato strutturato, di uso comune e in un formato
                    leggibile da dispositivo automatico, per il tuo proprio utilizzo tramite diversi servizi.
                </ol>
                <ol>
                    6.6. Diritto di Opposizione: il diritto di opposizione all’utilizzo da parte nostra dei tuoi dati
                    personali
                    anche con riferimento a tale utilizzo per nostri interessi legittimi o per finalità di marketing
                    diretto.
                </ol>
                <ol>
                    6.7. Diritto di essere informato: è riconosciuto il tuo diritto a vederti fornire informazioni
                    chiare,
                    trasparenti e facilmente comprensibili circa le modalità di utilizzo da parte nostra dei tuoi dati
                    personali.
                </ol>
                <ol>
                    6.8. Diritto di Revoca del Consenso: se hai prestato il tuo consenso per qualsiasi operazione da noi
                    svolta con i tuoi dati personali, hai il diritto di revocare tale consenso in ogni momento (questo
                    non
                    significa che quanto da noi compiuto con i tuoi dati personali tramite il tuo precedente consenso,
                    fino al
                    momento della revoca, è illecito).
                </ol>
                <ol>
                    L’esercizio di tali diritti è gratuito, ma è richiesta l’esibizione di due prove documentali al fine
                    di
                    comprovare la corretta identificazione dell’avente diritto. Ci impegniamo a porre in essere tutti
                    gli sforzi
                    necessari in accordo con il nostro dovere legale di fornire, correggere o cancellare i dati
                    personali che ti
                    riguardano sui nostri file.
                </ol>
                <ol>
                    Per presentare richieste o esercitare qualsiasi dei tuoi diritti individuati nella presente
                    Informativa sulla
                    Privacy e/o per presentare un reclamo, ti preghiamo di contattarci a mezzo e-mail o scriverci e
                    cercheremo di fornirti riscontro entro il termine di 30 giorni. Le informazioni per contattarci sono
                    reperibili nella Sezione 10 che segue.
                </ol>
                <ol>
                    Nel momento della ricezione di formali reclami per iscritto, ci impegniamo a contattare il
                    richiedente al
                    fine di seguire detto reclamo. Lavoriamo a stretto contatto con le rispettive Autorità di
                    regolamentazione
                    competenti, compresa la locale Autorità per la Protezione dei Dati, al fine di risolvere
                    qualsivoglia
                    reclamo che non siamo in grado di definire direttamente.
                </ol>
                <ol>
                    È tua facoltà, qualora non sia soddisfatto delle modalità di gestione da parte nostra del reclamo
                    presentato in relazione ai tuoi dati personali, di indirizzare il tuo reclamo alla competente
                    Autorità per la
                    Protezione dei Dati Personali.
                </ol>
                <ol className='bold'>7. Dove puoi presentare un reclamo?</ol>
                <ol>Hai facoltà di presentare un reclamo ai nostri contatti sotto riportati (DPO e DPC), oppure presso
                    l’autorità competente oppure presso la seguente autorità:
                </ol>
                <ol>Responsabile della protezione dei dati e della libertà di informazione del Land Rheinland-Pfalz</ol>
                <ol>Hintere Bleiche 34</ol>
                <ol>55116 Meinz</ol>
                <ol>E-mail:<a className='text_green'
                              href='mailto:poststelle@datenschutz.rlp.de'>poststelle@datenschutz.rlp.de</a>
                </ol>
                <ol><a className='text_green'
                       target="_blank"
                       href='https://www.datenschutz.rlp.de/de/general-storage/footer/ueber-den-lfdi/kontakt/'>https://www.datenschutz.rlp.de/de/general-storage/footer/ueber-den-lfdi/kontakt/</a>
                </ol>
                <ol className='bold'>Chi è il Titolare del Trattamento dei Dati?</ol>
                <ol>Il Titolare del Trattamento dei Dati è:</ol>
                <ol>BASF Italia S.p.A.</ol>
                <ol>Via Marconato, 8</ol>
                <ol>Cesano Maderno (MB)</ol>
                <ol>Italia</ol>
                <ol className='bold'>Chi è il Responsabile del Trattamento dei Dati e il
                    Coordinatore della Protezione dei Dati?
                </ol>
                <ol>Il Responsabile del Trattamento dei Dati (Data Protection Officer o DPO) del Gruppo BASF è:</ol>
                <ol>Alexandra Haug</ol>
                <ol><a className='text_green ' href='mailto:data-protection.eu@basf.com'>data-protection.eu@basf.com</a>
                </ol>
                <ol>Il Coordinatore della Protezione dei Dati (Data Protection Coordinator o DPC) è:</ol>
                <ol>Sara Biscardini</ol>
                <ol>28011 Cesano Maderno</ol>
                <ol>+39 0362 5121</ol>
                <ol><a className='text_green' href='mailto:data-protection.it@basf.com'>data-protection.it@basf.com</a>
                </ol>

            </div>
        )
    }
}


export default PrivacyPolicy;