import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setRedirectToHomeFalse } from '../store/user/actions'

import background_image from '../assets/images/bg.svg'
import first_image from '../assets/images/img1.jpg'
import second_image from '../assets/images/img2.jpg'
import { jarallax } from 'jarallax'
import cookie from 'react-cookies'

import '../assets/css/bootstrap.min.css'
import '../assets/css/tobii.min.css'
import '../assets/css/materialdesignicons.min.css'
import '../assets/css/style.min.css'
import '../assets/css/custom.css'
import '../assets/css/colors/green.css'

import dotenv from "dotenv";
dotenv.config();

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    jarallax(document.querySelectorAll('.jarallax'), { speed: 0.5 })

    const { user, setRedirectToHomeFalse } = this.props
    if (user.redirect_to_home) {
      setRedirectToHomeFalse()
    }
  }

  render() {
    return (
        <React.Fragment>
          <section className="bg-auth-home d-table w-100 jarallax jarallax-img" style={{background: `url(${background_image})`}}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="title-heading">
                    <h1 className="heading fw-bold mb-5 mb-sm-2  mt-4 text-white">
                      Zgjerojmë Rrjetin, Forcojmë Miqësinë!
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="position-relative">
            <div className="shape overflow-hidden text-light">
              <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"/>
              </svg>
            </div>
          </div>

          <section className="bg-light">
            <div className="container pt-5 pb-5">
              <div className="row align-items-center">
                <h3 className="section-title title mb-4 text_green" style={{marginTop: '3rem'}}>
                  {/*Programi ynë*/}
                  Platforma e parë online e lidhjes së bizneseve, profesionistëve, ekspertëve dhe OJQ-ve të Shqiptarëve
                  nëpër botë.

                </h3>
                <div className="col-lg-6 col-md-6 mt-6 mt-sm-0 pt-9 pt-sm-0">
                  <div className="section-title me-lg-5 font_helvetica" style={{textAlign: 'justify'}}>
                    <p className="text-black">
                      Tek Albanian Diaspora Business Database krenohemi me bashkimin e vlerave sipërmarrëse shqiptare në
                      mbarë botën. Sapo të regjistroni profilin do të bëheni pjesë e një rrjeti të bizneseve,
                      profesionistëve, ekspertëve dhe OJQ-ve shqiptare në një platformë globale.
                    </p>

                    <p>
                      Misioni ynë është të krijojmë një hapësirë dixhiitale dinamike që nxit bashkëpunimin, inovacionin
                      dhe
                      rritjen brenda komunitetit shqiptar në nivel botëror.
                    </p>

                    <p>
                      Së bashku, ndërtojmë ura nëpër kontinente, fuqizojmë ndërmarrjet shqiptare të triumfojnë në skenën
                      ndërkombëtare.
                    </p>
                    <p>
                      <b>Bashkohuni me Albanian Diaspora Business Database dhe bëhuni pjesë e një komuniteti ku suksesi nuk
                        njeh kufij!</b>
                    </p>

                    <a
                        className="mt-5 btn btn-primary border-button"
                        style={{padding: '10px 70px'}}
                        onClick={() => this.props.history.push('/register')}
                    >
                      <i className="uil uil-arrow-circle-right" style={{marginRight: '5px'}}/>
                      Regjistrohu
                    </a>

                  </div>
                </div>
                <div className="col-lg-6 col-md-6 order-1 order-md-2 container pt-5 pb-5">
                  <img
                      src={first_image}
                      className="img-fluid"
                      alt="Programi ADDW"
                  />
                </div>
                {/* <div className="col-md-6 offset-md-3 text-center order-2 mt-2 mt-sm-5">
                {!cookie.load('token') && (
                  <div className="col-md-6 offset-md-3 text-center order-2 mt-2 mt-sm-5">
                    <a
                      className="mt-3 btn btn-primary border-button"
                      style={{ padding: '10px 70px' }}
                      onClick={() => this.props.history.push('/register')}
                    >
                      <i className="uil uil-arrow-circle-right" style={{marginRight: '5px'}}/>
                      Regjistrohu
                    </a>
                  </div>
                )}
              </div> */}
              </div>

            </div>
          </section>

          <section className="bg-light">
            <div className="container pt-5 pb-5">
              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div className="section-title me-lg-5 font_helvetica">
                    <h2 className="section-title title mb-4 text_green">
                      Plot arsye përse të bashkohesh edhe ti:
                    </h2>

                    <ul style={{textAlign: 'justify'}}>
                      <li className="space_bullet mt-4">
                        <b>Mundësi Globale për Rrjetëzim: </b>
                        Lidhuni me një rrjet të gjerë të bizneseve shqiptare, profesionistëve, ekspertëve dhe OJQ-ve nga
                        e gjithë bota. Keni akses në një komunitet global, afër dhe larg, që hap dyert për bashkëpunim,
                        partneritete dhe lidhje të vlefshme.
                      </li>
                      <li className="space_bullet">
                        <b>Sherbime One-Stop-Shop Për Biznesin: </b>
                        Akses në shërbimet e plota të "Si të bësh Biznes në Shqipëri" përmes qendrës së shërbimeve të
                        Dhomës së Biznesit të Diasporës Shqiptare. Merrni udhëzim dhe burime eksperte për një përvojë të
                        lehtë në biznes në Shqipëri.
                      </li>
                      <li className="space_bullet">
                        <b>Akses në mundësi dhe burime: </b>

                        Mbahuni të informuar rreth një sërë mundësish, burimesh dhe ngjarjesh që kanë rëndësi për
                        bizneset, profesionistët dhe OJQ shqiptare si prsh perspektivat e investimeve, oferta pune, apo
                        mundësi financimi, platforma shërben si një qendër e centralizuar për informacion të
                        vlefshëm.
                      </li>
                      <li className="space_bullet">
                        <b>Promovim i aftësive dhe shërbimeve tuaja: </b>
                        Përdorni platformën për të promovuar dhe shfaqur biznesin tuaj, aftësitë profesionale, apo
                        misionin dhe shërbimet e OJQ-së suaj. Ky është një rast për të theksuar ekspertizën tuaj,
                        projektet dhe nismat për një audiencë më të gjerë ndërkombëtare, duke tërhequr klientë
                        potencialë, partnerë, ose mbështetës.
                      </li>
                      <li className="space_bullet">
                        <b>Kontriboni në Rritjen e Sektorit Privat si edhe zhvillimit të diasporës: </b>
                        Kontribuoni në rritjen dhe zhvillimin e sektorit privat duke marrë pjesë aktivisht në nismat dhe
                        aktivitetet. Bashkimi në këtë platformë lejon bizneseve, profesionistëve dhe OJQ-ve të luajnë
                        një
                        rol në formimin e mjedisit të biznesit të diasporës shqiptare.
                      </li>
                      <li className="space_bullet">
                        <b>Plotëso kënaqësinë tënde </b>duke lidhur konsumatorin shqiptar me produktin/shërbimin
                        tënd.
                      </li>
                      <li className="space_bullet">
                        <b>Krijo një derë mikrpitëse në çdo shtet. Përfito antarësimin pranë Dhomës së Biznesit të Diasporës pa pagesë për vitin e parë.</b>
                      </li>
                    </ul>

                    <a
                        className="mt-5 btn btn-primary border-button"
                        style={{padding: '10px 70px'}}
                        onClick={() => this.props.history.push('/register')}
                    >
                      <i className="uil uil-arrow-circle-right" style={{marginRight: '5px i'}}/>
                      Lidhu me rrjetin tënd të Diasporës
                    </a>

                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="bg-light">
            <div className="container pt-5 pb-5">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 order-1 order-md-2" style={{textAlign: 'justify'}}>
                  <p>
                    <b>Dhoma e Biznesit të Diasporës Shqiptare</b> është një ent publik jobuxhetor dhe jo-fitimprurës.
                      Mekanizmi që ofron promovimin, zhvillimin dhe nxitjen e kapitaleve Investuese nga Diaspora në
                      Shqipëri.
                  </p>
                  <p>
                    Dhoma u asistua dhe u iniciua si një projekt i Fondit Shqiptar të Zhvillimit për Diasporën, me
                    mision krijimin dhe nxitjen e investimeve të Diasporës në Shqipëri.
                  </p>
                  <p>
                    Çdo vit, numërojmë arritje, zgjerim, kontribut direkt tek anëtarët dhe kualifikim të mëtejshëm.
                  </p>
                  <p>
                    Per me shumë, vizitoni website: <a href="https://www.adbc.al/" target="_blank">https://adbc.al/</a>
                  </p>


                  {!cookie.load('token') && (
                      <a
                          className="mt-5 btn btn-primary border-button"
                          style={{padding: '10px 70px'}}
                          onClick={() => this.props.history.push('/register')}
                      >
                        <i className="uil uil-arrow-circle-right" style={{marginRight: '5px'}}/>
                        Regjistrohu
                      </a>
                  )}


                </div>
                <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div className="section-title me-lg-5 font_helvetica mb-4">
                    <img src={second_image} className="img-fluid" alt="Programi ADDW"/>
                  </div>
                </div>

              </div>
            </div>
          </section>
        </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = {
  setRedirectToHomeFalse,
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)