import {
    GET_PROFILE_CATEGORIES,
    REGISTER_NEW_USER,
    REGISTER_TO_NEWSLETTER,
    SET_ERROR_CODE_FALSE,
    SET_ERROR_MAP_FALSE,
    SET_ERROR_MAP_TRUE,
    SET_MAP_CODE_SELECTED,
    SET_NEW_UPDATE_VALUE,
    SET_REDIRECT_TO_HOME_FALSE,
    SET_REGISTRATION_VALUE,
    SET_SHAREHOLDER_VALUE,
    UPLOAD_MEDIA,
    UPLOAD_NEW_UPDATE,
    CLEAR_FORM,
    SET_REGISTER_TO_NEWSLETTER_VALUE,
} from "./actionTypes";


export const setNewUpdateValue = (field, value) => {
    return {
        type: SET_NEW_UPDATE_VALUE,
        payload: {
            field,
            value
        }
    }
}

export const setRegistrationValue = (field, value) => {
    return {
        type: SET_REGISTRATION_VALUE,
        payload: {
            field,
            value
        }
    }
}

export const setRegisterToNewsletterValue = (field, value) => {
    return {
        type: SET_REGISTER_TO_NEWSLETTER_VALUE,
        payload: {
            field,
            value
        }
    }
}

export const registerToNewsletter = (data) => {
    return {
        type: REGISTER_TO_NEWSLETTER,
        payload: data,
        api: true
    }
}

export const setShareholderValue = (field, value) => {
    return {
        type: SET_SHAREHOLDER_VALUE,
        payload: {
            field,
            value
        }
    }
}

export const setErrorMapFalse = () => {
    return {
        type: SET_ERROR_MAP_FALSE
    }
}

export const setErrorMapTrue = () => {
    return {
        type: SET_ERROR_MAP_TRUE
    }
}


export const registerNewUser = (data) => {
    return {
        type: REGISTER_NEW_USER,
        payload: data,
        api: true
    }
}


export const uploadNewUpdate = (selectedFiles) => {
    const formData = new FormData();
    // formData.append('user_id', selectedFiles.user_id);
    formData.append('file_reference', selectedFiles.file_reference);

    for (const file of selectedFiles) {
      formData.append('files', file);
    }
    console.log("formData 1111111111",formData)
    return {
        type: UPLOAD_NEW_UPDATE,
        payload: formData,
        api: true
    }
}

export const setMapCodeSelected = (code) => {
    return {
        type: SET_MAP_CODE_SELECTED,
        payload: code
    }
}

export const setRedirectToHomeFalse = () => {
    return {
        type: SET_REDIRECT_TO_HOME_FALSE
    }
}

export const setErrorCodeFalse = () => {
    return {
        type: SET_ERROR_CODE_FALSE
    }
}

export const uploadMedia = (file) => {
    console.log("uploading file", file);
    return {
        type: UPLOAD_MEDIA,
        payload: file
    }
}

export const getProfileCategories = () => {
    return {
        type: GET_PROFILE_CATEGORIES,
    }
}

export const clearForm = () => {
    return {
        type: CLEAR_FORM
    }
}