export const district_list = [
    { id: 1, name: 'Qarku i Tiranës' },
    { id: 2, name: 'Qarku i Vlorës' },
    { id: 3, name: 'Qarku i Shkodrës' },
    { id: 4, name: 'Qarku i Lezhës' },
    { id: 5, name: 'Qarku i Kukësit' },
    { id: 6, name: 'Qarku i Korçës' },
    { id: 7, name: 'Qarku i Gjirokastrës' },
    { id: 8, name: 'Qarku i Fierit' },
    { id: 9, name: 'Qarku i Elbasanit' },
    { id: 10, name: 'Qarku i Durrësit' },
    { id: 11, name: 'Qarku i Dibrës' },
    { id: 12, name: 'Qarku i Beratit' },
];


export const origin_list = [
    {id: 1, name: 'Shqiptare', code: 'AL'},
    {id: 2, name: 'Kosove', code: 'KS'},
    {id: 3, name: 'Maqedonia e Veriut', code: 'MQV'},
    {id: 4, name: 'Lugina e Presheves', code: 'LP'},
    {id: 5, name: 'Arberesh', code: 'ARB'},
];



export const states_list = [
    {id: 1, name: 'Itali'},
    {id: 2, name: 'Greqi'},
    {id: 3, name: 'ShBA'},
    {id: 4, name: 'Gjermani'},
    {id: 5, name: 'Kanada'},
    {id: 6, name: 'Mbretëria e Bashkuar'},
    {id: 7, name: 'Belgjikë'},
    {id: 8, name: 'Turqi'},
    {id: 9, name: 'Zvicër'},
    {id: 10, name: 'Hollandë'},
    {id: 11, name: 'Shqipëri'},
    {id: 12, name: 'Vënd të tjera'},
];



export const area_list = [
    {id: 1, name: 'Aktivitete të arkitekturës dhe inxhinierisë'},
    {id: 2, name: 'Industria automobile'},
    {id: 3, name: 'Industria përpunuese'},
    {id: 4, name: 'Inteligjenca artificiale'},
    {id: 5, name: 'Ndërtim'},
    {id: 6, name: 'Organizatë Jofitimprurëse'},
    {id: 7, name: 'Pasuritë e patundshme'},
    {id: 8, name: 'Prodhim'},
    {id: 9, name: 'Shërbime edukimi'},
    {id: 10, name: 'Shërbime financiare'},
    {id: 11, name: 'Shërbime për biznesin'},
    {id: 12, name: 'Tregti produkte ushqimore'},
    {id: 13, name: 'Tjetër'},
];

export const legal_status_list = [
    {id: 1, name: 'SH.P.K., Shoqëri me përgjegjësi të kufizuara'},
    {id: 2, name: 'Sh.A. Shoqeri Aksionare'},
    {id: 3, name: 'PF. Person Fizik'},
    {id: 4, name: 'OJF. Organizatë Jofitimprurëse'},
    {id: 5, name: 'Tjetër'},
];


export const education_list = [
    {id: 1, name: 'I ulët'},
    {id: 2, name: 'I mesëm'},
    {id: 3, name: 'I lartë'},
    {id: 4, name: 'Phd'},
];


export const property_list = [
    {id: 1, name: 'Arberesh'},
    {id: 2, name: 'Shqiptare'},
    {id: 3, name: 'E perbashket'},
    {id: 4, name: 'Kosova'},
    {id: 5, name: 'Maqedonia e Veriut'},
    {id: 6, name: 'Lugina e Presheves'},
    {id: 7, name: 'Mali i Zi'},
    {id: 8, name: 'Perbashket(shqiptare e huaj)'},
    {id: 9, name: 'Perbashket(diaspora)'},    
    {id: 10, name: 'Shto edhe Cilëso'},
];


export const bussines_categories = [
    {id: 1, name: "Profil Biznesi", value: "PB"},
    {id: 2, name: "Profil i Sipërmarrësit të vetëpunësuar", value: "PSV"},
    {id: 3, name: "Profil Lidershipi", value: "PL"},
    {id: 4, name: "Profil Eksperti", value: "PE"},
    {id: 5, name: "Profil Individi", value: "PI"},
    {id: 6, name: "Profil Organizate Jofitimprurese", value: "POJF"}
];