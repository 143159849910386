import {
    SET_SHAREHOLDER_VALUE,
    SET_ERROR_MAP_FALSE,
    SET_ERROR_MAP_TRUE,
    SET_REGISTRATION_VALUE,
    SET_REGISTER_TO_NEWSLETTER_VALUE,
    REGISTER_NEW_USER,
    REGISTER_TO_NEWSLETTER,
    SET_MAP_CODE_SELECTED,
    SET_REDIRECT_TO_HOME_FALSE,
    SET_ERROR_CODE_FALSE,
    GET_PROFILE_CATEGORIES,
    UPLOAD_MEDIA,
    CLEAR_FORM
} from "./actionTypes";

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import cookie from "react-cookies";
import {SET_NEW_UPDATE_VALUE, UPLOAD_NEW_UPDATE} from "../user/actionTypes";

function setTokenCookie(token) {
    cookie.save("token", token, {
        "path": "/"
    });
}


const initialState = {
    registration_data: {},
    newsletter_data:{},
    bussines_img: null,
    product_or_service_img: null,
    profile_img: null,
    error_map: false,
    map_code_selected: '',
    redirect_to_home: false,
    code_error: false,
    new_update: {
        bussines_img: null
    },
    upload_loading: false,
    uploaded_documents: [],
};

export default (state = initialState, action) => {
    let shareholder_data;
    let registrationData;
    let newsletterData;
    let error_map;
    let map_code_selected;
    let redirect_to_home;
    let code_error;
    let profile_categories;
    let new_update;
    let uploaded_documents;
    let upload_loading;
    switch (action.type) {
        case SET_REGISTRATION_VALUE:
            registrationData = Object.assign({}, state.registration_data);
            console.log(state, registrationData)
            registrationData[action.payload.field] = action.payload.value;
            state = {
                ...state,
                registration_data: registrationData
            }
            break;
        case SET_REGISTER_TO_NEWSLETTER_VALUE:
            newsletterData = Object.assign({}, state.newsletter_data);
            console.log(state, newsletterData)
            newsletterData[action.payload.field] = action.payload.value;
            state = {
                ...state,
                newsletter_data: newsletterData
            }
            break;
        case SET_SHAREHOLDER_VALUE:
            shareholder_data = Object.assign({}, state.shareholder_data);
            shareholder_data[action.payload.field] = action.payload.value;
            state = {
                ...state,
                shareholder_data: shareholder_data
            }
            break;
        case SET_ERROR_MAP_FALSE:
            error_map = state.error_map;
            error_map = false;
            state = {
                ...state,
                error_map: error_map
            }
            break;
        case SET_ERROR_MAP_TRUE:
            error_map = state.error_map;
            error_map = true;
            state = {
                ...state,
                error_map: error_map
            }
            break;
        case REGISTER_NEW_USER.concat("_SUCCESS"):
            toast.success('Të dhënat e profilit u ruajtën me sukses!');
            setTokenCookie(action.payload.token);
            redirect_to_home = state.redirect_to_home;
            redirect_to_home = true;
            state = {
                ...state,
                redirect_to_home: redirect_to_home
            }
            break;
        case REGISTER_NEW_USER.concat("_FAILURE"):
            toast.error(action.payload.data.message);
            code_error = state.code_error;

            if (action.payload.data.errorCode === "999") {
                code_error = true;
            }
            state = {
                ...state,
                code_error: code_error
            }
            break;

        case REGISTER_TO_NEWSLETTER.concat("_SUCCESS"):
            toast.success(action.payload.message);
            redirect_to_home = state.redirect_to_home;
            redirect_to_home = true;
            state = {
                ...state,
                redirect_to_home: redirect_to_home
            }
            break;
        case REGISTER_TO_NEWSLETTER.concat("_FAILURE"):
            toast.error(action.payload.data.message);
            code_error = state.code_error;

            if (action.payload.data.errorCode === "999") {
                code_error = true;
            }
            state = {
                ...state,
                code_error: code_error
            }
            break;
        case SET_MAP_CODE_SELECTED:
            map_code_selected = state.map_code_selected;
            map_code_selected = action.payload;
            state = {
                ...state,
                map_code_selected: map_code_selected
            }
            break;
        case SET_REDIRECT_TO_HOME_FALSE:
            redirect_to_home = state.redirect_to_home;
            redirect_to_home = false;
            state = {
                ...state,
                redirect_to_home: redirect_to_home
            }
            break;
        case SET_ERROR_CODE_FALSE:
            code_error = state.code_error;
            code_error = false;
            state = {
                ...state,
                code_error: code_error
            }
            break;
        case GET_PROFILE_CATEGORIES:
            profile_categories = action.payload;
            console.log("ERNEST", profile_categories);
            state = {
                ...state,
                profile_categories: profile_categories
            }
            break;
        case SET_NEW_UPDATE_VALUE:
            new_update = Object.assign({}, state.new_update);
            new_update[action.payload.field] = action.payload.value;
            state = {
                ...state,
                new_update: new_update
            }
            break;
        case UPLOAD_NEW_UPDATE:
            upload_loading = state.upload_loading;
            upload_loading = true;
            state = {
                ...state,
                upload_loading: upload_loading
            }
            break;
        case UPLOAD_NEW_UPDATE.concat('_SUCCESS'):
            upload_loading = state.upload_loading;
            upload_loading = false;
            state = {
                ...state,
                upload_loading: upload_loading
            }
            toast.success('Foto u ngarkua me sukses!')
            break;
        case UPLOAD_NEW_UPDATE.concat('_FAILURE'):
            upload_loading = state.upload_loading;
            upload_loading = false;
            state = {
                ...state,
                upload_loading: upload_loading
            }
            toast.error('Ngarkimi i fotos dështoi!');
            break;
        case UPLOAD_MEDIA:
            uploaded_documents = action.payload;

            console.log("DOCUMENTS_UPLOAD", uploaded_documents)
            state = {
                ...state,
                uploaded_documents: uploaded_documents
            }

            break;
        case CLEAR_FORM:
            new_update = Object.assign({}, state.new_update);
            new_update.benchmark = '';
            new_update.title = '';
            new_update.description = '';
            new_update.bussines_img = null;
            state = {
                ...state,
                new_update: new_update
            }
            break;
        default:
            state = { ...state };
            break;
    }

    return state;
};
