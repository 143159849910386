import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect, withRouter} from 'react-router-dom';
import ReactPlayer from "react-player";
import DotLoader from "react-spinners/DotLoader";


import {Icon} from "@iconify/react";

import {getCurrentUser} from "../store/auth/actions";

import {
    getClientsForBasfByDistributor,
    getBasfDistributors,
    getDistributorsCompetenceAreas,
    setBasfSelectValue,
    getClientDetailsForBasfPreview,
    clearClientDetails,
    clearClients,
    clearDistributors,
    setNewUpdateValue,
    uploadNewUpdate,
    uploadMedia,
    getDistributorName
} from "../store/basf/actions";

import {
    getAllBenchmarks,
    setBenchmarkFilterValue,
    getPhotosAndVideosUpdates,
    getUserData
} from "../store/common/actions";

import {capitalize, clearApiBaseUrl, formatUpdateCreationDate, max_file_size} from "../assets/helpers/helpers";

import cereali_img from "../assets/images/icon/cereali.svg";


class BasfPersonnelProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile_open: true,
            distributors_open: false,
            updates_open: false,
            distributors_clients_table: false,
            params: props.basf.params,
            new_update: props.basf.new_update,
            errors: {
                area: false,
                distributor: false
            },
            form_errors: {}
        };
    }

    componentDidMount() {
        const {getAllBenchmarks, media} = this.props;

        if (media.benchmarks.list.data.length === 0 && media.benchmarks.list.loading === false) {
            getAllBenchmarks();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.updates_open !== this.state.updates_open) {
            const {setBenchmarkFilterValue, getPhotosAndVideosUpdates, media, setNewUpdateValue} = this.props;
            if (media.filter.benchmark === '') {
                const value = media.benchmarks.list.data[0]?.campo_benchmark;
                setBenchmarkFilterValue('benchmark', value);
                setNewUpdateValue('benchmark', value)
                getPhotosAndVideosUpdates();
            }
        }
    }

    handleToggle = (type) => {
        const {clearClientDetails} = this.props;
        const client_detail = this.props.basf.client_detail;

        if (client_detail !== null) {
            clearClientDetails();
        }

        if (type === 'profile') {
            this.setState({
                profile_open: true,
                distributors_open: false,
                updates_open: false,
                distributors_clients_table: false,
                single_client_detail: false
            })
        }

        if (type === 'distributors') {
            this.setState({
                profile_open: false,
                distributors_open: true,
                updates_open: false,
                distributors_clients_table: false,
                single_client_detail: false
            })
        }

        if (type === 'updates') {
            this.setState({
                profile_open: false,
                distributors_open: false,
                updates_open: true,
                distributors_clients_table: false,
                single_client_detail: false
            })
        }
    }


    showDistributorDetail = () => {
        this.setState({
            single_client_detail: true,
            distributors_clients_table: false,
            profile_open: false,
            distributors_open: true,
            updates_open: false,

        })
    }

    initGetAreas = () => {
        const {getDistributorsCompetenceAreas, clearClients, clearClientDetails} = this.props;
        this.handleToggle('distributors');
        clearClientDetails();
        clearClients();
        if (this.props.basf.areas.list.data.length === 0 && this.props.basf.areas.list.loading === false) {
            getDistributorsCompetenceAreas();
        }
    }


    handleResearch = (e) => {
        e.preventDefault();
        if (this.handleResearchValidation()) {
            const {getClientsForBasfByDistributor, getDistributorName} = this.props;
            this.setState({
                distributors_clients_table: true
            });
            getDistributorName(this.props.basf.distributors.list.data);
            getClientsForBasfByDistributor();
        }
    }

    getClientDetailForBasf = (client) => {
        const {getClientDetailsForBasfPreview} = this.props;
        this.showDistributorDetail();
        getClientDetailsForBasfPreview(client);
    }

    handleResearchValidation = () => {
        const {params, errors} = this.state;

        let form_is_valid = true;
        if (!params.area_id.trim()) {
            form_is_valid = false;
            errors.area = true;
        }

        if (!params.distributor_id.trim()) {
            form_is_valid = false;
            errors.distributor = true;
        }

        this.setState((prevState) => ({
            ...prevState,
            errors: errors
        }));

        return form_is_valid;

    }

    uploadNewUpdate = (e) => {
        e.preventDefault();
        if (this.handleFormValidation()) {
            const {uploadNewUpdate} = this.props;
            const data = this.props.basf.new_update;
            data.id_user = this.props.auth.user.id;
            uploadNewUpdate(data);
        }
        document.getElementById('customFile').value = null;
    }


    handleFormValidation = () => {
        const {form_errors} = this.state;
        const new_update = this.props.basf.new_update;

        let form_is_valid = true;

        if (new_update.benchmark === '') {
            form_errors.benchmark = true;
            form_is_valid = false;
        }
        if (!new_update.title.trim()) {
            form_errors.title = true;
            form_is_valid = false;
        }
        if (!new_update.description.trim()) {
            form_errors.description = true;
            form_is_valid = false;
        }

        //// 157286400 = 150 MB

        if (new_update.file === null || new_update.file.size >= max_file_size) {
            form_errors.file = true;
            form_is_valid = false;
        }

        this.setState((prevState) => ({
            ...prevState,
            form_errors: form_errors
        }));
        return form_is_valid;
    }

    render() {
        const {profile_open, distributors_open, updates_open, distributors_clients_table, single_client_detail, errors, form_errors} = this.state;

        const {currentUser, auth, setBasfSelectValue, getBasfDistributors, clearDistributors, clearClients, setNewUpdateValue, uploadMedia, setBenchmarkFilterValue, getDistributorName, getPhotosAndVideosUpdates} = this.props;

        const areas = this.props.basf.areas.list.data;
        const distributors = this.props.basf.distributors.list.data;
        const clients = this.props.basf.clients.list.data;
        const client_detail = this.props.basf.client_detail;
        const benchmarks = this.props.media.benchmarks.list.data;
        const distributor_name = this.props.basf.distributor_name;
        const medias = this.props.media.content.data;
        const imgBaseUrl = process.env.REACT_APP_API_HOST;
        const upload_loading = this.props.basf.upload_loading;


        if (auth.token === null) {
            return <Redirect to='/login'/>
        }


        return (
            <React.Fragment>
                <section>
                    <div className="container">
                        <nav aria-label="breadcrumb" className="d-inline-block">
                            <ul className="breadcrumb mb-0">
                                <li className="breadcrumb-item" style={{marginRight: '5px'}}><a
                                    onClick={() => this.props.history.push('/')}>Home</a>
                                </li>
                                <li className={profile_open ? "breadcrumb-item active" : "breadcrumb-item"}><a
                                    onClick={() => this.handleToggle('profile')}>Basf Revy +</a></li>
                                {(distributors_open) &&
                                <li className={distributors_open && clients.length === 0 ? "breadcrumb-item active" : "breadcrumb-item"}>
                                    <a
                                        onClick={() => this.initGetAreas()}>Distributori</a>
                                </li>
                                }
                                {updates_open &&
                                <li className={updates_open ? "breadcrumb-item active" : "breadcrumb-item"}><a
                                    onClick={() => this.handleToggle('updates')}>Campi benchmark</a>
                                </li>
                                }
                                {(distributors_open && clients.length !== 0) &&
                                <li className={distributors_open && client_detail === null ? "breadcrumb-item active" : "breadcrumb-item"}>
                                    <a onClick={() => this.handleToggle('distributors')}>{distributor_name}</a>
                                </li>
                                }
                                {(client_detail !== null) &&
                                <li className={distributors_open ? "breadcrumb-item active" : "breadcrumb-item"}>
                                    <a>{client_detail?.ragione_sociale}</a>
                                </li>
                                }
                            </ul>
                        </nav>
                    </div>
                </section>
                <section className="d-table w-100 mt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt-3">
                                <div className="card public-profile border-0 rounded shadow">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-lg-2 text-center profile-icon">
                                                <i className="uil uil-user-circle"/>
                                            </div>

                                            <div className="col-lg-10 col-md-9">
                                                <div className="row align-items-end">
                                                    <div className="col-md-7 text-md-start text-center mt-4 mt-sm-0">
                                                        <h3 className="title mb-0">{currentUser?.nome} {currentUser?.cognome}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 d-lg-block">
                                <div className="sidebar sticky-bar p-4 rounded shadow">
                                    <div className="widget">
                                        <ul className="list-unstyled sidebar-nav mb-0" id="navmenu-nav">
                                            <li className={profile_open ? "navbar-item account-menu px-0 active" : "navbar-item account-menu px-0"}>
                                                <a onClick={() => this.handleToggle('profile')}
                                                   className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer">
                                                    <span className="h4 mb-0"><i className="uil uil-home"/></span>
                                                    <h6 className="mb-0 ms-2 custom_font">Basf Revy +</h6>
                                                </a>
                                            </li>

                                            <li className={distributors_open ? "navbar-item account-menu px-0 mt-2 active" : "navbar-item account-menu px-0 mt-2"}>
                                                <a onClick={() => this.initGetAreas()}
                                                   className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer">
                                                    <span className="h4 mb-0"><i
                                                        className="uil uil-building"/></span>
                                                    <h6 className="mb-0 ms-2 custom_font">Distributori</h6>
                                                </a>
                                            </li>
                                            <li className={updates_open ? "navbar-item account-menu px-0 mt-2 active c" : "navbar-item account-menu px-0 mt-2"}>
                                                <a onClick={() => this.handleToggle('updates')}
                                                   className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer">
                                                    <span className="h4 mb-0"><i className="uil uil-bell"/></span>
                                                    <h6 className="mb-0 ms-2 custom_font">Campi benchmark</h6>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {profile_open ?
                                <div className="col-lg-8 col-12">
                                    <div className="pb-4">
                                        <div className="row">
                                            <div className="col-md-12 mt-4">
                                                <h5>Dati personali:</h5>
                                                <div className="mt-4">
                                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <i data-feather="user" className="fea icon-ex-md me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="text_green custom_font mb-0">Nome:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.nome}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                        <i data-feather="user" className="fea icon-ex-md me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="text_green custom_font mb-0">Cognome:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.cognome}</p>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex align-items-center mb-2 pb-2">
                                                        <i data-feather="mail" className="fea icon-ex-md me-3"/>
                                                        <div className="flex-1">
                                                            <h6 className="text_green custom_font mb-0">Email:</h6>
                                                            <p className="text-muted mb-0">{currentUser?.email}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : distributors_open && !single_client_detail ?
                                    <>
                                        <div className="col-lg-8 col-12">
                                            <div className="pb-4 mb-5">
                                                <div className="row">
                                                    <div className="col-md-12 mt-4">
                                                        <h5>Ricerca distributori:</h5>
                                                        <form className="login-form mt-4">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="mb-3">
                                                                        <label
                                                                            className="form-label custom_font">Zona</label>
                                                                        <div className="form-icon position-relative">
                                                                            <Icon icon="cil:location-pin"
                                                                                  className="fea icon-sm icons"/>
                                                                            <select
                                                                                onChange={(event) => {
                                                                                    const value = event.target.value
                                                                                    this.setState((prevState) => ({
                                                                                        ...prevState,
                                                                                        params: {
                                                                                            area_id: value,
                                                                                            distributor_id: ''
                                                                                        }, errors: {
                                                                                            ...prevState.errors,
                                                                                            area: false
                                                                                        },
                                                                                        distributors_clients_table: false
                                                                                    }), () => {
                                                                                        setBasfSelectValue('area_id', value);
                                                                                        clearDistributors();
                                                                                        clearClients();
                                                                                        getBasfDistributors();
                                                                                    })
                                                                                }}
                                                                                value={this.props.basf.params.area_id}
                                                                                className={errors.area ? "form-select form-control ps-5 input_error" : "form-select form-control ps-5"}
                                                                                aria-label="Default select example">
                                                                                <option disabled value=''>Seleziona
                                                                                    zona
                                                                                </option>
                                                                                {areas.map((area, key) => {
                                                                                    return (
                                                                                        <option key={key}
                                                                                                value={area.id}>{area.zona}</option>
                                                                                    )
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="mb-3">
                                                                        <label
                                                                            className="form-label">Distributore</label>
                                                                        <div className="form-icon position-relative">
                                                                            <Icon icon="bx:bx-building-house"
                                                                                  className="fea icon-sm icons"/>
                                                                            <select
                                                                                onChange={(event) => {
                                                                                    const value = event.target.value
                                                                                    this.setState((prevState) => ({
                                                                                        ...prevState,
                                                                                        params: {
                                                                                            ...prevState.params,
                                                                                            distributor_id: value
                                                                                        }, errors: {
                                                                                            ...prevState.errors,
                                                                                            distributor: false
                                                                                        }
                                                                                    }), () => {
                                                                                        setBasfSelectValue('distributor_id', value);
                                                                                        getDistributorName(distributors);
                                                                                    })
                                                                                }}
                                                                                value={this.props.basf.params.distributor_id}
                                                                                className={errors.distributor ? "form-select form-control ps-5 input_error" : "form-select form-control ps-5"}
                                                                                aria-label="Default select example">
                                                                                <option disabled value=''>Seleziona
                                                                                    distributore
                                                                                </option>
                                                                                {distributors.map((distributor, key) => {
                                                                                    return (
                                                                                        <option key={key}
                                                                                                value={distributor.id}>{distributor.ragione_sociale}</option>
                                                                                    )
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12">
                                                                    <div className="d-grid">
                                                                        <button
                                                                            className="btn btn-primary border-button"
                                                                            onClick={(e) => this.handleResearch(e)}>Ricerca
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            {((distributors_clients_table && distributors_open) || clients.length !== 0) &&
                                            <> {clients.length !== 0 ?
                                                <div className="border-bottom pb-4">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-4">
                                                            <h5>{distributor_name}</h5>
                                                            <div className="table-responsive bg-white shadow rounded">
                                                                <table className="table mb-0 table-center invoice-tb">
                                                                    <thead className="bg-light">
                                                                    <tr>
                                                                        <th scope="col"
                                                                            className="border-bottom text-start">Nome
                                                                        </th>
                                                                        <th scope="col"
                                                                            className="border-bottom text-start">Cognome
                                                                        </th>
                                                                        <th scope="col"
                                                                            className="border-bottom text-start">Città
                                                                        </th>
                                                                        <th scope="col"
                                                                            className="border-bottom text-start">Provincia
                                                                        </th>
                                                                        <th scope="col"
                                                                            className="border-bottom text-start">Email
                                                                        </th>
                                                                        <th scope="col"
                                                                            className="border-bottom text-start">Telefono
                                                                        </th>
                                                                        <th scope="col"
                                                                            className="border-bottom text-center">Azioni
                                                                        </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {clients.map((client, key) => {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td scope="row"
                                                                                    className="text-start">{capitalize(client.nome)}</td>
                                                                                <td className="text-start">{capitalize(client.cognome)}</td>
                                                                                <td className="text-start">{capitalize(client.citta_di_residenza)}</td>
                                                                                <td className="text-start">{capitalize(client.provincia_di_residenza)}</td>
                                                                                <td className="text-start">{client.email}</td>
                                                                                <td className="text-start">{client.telefono}</td>
                                                                                <td className="text-center"><i
                                                                                    onClick={() => this.getClientDetailForBasf(client)}
                                                                                    className="uil uil-eye user_profile_text_green cursor_pointer"/>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <h4 className='text-center text-black mb-3'>Nessun cliente trovato</h4>
                                            }
                                            </>
                                            }
                                        </div>
                                    </> : single_client_detail ? <>
                                            <div className="col-lg-8 col-12">
                                                <div className="pb-4">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-4">
                                                            <h5>Dati personali:</h5>
                                                            <div className="mt-4">
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="bx:bx-user" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"/>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Nome:</h6>
                                                                        <p className="text-muted mb-0">{capitalize(client_detail?.nome)}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="bx:bx-user" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"/>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Cognome:</h6>
                                                                        <p className="text-muted mb-0">{capitalize(client_detail?.cognome)}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="bx:bx-user" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"/>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Ragione
                                                                            sociale:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.ragione_sociale}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="feather:map-pin" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"
                                                                    />
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Via:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.via}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="feather:map-pin" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"
                                                                    />
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Città:</h6>
                                                                        <p className="text-muted mb-0">{capitalize(client_detail?.citta_di_residenza)}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="feather:map-pin" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"
                                                                    />
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Cap:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.cap}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="feather:map-pin" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"
                                                                    />
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Provincia:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.provincia_di_residenza}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="ic:outline-email" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"
                                                                    />
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Email:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.email}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="clarity:phone-handset-line" width="17"
                                                                          height="17"
                                                                          className="fea icon-ex-md text-muted me-3"
                                                                    />
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Telefono:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.telefono}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="lucide:divide-circle" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"
                                                                    />
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Partita
                                                                            IVA:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.partita_iva}</p>
                                                                    </div>
                                                                </div>
                                                                <h5 className='mb-4 mt-4'>Dati campo Revy+:</h5>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="feather:map-pin" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"/>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Zona -
                                                                            Provincia</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.zona} - {client_detail?.provincia}</p>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="feather:map-pin" width="17" height="17"
                                                                          className="fea icon-ex-md text-muted me-3"/>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Il mio
                                                                            campo benchmark</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.campo_benchmark}</p>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="feather:divide-circle"
                                                                          className="fea icon-ex-md me-3"/>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Varietà
                                                                            coltivabile:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.varieta_coltivabile}</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <Icon icon="carbon:layers"
                                                                          className="fea icon-ex-md me-3"/>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Numero
                                                                            totale di ettari di superficie coltivabile:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.tot_ha_coltivabile}</p>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="d-flex align-items-center border-bottom mb-2 pb-2">
                                                                    <i className="fea icon-ex-md me-3"><img
                                                                        src={cereali_img}
                                                                        className="img-fluid mt-n2" alt="cereali"/></i>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Numero
                                                                            ettari di cereali partecipanti
                                                                            all'iniziativa:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.num_ha_cereali_partecipa}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <Icon icon="bi:code"
                                                                          className="fea icon-ex-md me-3"/>
                                                                    <div className="flex-1">
                                                                        <h6 className="user_profile_text_green mb-0">Codice
                                                                            fornito al momento dell'acquisto:</h6>
                                                                        <p className="text-muted mb-0">{client_detail?.codice_univoco}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : <div className="col-lg-8 col-12">

                                            <div className="border-bottom pb-4">
                                                <div className="row">
                                                    <div className="col-md-12 mt-4">
                                                        <h5>Nuovo aggiornamento:</h5>
                                                        <form className="login-form mt-4">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="mb-3">
                                                                        <label
                                                                            className={form_errors.benchmark ? "form-label label_error" : "form-label"}>Seleziona
                                                                            benchmark
                                                                            <span className="text-danger">*</span>
                                                                        </label>
                                                                        <div className="form-icon position-relative">
                                                                            <Icon icon="bx:bx-building-house"
                                                                                  className="fea icon-sm icons"/>
                                                                            <select
                                                                                onChange={(event) => {
                                                                                    const value = event.target.value
                                                                                    this.setState((prevState) => ({
                                                                                        ...prevState,
                                                                                        new_update: {
                                                                                            ...prevState.new_update,
                                                                                            benchmark: value
                                                                                        }, form_errors: {
                                                                                            ...prevState.form_errors,
                                                                                            benchmark: false
                                                                                        }

                                                                                    }), () => {
                                                                                        setNewUpdateValue('benchmark', value);
                                                                                    })
                                                                                }}
                                                                                value={this.props.basf.new_update.benchmark}
                                                                                className={form_errors.benchmark ? "form-select form-control ps-5 input_error" : "form-select form-control ps-5"}
                                                                                aria-label="Default select example">

                                                                                {benchmarks.map((benchmark, key) => {
                                                                                    return (
                                                                                        <option key={key}
                                                                                                value={benchmark.campo_benchmark}>{benchmark.campo_benchmark}</option>
                                                                                    )
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 mb-2">
                                                                    <div className="mb-3">
                                                                        <label
                                                                            className={form_errors.file ? "form-label label_error" : "form-label"}
                                                                            htmlFor="customFile">Carica qui il tuo file:
                                                                            <span className="text-danger">*</span>
                                                                        </label>
                                                                        <input type="file"
                                                                               accept="image/*, video/mp4, video/mpeg ,video/x-msvideo ,video/quicktime"
                                                                               className={form_errors.file ? "form-control input_error" : "form-control"}
                                                                               onChange={(event) => {
                                                                                   const value = event.target.files[0];
                                                                                   this.setState((prevState) => ({
                                                                                       ...prevState,
                                                                                       new_update: {
                                                                                           ...prevState.new_update,
                                                                                           file: value
                                                                                       }, form_errors: {
                                                                                           ...prevState.form_errors,
                                                                                           file: false
                                                                                       }
                                                                                   }), () => {
                                                                                       uploadMedia(value);

                                                                                   })
                                                                               }}
                                                                               id="customFile"/>
                                                                    </div>
                                                                    <span className="text-muted">I formati video accettati sono .mp4, .avi, .mov, .mpeg di peso massimo 150MB</span>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="mb-3">
                                                                        <label
                                                                            className={form_errors.title ? "form-label label_error" : "form-label"}>Titolo<span
                                                                            className="text-danger">*</span></label>
                                                                        <div className="form-icon position-relative">
                                                                            <Icon icon="jam:write"
                                                                                  className="fea icon-sm icons"/>
                                                                            <input type="text" id="title"
                                                                                   className={form_errors.title ? "form-control ps-5 input_error" : "form-control ps-5"}
                                                                                   value={this.props.basf.new_update.title}
                                                                                   onChange={(event) => {
                                                                                       const value = event.target.value;
                                                                                       this.setState((prevState) => ({
                                                                                           ...prevState,
                                                                                           new_update: {
                                                                                               ...prevState.new_update,
                                                                                               title: value
                                                                                           },
                                                                                           form_errors: {
                                                                                               ...prevState.form_errors,
                                                                                               title: false
                                                                                           }
                                                                                       }), () => {
                                                                                           setNewUpdateValue('title', value);
                                                                                       })
                                                                                   }}
                                                                                   placeholder="Titolo"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="mb-3">
                                                                        <label
                                                                            className={form_errors.description ? "form-label label_error" : "form-label"}>Testo<span
                                                                            className="text-danger">*</span></label>
                                                                        <div className="form-icon position-relative">
                                                                            <Icon icon="bx:bx-message-rounded"
                                                                                  className="fea icon-sm icons"/>
                                                                            <textarea name="comments"
                                                                                      id="comments"
                                                                                      rows="4"
                                                                                      className={form_errors.description ? "form-control ps-5 input_error" : "form-control ps-5"}
                                                                                      value={this.props.basf.new_update.description}
                                                                                      onChange={(event) => {
                                                                                          const value = event.target.value;
                                                                                          this.setState((prevState) => ({
                                                                                              ...prevState,
                                                                                              new_update: {
                                                                                                  ...prevState.new_update,
                                                                                                  description: value
                                                                                              },
                                                                                              form_errors: {
                                                                                                  ...prevState.form_errors,
                                                                                                  description: false
                                                                                              }
                                                                                          }), () => {
                                                                                              setNewUpdateValue('description', value);
                                                                                          })
                                                                                      }}
                                                                                      placeholder="Il tuo aggiornamento:"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="d-grid">
                                                                        <button
                                                                            className="btn btn-primary border-button"
                                                                            disabled={upload_loading}
                                                                            onClick={(e) => this.uploadNewUpdate(e)}>Invia</button>
                                                                    </div>
                                                                    <div className='loader-wrapper'>
                                                                        <DotLoader
                                                                            color='#7ED321'
                                                                            loading={upload_loading}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pb-4">
                                                <div className="row">
                                                    <div className="col-md-12 mt-4">
                                                        <h5 className='custom_font'>Storico aggiornamenti:</h5>
                                                        <div className="row">
                                                            <div className="col-md-12 mt-3">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Filtra per
                                                                        benchmark</label>
                                                                    <div className="form-icon position-relative">
                                                                        <Icon icon="bx:bx-building-house"
                                                                              className="fea icon-sm icons"/>
                                                                        <select
                                                                            onChange={(event) => {
                                                                                const value = event.target.value
                                                                                this.setState((prevState) => ({
                                                                                    ...prevState,
                                                                                    filter: {
                                                                                        benchmark: value
                                                                                    }
                                                                                }), () => {
                                                                                    setBenchmarkFilterValue('benchmark', value);
                                                                                    getPhotosAndVideosUpdates();
                                                                                })
                                                                            }}
                                                                            value={this.props.media.filter.benchmark}
                                                                            className="form-select form-control ps-5"
                                                                            aria-label="Default select example">
                                                                            {benchmarks.map((benchmark, key) => {
                                                                                return (
                                                                                    <option key={key}
                                                                                            value={benchmark.campo_benchmark}>{benchmark.campo_benchmark}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {medias.length === 0 ?
                                                                <div className='text-center custom_font'>Non sono
                                                                    presenti
                                                                    aggiornamenti</div> :
                                                                <>
                                                                    {medias.map((media, key) => {
                                                                        return (
                                                                            <div key={key}
                                                                                 className="col-md-6 mt-4 pt-2">
                                                                                <div
                                                                                    className='card rounded border-0 shadow'>
                                                                                    <div className="position-relative">
                                                                                        {media.file_type.includes('image') ?
                                                                                            <img
                                                                                                src={imgBaseUrl.concat(clearApiBaseUrl(media.path))}
                                                                                                className="card-img-top rounded-top"
                                                                                                alt="..."/>
                                                                                            :
                                                                                            <ReactPlayer
                                                                                                controls={true}
                                                                                                width={"fit-content"}
                                                                                                height={"fit-content"}
                                                                                                volume={0.5}
                                                                                                config={{
                                                                                                    file: {
                                                                                                        attributes: {
                                                                                                            controlsList: 'nodownload',
                                                                                                            disablepictureinpicture: 'true',
                                                                                                            disableremoteplayback: 'true'
                                                                                                        }
                                                                                                    }
                                                                                                }}
                                                                                                url={imgBaseUrl.concat(clearApiBaseUrl(media.path))}/>
                                                                                        }

                                                                                    </div>
                                                                                    <div className="card-body content">
                                                                                        <h6 className='text-muted text-left creation-date'>{formatUpdateCreationDate(media.data_creazione)}</h6>
                                                                                        <h5 className='text-left'>
                                                                                            <a className="updates-title">{media.titolo}</a>
                                                                                        </h5>
                                                                                        <h6 className='text-muted text-left'>{media.descrizione}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            }
                        </div>
                    </div>
                </section>

            </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.user,
        auth: state.auth,
        basf: state.basf,
        media: state.media
    }
}

const mapDispatchToProps = {
    getCurrentUser,
    getDistributorsCompetenceAreas,
    getClientsForBasfByDistributor,
    getBasfDistributors,
    setBasfSelectValue,
    getClientDetailsForBasfPreview,
    clearClientDetails,
    clearClients,
    clearDistributors,
    setNewUpdateValue,
    getAllBenchmarks,
    uploadNewUpdate,
    uploadMedia,
    setBenchmarkFilterValue,
    getDistributorName,
    getPhotosAndVideosUpdates
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BasfPersonnelProfile));
