import React, {Component, Fragment} from "react";


class Crediti extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Fragment>
               <div id='crediti'>
                   <h1 className='mt-5 text-bold'>BASF Agricultural Solutions Italia</h1>
                   <p className='text-bold mt-5 margin-bottom'>BASF Italia S.p.A.</p>
                   <p className='margin-bottom'>Divisione Agricultural Solutions</p>
                   <p className='margin-bottom'>Via Marconato 8</p>
                   <p className='margin-bottom'>20811 Cesano Maderno (MB)</p>
                   <p className='mt-3 margin-bottom'>Registro Imprese: Milano</p>
                   <p className='margin-bottom'>Numero di iscrizione: 00717180152</p>
                   <p className='margin-bottom'>Codice fiscale: 00717180152</p>
                   <p className='margin-bottom'>Numero di partita IVA: IT00688460936</p>
                   <p className='margin-bottom'>Capitale Sociale: € 25.000.000</p>
                   <p className='mt-3 margin-bottom'>Telefono: 0362 512 1</p>
                   <p className='margin-bottom'>Fax: 0362 512 065</p>
                   <p>Email:<a className='text_green' href='mailto:info.agroitalia@basf.com'> info.agroitalia@basf.com</a></p>
                   <p style={{fontSize:'1.3rem'}} className='margin-bottom'>Responsabile del sito:</p>
                   <p className='margin-bottom'>Francesco Esposito</p>
                   <p>Email: <a className='text_green' href='mailto:francesco.esposito@basf.com'>francesco.esposito@basf.com</a></p>
                   <p className='mt-3 text-bold'>Board of Executive Directors</p>
                   <p>Martin Brudermueller, Chairman; Hans-Ulrich Engel, Vice Chairman</p>
                   <p>Saori Dubourg, Michael Heinz, Markus Kamieth, Melanie Maas-Brunner</p>
                   <p><strong>Chairman of the Supervisory Board</strong> : <span>Kurt Bock</span></p>
                   <p>BASF SE, Registered Office: 67056 Ludwigshafen, Germany</p>
                   <p>Registration Court: Amtsgericht Ludwigshafen, Registration No.: HRB 6000</p>
               </div>
            </Fragment>
        )
    }

}


export default Crediti;