import {GET_PHOTOS_AND_VIDEOS_UPDATES, GET_ALL_BENCHMARKS, SET_BENCHMARK_FILTER_VALUE, GET_USER_DATA} from "./actionTypes";


export const getPhotosAndVideosUpdates = () => {
    return {
        type: GET_PHOTOS_AND_VIDEOS_UPDATES,
        api: true
    }
}

export const getAllBenchmarks = () => {
    return {
        type: GET_ALL_BENCHMARKS,
        api: true
    }
}


export const getUserData = () => {
    return {
        type: GET_USER_DATA,
        api: true
    }
}


export const setBenchmarkFilterValue = (field, value) => {
    return {
        type: SET_BENCHMARK_FILTER_VALUE,
        payload: {
            field,
            value
        }
    }
}